import api from '../api';

export default {
    namespaced: true,
    state: {
        collagesData: [],
        applicantPrefrences: [],
        // minFacultyCount: 0,

    },
    getters: {
        // getMinFacultyCount(state) {
        //     return state.minFacultyCount;
        // },
        getCollagesData(state) {
            return state.collagesData;
        },
        applicantPrefrencesData(state) {
            return state.applicantPrefrences;
        },
    },
    mutations: {
        // setMinFacultyCount(state, data) {
        //     state.minFacultyCount = data;
        // },
        setCollagesData(state, data) {
            state.collagesData = [];
            for (var x = 0; x < data.length; x++) {
                state.collagesData.push({
                    "code": data[x].facultieID,
                    "name": data[x].facultieDescr,
                    "programs":[]
                    // "programs": data[x].facultyPrograms.map((prog) => ({
                    //     "code": prog.majorEntMainId,
                    //     "name": prog.majorDescr,
                    // }))
                });
            }
        },
        setApplicantPrefrencesData(state, data) {
            state.applicantPrefrences = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.applicantPrefrences.push({
                    "asFacultyInfoId": data[x].asFacultyInfoId,
                    "entMainId": data[x].entMainId,
                    "prefOrder": data[x].prefOrder,
                    "facDescr": data[x].facDescr,
                    "programDescr": data[x].programDescr,
                });
            }
        },
    },
    actions: {
        fetchCollagesData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetApplicantPref_Faculties`)
                    .then(res => {
                        commit("setCollagesData", res.data.faculties);
                        // commit("setMinFacultyCount", res.data.MinFacultyCount);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchProgramsData({ commit },facultyID) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetApplicantPref_Programs?AsFacultyInfoId=${facultyID}`)
                    .then(res => {
                        resolve(res.data.programs)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        GetMinMaxNumberOfPrefrences({ _ }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetMinMaxNumberOfPrefrences`)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchApplicantPrefrencesData({ commit, state }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetAllApplicantPrefrences`)
                    .then(res => {
                        commit("setApplicantPrefrencesData", res.data.applicantPrefrences);
                        resolve(state.applicantPrefrences)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        SaveApplicantPrefrences({ }, preferencesDataList) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/ApplicantPref/SaveApplicantPrefrences", {
                    "modifiedApplicantPrefrences": preferencesDataList
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}