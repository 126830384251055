<template>
  <div class="mainForm">
    <div>
      <div class="grid m-3">
        <div class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round">
          <span>{{ $t("fullNameLabel") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="firstNamePlaceholder" v-model="firstName" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.firstName && submitted },
          ]" />
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="secondNamePlaceholder" v-model="secondName" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.secondName && submitted },
          ]" />
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="thirdNamePlaceholder" v-model="thirdName" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.thirdName && submitted },
          ]" />
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="lastNamePlaceholder" v-model="lastName" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.lastName && submitted },
          ]" />
        </div>
      </div>
      <div class="grid m-3">
        <div class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round">
          <span>{{ $t("fullNameLabel_ar") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="firstNamePlaceholder" v-model="firstName_ar" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.firstName_ar && submitted },
          ]" />
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="secondNamePlaceholder" v-model="secondName_ar" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.secondName_ar && submitted },
          ]" />
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="thirdNamePlaceholder" v-model="thirdName_ar" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.thirdName_ar && submitted },
          ]" />
        </div>
        <div class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round">
          <InputText :placeholder="lastNamePlaceholder" v-model="lastName_ar" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.lastName_ar && submitted },
          ]" />
        </div>
      </div>

      <div class="grid m-2">
        <div class="col-12 md:col-9">
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="align-items-start justify-content-start font-bold border-round p-1">
                <span>{{ $t("Birth_Date") }}</span><span style="color: red">*</span>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <Calendar v-model="Birth_Date" @hide="checkForBirthDate" dateFormat="yy-mm-dd"  showIcon :class="[
                'w-full',
                {
                  'p-invalid':
                    (validationErrors.Birth_Date && submitted) ||
                    validationErrors.Birth_Date_not_correct,
                },
              ]" />
            </div>
            <div class="col-0 md:col-4"></div>

            <div class="col-12 md:col-4">
              <div class="align-items-start justify-content-start font-bold border-round p-1">
                <span>{{ $t("Birth_Place") }}</span><span style="color: red">*</span>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <Dropdown v-model="Birth_Place" :options="countriesData" optionLabel="name" filter
                :placeholder="selectPlaceholder" :class="[
                  'w-full mt-3',
                  { 'p-invalid': validationErrors.Birth_Place && submitted },
                ]" class="w-full m-1" />
            </div>
            <div class="col-0 md:col-4"></div>

            <div class="col-12 md:col-4 labelDiv flex align-items-start justify-content-start font-bold border-round">
              <span>{{ $t("genderNameLabel") }}</span><span style="color: red">*</span>
            </div>
            <div class="col-12 md:col-4 flex align-items-center justify-content-center font-bold border-round">
              <Dropdown v-model="selectedgender"  :options="gendersData" optionLabel="name"
                :placeholder="selectPlaceholder" :class="[
                  'w-full',
                  { 'p-invalid': validationErrors.selectedgender && submitted },
                ]" />
            </div>
            <div class="col-0 md:col-4"></div>
          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="surface-section" style="text-align: center"
            :class="[{ emptyIMgError: validationErrors.imgPath && submitted }]">
            <div class="flex labelDiv align-items-center justify-content-center font-bold border-round">
              <span>{{ $t("photo") }}</span><span style="color: red">*</span>
            </div>

            <img v-if="imgPath" style="width: 180px" :src="imgDataUrl"  />
            <img v-else style="width: 180px" src="../../assets/images/avatar.png" alt="Profile Image" />

            <div class="flex justify-content-center mt-2">
              <div v-tooltip="`${deleteBtntooltip}`">
                <Button icon="pi pi-trash" v-if="isSubmitted == 'false'" @click="removeImage" style="
                    background-color: #df2b2b;
                    width: 60px;
                    margin-inline: 5px;
                    border: none;
                  " />
                <input type="file" v-if="isSubmitted == 'false'" style="display: none !important" id="image_setting"
                  accept="image/*" @change="onFileChange" />
              </div>
              <div v-tooltip="`${uploadBtntooltip}`">
                <Button v-if="isSubmitted == 'false'" icon="pi pi-upload" @click="uploadImage"
                  style="background-color: #028bff; width: 60px; margin-inline: 5px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-end">
      <div v-tooltip="`${nextBtnTooltip}`">
        <Button :label="nextBtnTxt" class="btnClass" @click="saveBasicInfoPage" icon="pi pi-angle-right"
          iconPos="right" />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../store/mixin.js";

export default {
  mixins: [mixin],
  data() {
    return {
      submitted: false,
      firstName: "",
      secondName: "",
      thirdName: "",
      lastName: "",
      firstName_ar: "",
      secondName_ar: "",
      thirdName_ar: "",
      lastName_ar: "",
      Birth_Date: "",
      imgPath: "",
      imageLocation: "",
      image: "",
      Birth_Place: {},
      selectedgender: {},
      validationErrors: {},
      imageName:"",
    };
  },
  computed: {
    isSubmitted() {
      return localStorage.getItem("isSubmitted");
    },
    deleteBtntooltip() {
      return this.$t("deleteBtntooltip");
    },
    uploadBtntooltip() {
      return this.$t("uploadBtntooltip");
    },

    firstNamePlaceholder() {
      return this.$t("firstNamePlaceholder");
    },
    secondNamePlaceholder() {
      return this.$t("secondNamePlaceholder");
    },
    thirdNamePlaceholder() {
      return this.$t("thirdNamePlaceholder");
    },
    lastNamePlaceholder() {
      return this.$t("lastNamePlaceholder");
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    countriesData() {
      return this.$store.getters["Auth/getCountriesData"];
    },
    gendersData() {
     const genders= [
        { code: 1, name: this.$t("male") },
        { code: 2, name: this.$t("female") },

      ]
      return genders;
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    nextBtnTooltip() {
      return this.$t("nextBtnTooltip");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },

      imgDataUrl() {
    // Replace this with your actual byte array data
 const   imageUrl = `data:image/jpeg;base64,${this.imgPath}`;
console.log(imageUrl);
    return imageUrl;

    }
  },
  methods: {
    checkForBirthDate() {
  try {
    console.log("Selected Birth_Date (raw):", this.Birth_Date);

    // Check if Birth_Date is a Date object and convert it to 'yyyy-mm-dd' format
    if (this.Birth_Date instanceof Date) {
      let year = this.Birth_Date.getFullYear();
      let month = String(this.Birth_Date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      let day = String(this.Birth_Date.getDate()).padStart(2, '0');

      // Format as 'yyyy-mm-dd'
      this.Birth_Date = `${year}-${month}-${day}`;
      console.log("Formatted Birth_Date:", this.Birth_Date);
    }

    // Validate the formatted birth date
    var birthDateParts = this.Birth_Date.split('-');
    if (birthDateParts.length !== 3) {
      console.error("Invalid Birth_Date format after formatting.");
      return;
    }

    var birthYear = parseInt(birthDateParts[0], 10);
    var birthMonth = parseInt(birthDateParts[1], 10) - 1; // JavaScript months are 0-indexed
    var birthDay = parseInt(birthDateParts[2], 10);

    console.log(`Parsed Birth_Date: Year: ${birthYear}, Month: ${birthMonth + 1}, Day: ${birthDay}`);

    // Create a Date object from the parsed birth date
    var Birth_Date_DT = new Date(birthYear, birthMonth, birthDay);

    // Get today's date
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date time to 00:00:00 to remove time differences

    console.log("Current Date:", currentDate);

    // Calculate the age in years
    var age = currentDate.getFullYear() - Birth_Date_DT.getFullYear();

    // Adjust age if the birth date hasn't occurred yet this year
    var monthDiff = currentDate.getMonth() - Birth_Date_DT.getMonth();
    var dayDiff = currentDate.getDate() - Birth_Date_DT.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    console.log("Calculated Age:", age);

    // Check if the person is younger than 15
    if (age < 15) {
      this.validationErrors["Birth_Date_not_correct"] = true;
      this.$swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.$t("confirmButtonText"),
        text: this.$t("Birth_Date_not_correct"),
      });
    } else {
      // Update the Birth_Date to be in 'yyyy-mm-dd' format to send to the backend
      this.Birth_Date = `${Birth_Date_DT.getFullYear()}-${String(Birth_Date_DT.getMonth() + 1).padStart(2, '0')}-${String(Birth_Date_DT.getDate()).padStart(2, '0')}`;
      console.log("Formatted Birth_Date to send to backend:", this.Birth_Date);
      delete this.validationErrors["Birth_Date_not_correct"];
    }
  } catch (error) {
    console.error("Error in checkForBirthDate:", error);
  }
},





   uploadImage() {
      document.getElementById("image_setting").click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;
      var re = /(\.jpg|\.jpeg|\.bmp|\.doc|.docx|.pdf|\.png)$/i;
      if (!re.exec(files[0].name) || files[0].length > 1024 * 1024 * 5) {
        return;
      }

      this.createImage(files[0]);
      this.image = files[0];
      this.uploadImg();
      debugger  
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imgPath = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.imgPath = "";
    },

    validateForm() {
if (this.imgPath == "" || this.imgPath == undefined)
this.validationErrors["imgPath"] = true;
     else delete this.validationErrors["imgPath"];


      if (!/^[a-zA-Z\u0600-\u06FF\s]+$/.test(this.firstName)) {
        this.validationErrors["firstName"] = true;
      } else {
        delete this.validationErrors["firstName"];
      }

      if (!/^[a-zA-Z\u0600-\u06FF\s]+$/.test(this.thirdName)) {
        this.validationErrors["thirdName"] = true;
      } else {
        delete this.validationErrors["thirdName"];
      }
      if (!/^[a-zA-Z\u0600-\u06FF\s]+$/.test(this.secondName)) {
        this.validationErrors["secondName"] = true;
      } else {
        delete this.validationErrors["secondName"];
      }

      if (!/^[a-zA-Z\u0600-\u06FF\s]+$/.test(this.lastName)) {
        this.validationErrors["lastName"] = true;
      } else {
        delete this.validationErrors["lastName"];
      }
      if (!/^[a-zA-Z\u0600-\u06FF\s]+$/.test(this.firstName_ar)) {
        this.validationErrors["firstName_ar"] = true;
      } else {
        delete this.validationErrors["firstName_ar"];
      }
      if (!/^[a-zA-Z\u0600-\u06FF\s]+$/.test(this.secondName_ar)) {
        this.validationErrors["secondName_ar"] = true;
      } else {
        delete this.validationErrors["secondName_ar"];
      }

      if (this.thirdName_ar == "" || this.thirdName_ar == undefined)
        this.validationErrors["thirdName_ar"] = true;
      else delete this.validationErrors["thirdName_ar"];

      if (this.lastName_ar == "" || this.lastName_ar == undefined)
        this.validationErrors["lastName_ar"] = true;
      else delete this.validationErrors["lastName_ar"];

      if (!this.selectedgender.code) this.validationErrors["selectedgender"] = true;
      else delete this.validationErrors["selectedgender"];

      if (this.Birth_Date == "" || this.Birth_Date == undefined)
        this.validationErrors["Birth_Date"] = true;
      else delete this.validationErrors["Birth_Date"];

      if (!this.Birth_Place.code) this.validationErrors["Birth_Place"] = true;
      else delete this.validationErrors["Birth_Place"];

      return !Object.keys(this.validationErrors).length;
    },

    uploadImg() {
      if (this.isSubmitted == "false") {
        this.$store
          .dispatch("BasicInfo/ApplicantPhotoUpload", {
            file: this.image,
          })
          .then((res) => {
            debugger
            this.imgPath = res.data.photo;
           this.imageLocation =res.data.saveFileLocation;
           this.imageName=res.data.saveFileLocation.replace(/\\/g, '/').split('/').pop();;
          })
          .catch((error) => {
            // this.$toast.add({
            //   severity: "error",
            //   detail: this.$t(error.response.data.Message),
            //   life: 3000,
            // });
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(error.response.data.Message),
            });
          });
      }
    },
    saveBasicInfoPage() {
      if (this.isSubmitted == "false") {
        this.submitted = true;
        if (this.validateForm()) {
          debugger
          this.$store.commit("showLoaderFn", true);
          this.$store
            .dispatch("BasicInfo/Update_ApplicantBasicInformation", {
              fnamE_EN: this.firstName,
              mnamE_EN: this.secondName,
              lnamE_EN: this.thirdName,
              familY_EN: this.lastName,
              fnamE_AR: this.firstName_ar,
              mnamE_AR: this.secondName_ar,
              lnamE_AR: this.thirdName_ar,
              familY_AR: this.lastName_ar,
              gS_CODE_GENDER_ID: this.selectedgender.code,
              gS_BIRTH_COUNTRY_NODE_ID: this.Birth_Place.code,
              birthdate: this.Birth_Date,
              photO_PATH: this.imageName,
            })
            .then((res) => {
              if (res.status) {
                // this.$toast.add({
                //   severity: "success",
                //   detail: this.$t("dataSavedSuccessfuly"),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t("dataSavedSuccessfuly"),
                });
                this.$emit("nextPage", 1);
                this.$store.commit("showLoaderFn", false);
              }
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("fillTheMissedData"),
          });
        }
      } else {
        this.$emit("nextPage", 1);
      }
    },
  },
  created() {
    // this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Auth/fetchCountriesData");
    // this.$store.dispatch("BasicInfo/fetchGendersData");

    this.$store.dispatch("BasicInfo/fetchBasicInfoData").then((result) => {
      console.log(result);
      debugger;
      this.firstName = result.fname;
      this.secondName = result.mname;
      this.thirdName = result.lname;
      this.lastName = result.family;
      this.firstName_ar = result.fname_ar;
      this.secondName_ar = result.mname_ar;
      this.thirdName_ar = result.lname_ar;
      this.lastName_ar = result.family_ar;
      this.Birth_Date = result.birthdate != null ? result.birthdate.substr(0, 10) : "";
      this.Birth_Place = {
        code: result.gS_BIRTH_COUNTRY_NODE_ID,
        name: result.gS_BIRTH_COUNTRY_NODE,
      };
   
      this.selectedgender = { code: result.gS_CODE_GENDER_ID, name: result.gS_GENDER };
      this.imgPath = result.photo;
      // this.imgPath = res.data.photo;
console.log(result);
     this. imageLocation=result.photO_PATH;
     this.imageName=result.photO_PATH.replace(/\\/g, '/').split('/').pop();
// const filename = path.split(/[/\\]/).pop();  // Regex to split by either / or \
console.log(this.imageName);  // Outputs: 89.jpeg

debugger

      this.$store.commit("showLoaderFn", false);
    });
    // this.$store.commit("showLoaderFn", false);
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}

.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.emptyIMgError {
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
}
</style>
