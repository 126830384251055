<template>
  <div class="flex">
    <div class="flex flex-row md:flex-column">
      <div class="flex sm:align-items-center">
        <div v-if="index > currentTabIndex" class="stepTempContainer">
          <img class="stepImg" :src="inactive()" />
          <div class="centered">{{ index + 1 }}</div>
        </div>

        <div v-if="index == currentTabIndex" class="stepTempContainer">
          <img class="stepImg" :src="activeUncompleted()" />
          <div class="centered">{{ index + 1 }}</div>
        </div>

        <div v-if="index < currentTabIndex" class="stepTempContainer">
          <img class="stepImg" :src="completedStep()" />
          <!-- <div class="centered">{{ index + 1 }}</div> -->
        </div>

        <img
          v-if="index > currentTabIndex"
          class="stepImg stepImgWithoutNumb"
          :src="inactive()"
        />
        <img
          v-if="currentTabIndex == index"
          class="stepImg stepImgWithoutNumb"
          :src="activeUncompleted()"
        />
        <img
          v-if="index < currentTabIndex"
          class="stepImg stepImgWithoutNumb"
          :src="completedStep()"
        />
      </div>
      <div
        v-if="currentTabIndex >= index"
        :class="{
          verticalSolidBar: mainColor == 1,
          verticalSolidBar_brown: mainColor == 2,
        }"
        :style="{ display: index != tabLength - 1 ? 'block' : 'none' }"
      ></div>
      <div
        v-else
        :class="{
          verticaldashedBar: mainColor == 1,
          verticaldashedBar_brown: mainColor == 2,
        }"
        :style="{ display: index != tabLength - 1 ? 'block' : 'none' }"
      ></div>
    </div>

    <span
      class="tabTitleSpan hidden md:inline"
      :style="{
        color: index > currentTabIndex ? 'grey' : mainColor == 1 ? '#028BFF' : '#D89436',
      }"
      >{{ tab.title }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    tab: Object,
    index: Number,
    currentTabIndex: Number,
    mainColor: Number,
    tabLength: Number,
  },
  computed: {},
  methods: {
    completedStep() {
      return this.mainColor == 1
        ? require("../../../assets/images/svgs/completedStep.svg")
        : require("../../../assets/images/svgs/completedStep_brown.svg");
    },
    activeUncompleted() {
      return this.mainColor == 1
        ? require("../../../assets/images/svgs/activeUncompleted.svg")
        : require("../../../assets/images/svgs/activeUncompleted_brown.svg");
    },
    inactive() {
      return this.mainColor == 1
        ? require("../../../assets/images/svgs/inactive.svg")
        : require("../../../assets/images/svgs/inactive_brown.svg");
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 500px) {
  .stepImg {
    width: 25px;
  }

  .stepTempContainer {
    display: none;
  }
  .verticalSolidBar {
    border-right: 2px solid var(--main-color);
    height: 25px;
    width: 3px;
    margin: 2px auto;
    margin-inline-start: 10px;
  }

  .verticaldashedBar {
    border-right: 2px dashed var(--main-color);
    height: 25px;
    width: 3px;
    margin: 2px auto;
    margin-inline-start: 10px;
  }

  .verticalSolidBar_brown {
    border-right: 2px solid var(--main-color2);
    height: 25px;
    width: 3px;
    margin: 2px auto;
    margin-inline-start: 10px;
  }

  .verticaldashedBar_brown {
    border-right: 2px dashed var(--main-color2);
    height: 25px;
    width: 3px;
    margin: 2px auto;
    margin-inline-start: 10px;
  }
}

@media screen and (max-width: 500px) {
  .stepImg {
    width: 35px;
  }

  .stepImgWithoutNumb {
    display: none;
  }

  .stepTempContainer {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--main-color);
    font-weight: bold;
    font-size: 0.8rem;
  }

  .verticalSolidBar {
    border-bottom: 2px solid var(--main-color);
    height: 3px;
    width: 35px;
    margin: auto;
  }

  .verticaldashedBar {
    border-bottom: 2px solid var(--main-color);
    height: 3px;
    width: 35px;
    margin: auto;
  }

  .verticalSolidBar_brown {
    border-bottom: 2px solid var(--main-color2);
    height: 3px;
    width: 35px;
    margin: auto;
  }

  .verticaldashedBar_brown {
    border-bottom: 2px dashed var(--main-color2);
    height: 3px;
    width: 35px;
    margin: auto;
  }
}

.tabTitleSpan {
  font-size: 80%;
  font-family: "Cairo";
  inline-size: 200px;
  overflow-wrap: break-word;
  text-align: start;
  padding-inline-start: 5px;
  font-weight: bold;
}
</style>
