<template>
  <div class="mainForm">
    <div class="p-3">
      <div class="p-inputgroup flex-1 inputGroup" style="display: none">
        <div class="labelDiv col-2">
          <span>{{ $t("Applicant_Type") }}</span
          ><span style="color: red">*</span>
        </div>

        <div class="flex flex-wrap inputDiv col-4 gap-3">
          <div class="flex align-items-center">
            <RadioButton
              v-model="Applicant_Type"
              inputId="Applicant_Type"
              name="Applicant_Type"
              value="1"
            />
            <label for="ingredient1" class="m-1">{{ $t("New_Applicant") }}</label>
          </div>
          <div class="flex align-items-center" style="pointer-events: none">
            <RadioButton
              v-model="Applicant_Type"
              inputId="Applicant_Type"
              disabled
              name="Applicant_Type"
              value="2"
            />
            <label for="ingredient2" class="m-1">{{ $t("Depletion_Failure") }}</label>
          </div>
        </div>
        <!-- <div
          v-if="validationErrors.selectedDegreeType && submitted"
          class="validationErro col-2"
        >
          {{ $t("Check_This_Field") }}
        </div> -->
      </div>
      <div class="p-inputgroup flex-1 inputGroup">
        <div class="col-6 flex flex-row align-items-center">
          <div class="labelDiv col-4">
            <span>{{ $t("Degree_Type") }}</span
            ><span style="color: red">*</span>
          </div>
          <div class="col-8 inputDiv">
            <Dropdown
              v-model="selectedDegreeType"
              :options="degreeTypesData"
              optionLabel="name"
              @change="fetchDegreesByType"
              :placeholder="degreeTypeDDLPlaceholder"
              :class="[
                'w-full m-1',
                { 'p-invalid': validationErrors.selectedDegreeType && submitted },
              ]"
            />
          </div>
          <!-- <div
            v-if="validationErrors.selectedDegreeType && submitted"
            class="validationErro col-6"
          >
            {{ $t("Check_This_Field") }}
          </div> -->
        </div>
        <div class="col-6 flex flex-row align-items-center">
          <div class="labelDiv col-4">
            <span>{{ $t("Degree") }}</span
            ><span style="color: red">*</span>
          </div>
          <div class="col-8 inputDiv">
            <Dropdown
              v-model="selectedDegree"
              :options="degreesData"
              optionLabel="name"
              :loading="degreesData.length == 0 && selectedDegreeType != {}"
              :placeholder="degreeDDLPlaceholder"
              :class="[
                'w-full m-1',
                { 'p-invalid': validationErrors.selectedDegree && submitted },
              ]"
            />
          </div>
          <!-- <div
            v-if="validationErrors.selectedDegree && submitted"
            class="validationErro col-6"
          >
            {{ $t("Check_This_Field") }}
          </div> -->
        </div>
      </div>

      <div class="p-inputgroup flex-1 inputGroup">
        <div class="col-6 flex flex-row align-items-center">
          <div class="labelDiv col-4">
            <span>{{ $t("Nationality") }}</span
            ><span style="color: red">*</span>
          </div>
          <div class="col-8 inputDiv">
            <Dropdown
              v-model="selectedCountry"
              :options="countriesData"
              optionLabel="name"
              filter
              :placeholder="countryDDLPlaceholder"
              :class="[
                'w-full m-1',
                { 'p-invalid': validationErrors.selectedCountry && submitted },
              ]"
              class="w-full m-1"
            />
          </div>
          <!-- <div
            v-if="validationErrors.selectedCountry && submitted"
            class="validationErro col-6"
          >
            {{ $t("Check_This_Field") }}
          </div> -->
        </div>
      </div>
      <div class="p-inputgroup col-12 flex-column flex-1 inputGroup">
        <div class="flex">
          <div class="labelDiv col-2">
            <span>{{ $t("Passport_No") }}</span
            ><span style="color: red">*</span>
          </div>
          <div class="col-4 inputDiv">
            <InputText
              :placeholder="insertPassportPlaceholder"
              v-model="Passport_No"
              @keydown="validatePassportNumber"
              @change="validatePassportNumber"
              @input="validatePassportNumber"
              :class="[
                'w-full p-input-sm m-1',
                {
                  'p-invalid': validationErrors.Passport_No && submitted,
                },
              ]"
            />
          </div>
          <!-- <div
            v-if="validationErrors.Passport_No && submitted"
            class="validationErro col-2"
          >
            {{ $t("Check_This_Field") }}
          </div> -->
        </div>
        <div class="flex" v-if="validationErrors.not_valid_passport">
          <div class="labelDiv col-2"></div>
          <div
            v-if="validationErrors.not_valid_passport"
            class="validationErro col-4 inputDiv"
            style="padding: 0px !important"
          >
            {{ $t("notValidPassport") }}
          </div>
        </div>
      </div>
      <div class="p-inputgroup flex-row flex-1 inputGroup">
        <div class="p-inputgroup col-6 flex-1 flex-column inputGroup">
          <div class="flex">
            <div class="labelDiv col-4">
              <span>{{ $t("Passport_Date") }}</span
              ><span style="color: red">*</span>
            </div>
            <div class="col-8 inputDiv">
              <Calendar
                v-model="Passport_Date"
                dateFormat="yy-mm-dd"
                @hide="checkForPassportIssueDate"
                showIcon
                :class="[
                  'w-full m-1',
                  {
                    'p-invalid': validationErrors.Passport_Date_not_correct && submitted,
                  },
                ]"
              />
            </div>
            <!-- <div
              v-if="validationErrors.Passport_Date_not_correct && submitted"
              class="validationErro col-4"
            >
              {{ $t("Check_This_Field") }}
            </div> -->
          </div>

          <div class="flex" v-if="validationErrors.Passport_Date_not_correct">
            <div class="labelDiv col-4"></div>
            <div
              v-if="validationErrors.Passport_Date_not_correct"
              class="validationErro col-8 inputDiv"
              style="padding: 0px !important"
            >
              {{ $t("Passport_Date_not_correct") }}
            </div>
          </div>
        </div>

        <div class="p-inputgroup col-6 flex-1 flex-column inputGroup">
          <div class="flex">
            <div class="labelDiv col-4">
              <span>{{ $t("Passport_Exp_Date") }}</span
              ><span style="color: red">*</span>
            </div>
            <div class="col-8 inputDiv">
              <Calendar
                v-model="Passport_Exp_Date"
                @hide="checkForExpirationDuration"
                dateFormat="yy-mm-dd"
                showIcon
                :class="[
                  'w-full m-1',
                  { 'p-invalid': validationErrors.Passport_Exp_Date && submitted },
                ]"
              />
            </div>
            <!-- <div
              v-if="validationErrors.Passport_Exp_Date && submitted"
              class="validationErro col-6"
            >
              {{ $t("Check_This_Field") }}
            </div> -->
          </div>

          <div class="flex" v-if="validationErrors.Passport_Exp_Date_not_correct">
            <div class="labelDiv col-4"></div>
            <div
              v-if="validationErrors.Passport_Exp_Date_not_correct"
              class="validationErro col-8 inputDiv"
              style="padding: 0px !important"
            >
              {{ $t("MSG_03") }}
            </div>
          </div>

          <!-- <div
        v-if="validationErrors.Passport_Exp_Date_not_correct"
        class="validationErro col-12"
        style="padding: 0px !important"
      >
        {{ $t("MSG_03") }}
      </div> -->
        </div>
      </div>

      <div class="p-inputgroup col-12 flex-column flex-1 inputGroup">
        <div class="flex">
          <div class="labelDiv col-2">
            <span>{{ $t("E-Mail") }}</span
            ><span style="color: red">*</span>
          </div>
          <div class="col-4 inputDiv">
            <InputText
              :placeholder="insertPassportPlaceholder"
              v-model="emailField"
              @keydown="validateEmail"
              @change="validateEmail"
              @input="validateEmail"
              :class="[
                'w-full p-input-sm  m-1',
                {
                  'p-invalid': validationErrors.emailField && submitted,
                },
              ]"
            />
          </div>
          <!-- <div
            v-if="validationErrors.emailField && submitted"
            class="validationErro col-2"
          >
            {{ $t("Check_This_Field") }}
          </div> -->
        </div>

        <div class="flex" v-if="validationErrors.not_valid_email">
          <div class="labelDiv col-2"></div>
          <div
            v-if="validationErrors.not_valid_email"
            class="validationErro col-4 inputDiv"
            style="padding: 0px !important"
          >
            {{ $t("notValidEmail") }}
          </div>
          <div class="col-6"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex m-3 justify-content-end">
        <Button
          :label="nextBtnTxt"
          class="btnClass"
          style="background-color: #d89436; border: none"
          @click="saveRegestrationPage"
          icon="pi pi-angle-right"
          iconPos="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../store/mixin.js";
export default {
  mixins: [mixin],
  data() {
    return {
      submitted: false,
      Applicant_Type: "1",
      selectedDegreeType: {},
      selectedDegree: {},
      selectedCountry: {},
      Passport_No: "",
      Passport_Date: "",
      Passport_Exp_Date: "",
      emailField: "",
      validationErrors: {},
    };
  },
  computed: {
    degreeTypeDDLPlaceholder() {
      return this.$t("degreeTypeDDLPlaceholder");
    },
    degreeDDLPlaceholder() {
      return this.$t("degreeDDLPlaceholder");
    },
    countryDDLPlaceholder() {
      return this.$t("countryDDLPlaceholder");
    },
    registerBtnLabel() {
      return this.$t("Submit");
    },
    degreeTypesData() {
      return this.$store.getters["Auth/getDegreeTypesData"];
    },
    degreesData() {
      return this.$store.getters["Auth/getDegreesData"];
    },
    countriesData() {
      return this.$store.getters["Auth/getCountriesData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    checkForExpirationDuration() {
      var Passport_Exp_DT = new Date(this.Passport_Exp_Date);
      var todate_DT = new Date();

      if (
        Passport_Exp_DT.getMonth() -
          todate_DT.getMonth() +
          12 * (Passport_Exp_DT.getFullYear() - todate_DT.getFullYear()) <
        6
      ) {
        this.validationErrors["Passport_Exp_Date_not_correct"] = true;
      } else delete this.validationErrors["Passport_Exp_Date_not_correct"];
    },
    checkForPassportIssueDate() {
      var Passport_date_DT = new Date(this.Passport_Date);
      var todate_DT = new Date();

      if (Passport_date_DT > todate_DT) {
        this.validationErrors["Passport_Date_not_correct"] = true;
      } else delete this.validationErrors["Passport_Date_not_correct"];
    },
    validateForm() {
      if (!this.selectedDegreeType.code)
        this.validationErrors["selectedDegreeType"] = true;
      else delete this.validationErrors["selectedDegreeType"];
      if (!this.selectedDegree.code) this.validationErrors["selectedDegree"] = true;
      else delete this.validationErrors["selectedDegree"];
      if (!this.selectedCountry.code) this.validationErrors["selectedCountry"] = true;
      else delete this.validationErrors["selectedCountry"];
      if (this.Passport_No == "") this.validationErrors["Passport_No"] = true;
      else delete this.validationErrors["Passport_No"];
      if (this.Passport_Date == "") this.validationErrors["Passport_Date"] = true;
      else delete this.validationErrors["Passport_Date"];
      if (this.Passport_Exp_Date == "") this.validationErrors["Passport_Exp_Date"] = true;
      else delete this.validationErrors["Passport_Exp_Date"];
      // if (!this.checkForExpirationDuration())
      //   this.validationErrors["Passport_Exp_Date_not_correct"] = true;
      // else delete this.validationErrors["Passport_Exp_Date_not_correct"];
      if (this.emailField == "") this.validationErrors["emailField"] = true;
      else delete this.validationErrors["emailField"];
      return !Object.keys(this.validationErrors).length;
    },
    validateEmail() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
        this.validationErrors["not_valid_email"] = true;
      } else delete this.validationErrors["not_valid_email"];
    },
    validatePassportNumber() {
      if (!/^[a-zA-Z0-9]{5,20}$/.test(this.Passport_No)) {
        this.validationErrors["not_valid_passport"] = true;
      } else delete this.validationErrors["not_valid_passport"];
    },
    async fetchDegreesByType() {
      await this.$store.dispatch("Auth/fetchDegreesData", this.selectedDegreeType);
    },
    saveRegestrationPage() {
      if (
        this.$store.getters["Auth_embassy/getSelectedAdmApplicantId"] == 0 &&
        this.$store.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"] == 0
      ) {
        this.submitted = true;
        if (this.validateForm()) {
          this.$store.commit("showLoaderFn", true);
          this.$store
            .dispatch("Auth_embassy/ApplicantRegistration", {
              asCodeDegreeClassId: this.selectedDegreeType.code,
              asCodeDegreeId: this.selectedDegree.code,
              gsCountryNodeId: this.selectedCountry.code,
              applicantPassportNo: this.Passport_No,
              passportDate: this.Passport_Date,
              passportExpireDate: this.Passport_Exp_Date,
              applicantEmail: this.emailField,
              Applicant_Type: this.Applicant_Type,
            })
            .then((res) => {
              if (res.status) {
                this.$emit("nextPage", 1);
                this.$store.commit("showLoaderFn", false);
              }
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("fillTheMissedData"),
          });
        }
      } else {
        this.$emit("nextPage", 1);
      }
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Auth/fetchCountriesData");
    this.$store.dispatch("Auth/fetchDegreeTypesData").then(() => {
      if (
        this.$store.getters["Auth_embassy/getSelectedAdmApplicantId"] != 0 &&
        this.$store.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"] != 0
      ) {
        this.$store.dispatch("BasicInfo_embassy/fetchBasicInfoData").then((result) => {
          this.selectedDegreeType = this.degreeTypesData.filter(
            (c) => c.code == result.asCodeDegreeClassId
          )[0];
          this.fetchDegreesByType().then(() => {
            this.selectedDegree = this.degreesData.filter(
              (c) => c.code == result.asCodeDegreeId
            )[0];
          });
          this.selectedCountry = {
            code: result.gsCountyNodeID,
            name: result.gsCountyNodeNatonalti,
          };

          this.Passport_No = result.applicantPassportNo;
          this.emailField = result.applicantEmail;
          this.Passport_Date =
            result.passportIssueDate != null
              ? result.passportIssueDate.substr(0, 10)
              : "";
          this.Passport_Exp_Date =
            result.passportExpireDate != null
              ? result.passportExpireDate.substr(0, 10)
              : "";

          this.$store.commit("showLoaderFn", false);
        });
      } else {
        this.$store.commit("showLoaderFn", false);
      }
    });
  },
};
</script>

<style scoped>
>>> .p-calendar-w-btn .p-datepicker-trigger {
  background-color: #d89436 !important;
  border: none;
}

:deep(.p-inputtext) {
  color: var(--main-color);
}

:deep(.p-button-icon) {
  font-size: 20px;
}

.validationErro {
  color: red;
  font-size: 0.8vw;
  line-height: 2;
  text-align: center;
}

.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
