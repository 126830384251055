<template>
  <div class="w-screen h-screen m-auto mainDiv">
    <headerBar :mainColor="2"></headerBar>
    <div>
      <div
        style="
          margin: 15px auto;
          width: 85%;
          display: flex;
          flex-direction: row;
          padding-inline: 20px;
        "
      >
        <div class="steptUpperTitle">{{ $t("SearchForApplicationLabel") }}</div>
        <div class="dashedLineDiv"></div>
        <Button
          :label="createNewApplicationLabel"
          class="btnClass newApplicantBtn"
          @click="openNewApplicant"
        />
      </div>
      <div
        class="flex flex-column justify-content-between surface-0 shadow-2 p-2"
        style="width: 85%; margin: 5px auto; border-radius: 10px"
      >
        <div class="flex flex-row justify-content-between">
          <div class="flex-1 m-2">
            <div>
              <span>{{ $t("AcadmicYear") }}</span>
              <span style="color: red">*</span>
            </div>
            <Dropdown
              v-model="selectedAcadYear"
              :options="acadmicYearsData"
              optionLabel="edAcadYear_Descr"
              filter
              :placeholder="selectPlaceholder"
              :class="[
                'w-full m-1',
                { 'p-invalid': validationErrors.selectedAcadYear },
              ]"
            />
          </div>
          <div class="flex-1 m-2">
            <div>
              <span>{{ $t("Semester") }}</span>
              <span style="color: red">*</span>
            </div>
            <Dropdown
              v-model="selectedSemester"
              :options="semesterData"
              optionLabel="edCodeSemester_Descr"
              filter
              :placeholder="selectPlaceholder"
              @change="onChangeSemster"
              :class="[
                'w-full m-1',
                { 'p-invalid': validationErrors.selectedSemester },
              ]"
            />
          </div>
          <div class="flex-1 m-2">
            <div>{{ $t("ApplicantName") }}</div>
            <InputText
              placeholder=""
              v-model="applicantName"
              class="w-full m-1"
            />
          </div>
        </div>

        <div class="flex flex-row justify-content-between">
          <div class="flex-1 m-2">
            <div>{{ $t("SemesterFromDate") }}</div>
            <Calendar
              v-model="selectedSemesterFromDate"
              showIcon
              :class="['w-full m-1']"
            />
          </div>
          <div class="flex-1 m-2">
            <div>{{ $t("SemesterToDate") }}</div>
            <Calendar
              v-model="selectedSemesterToDate"
              showIcon
              :class="['w-full m-1']"
            />
          </div>
          <div class="flex-1 m-2">
            <div>{{ $t("ApplicantType") }}</div>
            <Dropdown
              v-model="selectedApplicantType"
              :options="applicantTypeData"
              optionLabel="admTypeDescr"
              filter
              :placeholder="selectPlaceholder"
              :class="['w-full m-1']"
            />
          </div>
        </div>

        <!-- Advanced Search -->
        <div v-if="showAdvancedSearch">
          <div class="flex flex-row justify-content-between">
            <div class="flex-1 m-2">
              <div>{{ $t("Faculty") }}</div>
              <Dropdown
                v-model="selectedFaculty"
                :options="facultyData"
                optionLabel="entMainDescr"
                filter
                :placeholder="selectPlaceholder"
                @change="onChangeFacultySelect"
                :class="[
                  'w-full m-1',
                  { 'p-invalid': validationErrors.selectedSchool && submitted },
                ]"
              />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("Department") }}</div>
              <Dropdown
                v-model="selectedDepartment"
                :options="departmentsData"
                optionLabel="entMainDescr"
                filter
                :placeholder="selectPlaceholder"
                :loading="loadingDepartments"
                @change="onChangeDepartmentSelect"
                :class="[
                  'w-full m-1',
                  { 'p-invalid': validationErrors.selectedSchool && submitted },
                ]"
              />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("Major") }}</div>
              <Dropdown
                v-model="selectedMajor"
                :options="majorData"
                optionLabel="entMainDescr"
                filter
                :placeholder="selectPlaceholder"
                :loading="loadingMajor"
                :class="['w-full m-1']"
              />
            </div>
          </div>

          <div class="flex flex-row justify-content-between">
            <div class="flex-1 m-2">
              <div>{{ $t("SubmitDateFrom") }}</div>
              <Calendar
                v-model="submitDateFrom"
                showIcon
                :class="['w-full m-1']"
              />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("SubmitDateTo") }}</div>
              <Calendar
                v-model="submitDateTo"
                showIcon
                :class="['w-full m-1']"
              />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("ApplicantStatus") }}</div>
              <Dropdown
                v-model="selectedApplicantStatus"
                :options="applicantStatusData"
                optionLabel="applicantStatusDescription"
                filter
                :placeholder="selectPlaceholder"
                :class="['w-full m-1']"
              />
            </div>
          </div>

          <div class="flex flex-row justify-content-between">
            <div class="flex-1 m-2">
              <div>{{ $t("Nationality") }}</div>
              <Dropdown
                v-model="selectedNational"
                :options="nationalitiesData"
                optionLabel="countryName"
                filter
                :placeholder="selectPlaceholder"
                :class="['w-full m-1']"
              />
            </div>

            <div class="flex-1 m-2">
              <div>{{ $t("QualificationType") }}</div>
              <Dropdown
                v-model="selectedQualificationType"
                :options="qualificationTypeData"
                optionLabel="qualificationTypeDescription"
                filter
                :placeholder="selectPlaceholder"
                @change="onChangeQualificationTypeSelect"
                :class="['w-full m-1']"
              />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("QualificationName") }}</div>
              <Dropdown
                v-model="selectedQualificationName"
                :options="qualificationNameData"
                optionLabel="qualificationNameDescription"
                filter
                :placeholder="selectPlaceholder"
                :loading="loadingQualificationName"
                :class="['w-full m-1']"
              />
            </div>
          </div>

          <div class="flex flex-row justify-content-between">
            <div class="flex-1 m-2">
              <div>{{ $t("Gender") }}</div>
              <Dropdown
                v-model="selectedGender"
                :options="genderData"
                optionLabel="genderDescription"
                filter
                :placeholder="selectPlaceholder"
                @change="onChangeDepartmentSelect"
                :class="['w-full m-1']"
              />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("PassportNumber") }}</div>
              <InputText v-model="cpr" :class="['w-full m-1']" />
            </div>
            <div class="flex-1 m-2">
              <div>{{ $t("ApplicantCode") }}</div>
              <InputText v-model="admAppCode" :class="['w-full m-1']" />
            </div>
          </div>
        </div>

        <div
          class="flex flex-row justify-content-arround justify-content-center mt-2"
        >
          <Button
            :label="clearSearchBtnLabel"
            class="btnClass clearAllBtn"
            @click="clearAll"
            style="margin-inline: 25px"
          />
          <Button
            :label="SearchBtnLabel"
            class="btnClass searchBtn"
            @click="searchForApplicant"
            style="margin-inline: 25px"
          />
          <Button
            :label="AdvancedSearchBtnLabel"
            class="btnClass advancedSearchBtn"
            @click="toggleAdvancedSearch"
            style="margin-inline: 25px"
          />
        </div>
      </div>
      <div
        class="flex flex-column justify-content-between p-1"
        style="width: 85%; margin: 5px auto; border-radius: 10px"
      >
        <applicantTable :applicantsData="applicantsData"></applicantTable>
      </div>
    </div>
  </div>
</template>

<script>
import headerBar from "../../components/custom-components/applicant_application_master/header_bar.vue";
import applicantTable from "../../components/custom-components/embassy_officer/searchTable.vue";

export default {
  components: {
    headerBar,
    applicantTable,
  },
  data() {
    return {
      showAdvancedSearch: false,
      selectedAcadYear: {},
      selectedSemester: {},
      selectedSemesterFromDate: "",
      selectedSemesterToDate: "",
      selectedApplicantStatus: {},
      selectedApplicantType: {},
      selectedFaculty: {},
      selectedDepartment: {},
      loadingDepartments: false,
      selectedMajor: {},
      loadingMajor: false,
      selectedNational: {},
      selectedGender: {},
      selectedQualificationType: {},
      selectedQualificationName: {},
      loadingQualificationName: false,
      submitDateFrom: "",
      submitDateTo: "",
      applicantName: "",
      cpr: "",
      admAppCode: "",
      validationErrors: {},
      searchParams: {
        edAcadYearId: "",
        edCodeSemesterId: "",
        semDateFrom: "",
        semDateTo: "",
        submitDateFrom: "",
        submitDateTo: "",
        admCdeAppTypeID: "",
        admCdeStatusId: "",
        fac_EntMainId: "",
        dept_EntMainId: "",
        mjr_EntMainID: "",
        admAppCode: "",
        app_CPR_PASSPORT: "",
        applicantName: "",
        nationality_GsCountryNodeId: "",
        gsCodeGenderID: "",
        edCdeQualCatId: "",
        edCodeQualId: "",
      },
    };
  },
  computed: {
    applicantsData() {
      return this.$store.getters["Applicant_search/getApplicantsData"];
    },
    acadmicYearsData() {
      this.selectedAcadYear =
        this.$store.getters[
          "Applicant_search/getAcadmicYearsData"
        ].academicYearCurrents;
      return this.$store.getters["Applicant_search/getAcadmicYearsData"]
        .academicYears;
    },
    semesterData() {
      this.selectedSemester =
        this.$store.getters[
          "Applicant_search/getSemesterData"
        ].currentSemesters;
      return this.$store.getters["Applicant_search/getSemesterData"].semesters;
    },
    applicantStatusData() {
      return this.$store.getters["Applicant_search/getApplicantStatusData"];
    },
    applicantTypeData() {
      return this.$store.getters["Applicant_search/getApplicantTypeData"];
    },
    facultyData() {
      return this.$store.getters["Applicant_search/getFacultyData"];
    },
    departmentsData() {
      return this.$store.getters["Applicant_search/getDepartmentsData"];
    },
    majorData() {
      return this.$store.getters["Applicant_search/getMajorData"];
    },
    nationalitiesData() {
      return this.$store.getters["Applicant_search/getNationalitiesData"];
    },
    genderData() {
      return this.$store.getters["Applicant_search/getGenderData"];
    },
    qualificationTypeData() {
      return this.$store.getters["Applicant_search/getQualificationTypeData"];
    },
    qualificationNameData() {
      return this.$store.getters["Applicant_search/getQualificationNameData"];
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    SearchForApplicationLabel() {
      return this.$t("SearchForApplicationLabel");
    },
    createNewApplicationLabel() {
      return this.$t("createNewApplicationLabel");
    },
    insertEmailPlaceholder() {
      return this.$t("insertEmailPlaceholder");
    },
    SearchBtnLabel() {
      return this.$t("SearchBtnLabel");
    },
    AdvancedSearchBtnLabel() {
      return this.showAdvancedSearch
        ? this.$t("CloseAdvancedSearchBtnLabel")
        : this.$t("OpenAdvancedSearchBtnLabel");
    },
    clearSearchBtnLabel() {
      return this.$t("clearSearchBtnLabel");
    },
  },
  methods: {
    openNewApplicant() {
      // this.$store.commit("Auth_embassy/setViewOnly", false);
      // this.$store.commit("Auth_embassy/setSelectedAdmApplicantId", 0);
      // this.$store.commit("Auth_embassy/setSelectedAdmAppRegHistoryId", 0);
      localStorage.setItem("isSubmitted", "false");
      localStorage.setItem("ViewOnly", false);
      localStorage.setItem("AdmApplicantId", 0);
      localStorage.setItem("AdmAppRegHistoryId", 0);
      this.$router.push("/application_embassy");
    },
    async onChangeSemster() {
      await this.$store
        .dispatch("Applicant_search/fetchSemesterFromToData", {
          edAcadYearID: this.selectedAcadYear.edAcadYear,
          edCodeSemesterID: this.selectedSemester.edCodeSemesterID,
        })
        .then((res) => {
          this.selectedSemesterFromDate = res.from_to.fromDate.substring(0, 10);
          this.selectedSemesterToDate = res.from_to.toDate.substring(0, 10);
        });
    },
    async onChangeFacultySelect() {
      this.loadingDepartments = true;
      this.loadingMajor = true;
      await this.$store
        .dispatch(
          "Applicant_search/fetchDepartmentsData",
          this.selectedFaculty.entMainId
        )
        .then(() => {
          this.loadingDepartments = false;
        })
        .catch(() => {
          this.loadingDepartments = false;
        });

      await this.$store
        .dispatch("Applicant_search/fetchMajorData", {
          facultyId: this.selectedFaculty.entMainId,
          departmentId: this.selectedDepartment.entMainId,
        })
        .then(() => {
          this.loadingMajor = false;
        })
        .catch(() => {
          this.loadingMajor = false;
        });
    },
    async onChangeDepartmentSelect() {
      this.loadingMajor = true;
      await this.$store
        .dispatch("Applicant_search/fetchMajorData", {
          facultyId: this.selectedFaculty.entMainId,
          departmentId: this.selectedDepartment.entMainId,
        })
        .then(() => {
          this.loadingMajor = false;
        })
        .catch(() => {
          this.loadingMajor = false;
        });
    },
    async onChangeQualificationTypeSelect() {
      this.loadingQualificationName = true;
      await this.$store
        .dispatch(
          "Applicant_search/fetchQualificationNameData",
          this.selectedQualificationType.qualificationTypeId
        )
        .then(() => {
          this.loadingQualificationName = false;
        })
        .catch(() => {
          this.loadingQualificationName = false;
        });
    },
    async searchForApplicant() {
      if (this.validateForm()) {
        this.$store.commit("showLoaderFn", true);
        this.searchParams = {
          edAcadYearId: this.selectedAcadYear.edAcadYear,
          edCodeSemesterId: this.selectedSemester.edCodeSemesterID,
          semDateFrom: this.selectedSemesterFromDate
            ? new Date(this.selectedSemesterFromDate).toISOString()
            : "",
          semDateTo: this.selectedSemesterToDate
            ? new Date(this.selectedSemesterToDate).toISOString()
            : "",
          submitDateFrom: this.submitDateFrom
            ? new Date(this.submitDateFrom).toISOString()
            : "",
          submitDateTo: this.submitDateTo
            ? new Date(this.submitDateTo).toISOString()
            : "",
          admCdeAppTypeID: this.selectedApplicantType.admTypeId || "",
          admCdeStatusId: this.selectedApplicantStatus.applicantStatusId || "",
          fac_EntMainId: this.selectedFaculty.entMainId || "",
          dept_EntMainId: this.selectedDepartment.entMainId || "",
          mjr_EntMainID: this.selectedMajor.entMainId || "",
          admAppCode: this.admAppCode,
          app_CPR_PASSPORT: this.cpr || "",
          applicantName: this.applicantName,
          nationality_GsCountryNodeId:
            this.selectedNational.gsCountryNodeId || "",
          gsCodeGenderID: this.selectedGender.genderId || "",
          edCdeQualCatId:
            this.selectedQualificationType.qualificationTypeId || "",
          edCodeQualId:
            this.selectedQualificationName.qualificationNameId || "",
        };
        this.$store
          .dispatch("Applicant_search/fetchApplicantsData", this.searchParams)
          .then(() => {
            this.$store.commit("showLoaderFn", false);
          })
          .catch(() => {
            this.$store.commit("showLoaderFn", false);
          });
      }
    },
    clearAll() {
      this.selectedAcadYear = {};
      this.selectedSemester = {};
      this.selectedSemesterFromDate = "";
      this.selectedSemesterToDate = "";
      this.selectedApplicantStatus = {};
      this.selectedApplicantType = {};
      this.selectedFaculty = {};
      this.selectedDepartment = {};
      this.loadingDepartments = false;
      this.selectedMajor = {};
      this.loadingMajor = false;
      this.selectedNational = {};
      this.selectedGender = {};
      this.selectedQualificationType = {};
      this.selectedQualificationName = {};
      this.loadingQualificationName = false;
      this.submitDateFrom = "";
      this.submitDateTo = "";
      this.applicantName = "";
      this.cpr = "";
      this.admAppCode = "";
      this.$store.commit("Applicant_search/setApplicantsData", []);
    },

    toggleAdvancedSearch() {
      this.selectedApplicantStatus = {};
      this.selectedFaculty = {};
      this.selectedDepartment = {};
      this.loadingDepartments = false;
      this.selectedMajor = {};
      this.loadingMajor = false;
      this.selectedNational = {};
      this.selectedGender = {};
      this.selectedQualificationType = {};
      this.selectedQualificationName = {};
      this.loadingQualificationName = false;
      this.submitDateFrom = "";
      this.submitDateTo = "";
      this.cpr = "";
      this.admAppCode = "";
      this.searchForApplicant();
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },

    validateForm() {
      if (
        this.selectedAcadYear.edAcadYear == "" ||
        this.selectedAcadYear.edAcadYear == undefined
      )
        this.validationErrors["selectedAcadYear"] = true;
      else delete this.validationErrors["selectedAcadYear"];

      if (
        this.selectedSemester.edCodeSemesterID == "" ||
        this.selectedSemester.edCodeSemesterID == undefined
      )
        this.validationErrors["selectedSemester"] = true;
      else delete this.validationErrors["selectedSemester"];

      return !Object.keys(this.validationErrors).length;
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Applicant_search/fetchAcadmicYearData").then(() => {
      this.$store.dispatch("Applicant_search/fetchSemesterData").then(() => {
        this.$store
          .dispatch("Applicant_search/fetchSemesterFromToData", {
            edAcadYearID: this.selectedAcadYear.edAcadYear,
            edCodeSemesterID: this.selectedSemester.edCodeSemesterID,
          })
          .then((res) => {
            this.selectedSemesterFromDate = res.from_to.fromDate.substring(
              0,
              10
            );
            this.selectedSemesterToDate = res.from_to.toDate.substring(0, 10);
            this.searchForApplicant();
          });
      });
    });
    this.$store.dispatch("Applicant_search/fetchApplicantStatusData");
    this.$store.dispatch("Applicant_search/fetchApplicantTypeData");
    this.$store.dispatch("Applicant_search/fetchFacultyData");
    this.$store.dispatch("Applicant_search/fetchNationalitiesData");
    this.$store.dispatch("Applicant_search/fetchGenderData");
    this.$store.dispatch("Applicant_search/fetchQualificationTypeData");
  },
};
</script>

<style scoped>
>>> .p-calendar-w-btn .p-datepicker-trigger {
  background-color: #d89436 !important;
  border: none;
}
.mainDiv {
  background-image: url("../../assets/images/islamicTextureBg.png");
  background-size: cover;
}

.steptUpperTitle {
  font-size: 22px;
  font-weight: bold;
  font-family: "Cairo";
  color: var(--main-color2);
  margin-inline-end: 10px;
}

.newApplicantBtn {
  background-color: #0f5887;
}

.newApplicantBtn:hover {
  background-color: #0f5887 !important;
}

.btnClass {
  border: none;
  width: 200px;
}
.dashedLineDiv {
  border-bottom: 3px dotted var(--main-color2);
  height: 20px;
  flex-grow: 1;
  margin-inline: 5px;
}

.textFieldSearch {
  box-shadow: 0px 3px 10px #0000001a;
}
.advancedSearchBtn {
  background-color: #0f5887;
}
.searchBtn {
  background-color: var(--main-color2);
}
.searchBtn:hover {
  background-color: var(--main-color2) !important;
}
.clearAllBtn {
  background-color: #df2b2b;
}
.clearAllBtn:hover {
  background-color: #df2b2b !important;
}
</style>
