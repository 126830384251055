<template>
  <div class="navigatorDiv">
    <span class="welcomeSpan">{{ $t("Welcome_Dear_Student") }}</span>
    <span class="chooseServicekSpan">{{ $t("Please_Choose_Service") }}</span>

    <router-link to="/regestration">
      <Button :label="regestrationBtnLabel" class="btnClass" />
    </router-link>

    <router-link to="/login">
      <Button :label="loginBtnLabel" class="btnClass" />
    </router-link>

    <!-- <router-link to="/login_embassy">
      <Button
        :label="adminOfficerBtnLabel"
        class="btnClass"
        style="background-color: var(--main-color2); border: none"
      />
    </router-link> -->
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    regestrationBtnLabel() {
      return this.$t("Register_New_Application");
    },
    loginBtnLabel() {
      return this.$t("Log_In");
    },
    adminOfficerBtnLabel() {
      return this.$t("Admin_officer_login");
    },
  },
};
</script>

<style scoped>
.header {
  padding: 5px 20px;
}

.navigatorDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.welcomeSpan {
  font-weight: bold;
  font-size: 1.3rem;
  margin: 5px;
}

.chooseServicekSpan {
  font-size: 1.3rem;
  margin: 10px;
}

.btnClass {
  width: 250px;
  margin: 5px auto;
  height: 45px;
  font-weight: bold;
}

.infoLinkSpan {
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #028bff;
  text-transform: capitalize;
  margin: 5px 10px;
}

.footer {
  margin-bottom: 20px;
  text-align: center;
}
</style>
