import axios from "axios";
import { serverBasePath } from "./store/consts"

export default () => {
    return axios.create({
        baseURL: serverBasePath,
        headers: {
            "Accept-Language": localStorage.getItem('language'),
            "Authorization": localStorage.getItem('JwtToken'),
        }
    })
}