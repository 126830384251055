import api from '../api';
import { serverBasePath } from './Contacts.module'

export default {
    namespaced: true,
    state: {
        gendersData: [],
        basicInfo: {
            "fname": "",
            "mname": "",
            "lname": "",
            "family": "",
            "fulL_NAME": "",
            "fname_ar": "",
            "mname_ar": "",
            "lname_ar": "",
            "family_ar": "",
            "gS_CODE_GENDER_ID": "",
            "gS_GENDER": "",
            "birthdate": "",
            "photO_PATH": "",
            "gS_BIRTH_COUNTRY_NODE_ID": "",
            "gS_BIRTH_COUNTRY_NODE": "",
            "photo":'',

        }
    },
    getters: {
        getGendersData(state) {
            return state.gendersData;
        },
        getBasicInfoData(state) {
            return state.basicInfo;
        },
    },
    mutations: {
        setGendersData(state, data) {
            state.gendersData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.gendersData.push({
                    "code": data[x].gsCodeGenderId,
                    "name": data[x].genderDescr
                });
            }
        },
        setBasicInfoData(state, data) {
            state.basicInfo = {};
            state.basicInfo = {
                "fname": data.fnameen,
                "mname": data.mnameen,
                "lname": data.lnameen,
                "family": data.familyen,
                "fulL_NAME": data.fulL_NAME,
                "fname_ar": data.fnamear,
                "mname_ar": data.mnamear,
                "lname_ar": data.lnamear,
                "family_ar": data.familyar,
                "gS_CODE_GENDER_ID": data.gS_CODE_GENDER_ID,
                "gS_GENDER": data.gS_GENDER,
                "birthdate": data.birthdate,
                "photO_PATH": data.photO_PATH,
                "gS_BIRTH_COUNTRY_NODE_ID": data.gS_BIRTH_COUNTRY_NODE_ID,
                "gS_BIRTH_COUNTRY_NODE": data.gS_BIRTH_COUNTRY_NODE,
                "photo":data.photo,
            };
        },
    },
    actions: {
        fetchGendersData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v0/GsCodeGender`)
                    .then(res => {
                        commit("setGendersData", res.data.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchBasicInfoData({ commit, state }) {
            return new Promise((resolve, reject) => {
                - api().get(`api/v0/GetApplicantBasicInformation/?AdmAppRegHistoryId=${localStorage.getItem("admApplicantId")}`)
                .then(res => {
                        commit("setBasicInfoData", res.data);
                        resolve(state.basicInfo)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        Update_ApplicantBasicInformation({ }, basicInfo) {
            return new Promise((resolve, reject) => {
                api().post("api/v0/Update_ApplicantBasicInformation", {
                    "admAppRegHistoryId": localStorage.getItem("admApplicantId"),

                    "fnamE_EN": basicInfo.fnamE_EN,
                    "mnamE_EN": basicInfo.mnamE_EN,
                    "lnamE_EN": basicInfo.lnamE_EN,
                    "familY_EN": basicInfo.familY_EN,
                    "fnamE_AR": basicInfo.fnamE_AR,
                    "mnamE_AR": basicInfo.mnamE_AR,
                    "lnamE_AR": basicInfo.lnamE_AR,
                    "familY_AR": basicInfo.familY_AR,
                    "gS_CODE_GENDER_ID": basicInfo.gS_CODE_GENDER_ID,
                    "gS_BIRTH_COUNTRY_NODE_ID": basicInfo.gS_BIRTH_COUNTRY_NODE_ID,
                    "birthdate": basicInfo.birthdate,
                    "photO_PATH": basicInfo.photO_PATH
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        ApplicantPhotoUpload({ _ }, payload) {
            let formData = new FormData();
            formData.append('file', payload.file, payload.file.name);
            formData.append('AdmApplicantId', localStorage.getItem("admApplicantId"));

            return new Promise((resolve, reject) => {
                api().post(`api/v1/ApplicantBasicInformation/ApplicantPhotoUpload`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then((resp) => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    });
            });
        }
    }
}
