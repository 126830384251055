import api from "../api";

export default {
    namespaced: true,
    state: {
        grades: [], // Added state to hold grades data

        schoolsData: [],
        certificateTypesData: [],
        certificateNamesData: [],
        specializationsData: [],
        subjectsData: [],
        schoolsInfo: {
            qualDate: "",
            edCdeQualCatId: 0,
            edCodeQualID: 0,
            edCdeQualBrnId: 0,
            gsCountryNodeId: 0,
            gsCdeSchoolId: 0,
            qualTotalDegree: 0,
            applicantTotalDegree: 0,
            applicantPercent: 0,
            subjects: [],
        },
    },
    getters: {
        grades: (state) => state.grades, // Getter to access grades data

        getSchoolsData(state) {
            return state.schoolsData;
        },
        getCertificateTypesData(state) {
            return state.certificateTypesData;
        },
        getCertificateNamesData(state) {
            return state.certificateNamesData;
        },
        getSpecializationsData(state) {
            return state.specializationsData;
        },
        getSubjectsData(state) {
            return state.subjectsData;
        },
        getBasicInfoData(state) {
            return state.basicInfo;
        },
    },
    mutations: {
        setGradesData(state, data) {
            state.grades = data; // Mutation to set grades data
          },
        setSchoolsData(state, data) {
            state.schoolsData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.schoolsData.push({
                    code: data[x].gsCdeSchoolId,
                    name: data[x].schoolDescr,
                });
            }
            state.schoolsData.push({
                code: -1,
                name: "other",
            });
        },
        setCertificateTypesData(state, data) {
            state.certificateTypesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.certificateTypesData.push({
                    code: data[x].edCdeQualCatId,
                    name: data[x].descr,
                });
            }
        },
        setCertificateNamesData(state, data) {
            state.certificateNamesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.certificateNamesData.push({
                    code: data[x].edCodeQualId,
                    name: data[x].descr,
                    totalMark: data[x].totalMark,
                });
            }
        },
        setSpecializationsData(state, data) {
            state.specializationsData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.specializationsData.push({
                    code: data[x].edCdeQualBrnId,
                    name: data[x].brnDescr,
                    totalMark: data[x].totalMark,
                });
            }
        },
        setSubjectsData(state, data) {
            state.subjectsData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.subjectsData.push({
                    code: data[x].edCodeQualSubjectId,
                    name: data[x].subjectDescr,
                });
            }
        },
        setSchoolsInfoData(state, data) {
            state.schoolsInfo = {};
            state.schoolsInfo = {
                qualDate: data.qualDate.substr(0, 10),
                edCdeQualCatId: data.edCdeQualCatId,
                edCodeQualID: data.edCodeQualID,
                edCdeQualBrnId: data.edCdeQualBrnId,
                gsCountryNodeId: data.gsCountryNodeId,
                gsCdeSchoolId: data.gsCdeSchoolId,
                qualTotalDegree: data.qualTotalDegree,
                CountryTotalMark: data.countryTotalMark,
                applicantTotalDegree: data.applicantTotalDegree,
                applicantPercent: data.applicantPercent,
                schoolName: data.schoolName,
                subjects: data.subjects,
            };
        },
    },
    actions: {
        //, EdCodeQualId, EdCdeQualBrnId
        fetchSchoolsData({ commit }, param) {
            return new Promise((resolve, reject) => {
                api()
                    //.get(`api/v0/GsCdeSchool?GsCountryNodeId=${countryId}`)
                    .get(`api/v1/SchoolInformation/GetSchools?GsCountryNodeId=${param.countryId}&EdCodeQualId=${param.certificateName}&EdCdeQualBrnId=0`)
                    .then((res) => {
                        commit("setSchoolsData", res.data.schools);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchCertificateTypesData({ commit }) {
            return new Promise((resolve, reject) => {
                api()
                    .get(`api/v0/EdCdeQualCat`)
                    .then((res) => {
                        commit("setCertificateTypesData", res.data.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchCertificateNamesData({ commit }, certificateType) {
            return new Promise((resolve, reject) => {
                api()
                    .get(`api/v0/EdCodeQual?EdCdeQualCatId=${certificateType}`)
                    .then((res) => {
                        commit("setCertificateNamesData", res.data.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchSpecializationsData({ commit }, certificateName) {
            return new Promise((resolve, reject) => {
                api()
                    .get(`api/v0/EdCdeQualBrn?EdCodeQualId=${certificateName}`)
                    .then((res) => {
                        commit("setSpecializationsData", res.data.data);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchSubjectsData({ commit }, specializimName) {
            debugger
            return new Promise((resolve, reject) => {
                api()
                    .get(
                        `api/v1/SchoolInformation/GetSpecializationSubjects?EdCdeQualBrnId=${specializimName}`
                    )
                    .then((res) => {
                        commit("setSubjectsData", res.data.subjects);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchSchoolsInfoData({ commit, state }) {
            return new Promise((resolve, reject) => {
                api()
                    .get(`api/v1/SchoolInformation/GetApplicantSchoolInformation`)
                    .then((res) => {
                        commit("setSchoolsInfoData", res.data);
                        resolve(state.schoolsInfo);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchGradesData({ commit, state }) { // New action to fetch grades data
            return new Promise((resolve, reject) => {
              api()
                .get(`api/v1/SchoolInformation/GetAllGrades`)
                .then((res) => {
                  commit("setGradesData", res.data);
                  resolve(state.grades);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          },
      
        Add_Update_SchoolInformation({ }, schoolInfo) {
            return new Promise((resolve, reject) => {
                api()
                    .post("api/v1/SchoolInformation/Add_Update_SchoolInformation", {
                        qualDate: schoolInfo.Graduation_Date,
                        edCdeQualCatId: schoolInfo.selectedCertificateType,
                        edCodeQualID: schoolInfo.selectedCertificateName,
                        edCdeQualBrnId: schoolInfo.selectedSpecialization,
                        gsCountryNodeId: schoolInfo.selectedCountry,
                        gsCdeSchoolId: schoolInfo.selectedSchool,
                        isEgypt: schoolInfo.isEgypt,
                        qualTotalDegree: 0,
                        schoolName: schoolInfo.schoolName,
                        CountryTotalMark: schoolInfo.Total_Score,
                        admCdeExamGrdngId: schoolInfo.admCdeEXamGrdngId?.admCdeEXamGrdngId,
                        applicantTotalDegree: schoolInfo.applicantScore,
                        applicantPercent: schoolInfo.Percentage.replace("%", ""),
                        applicantQualSubjects: schoolInfo.subjectsDataWithMarks,
                        gradeWeight: schoolInfo.gradeWeight
                    })
                    .then((resp) => {
                        if (resp.status == "200") {
                            resolve({ status: true });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
