<template>
  <div class="w-screen h-screen m-auto mainDiv">
    <headerBar :mainColor="2"></headerBar>
    <div>
      <div class="m-3">
        <div class="stepTitleHeader">
          <div
            class="stepsIndicator"
            style="width: 230px; margin-inline-end: 10px; padding-inline: 15px 40px"
          ></div>
          <div
            style="
              margin-inline-start: 20px;
              width: 100%;
              display: flex;
              flex-direction: row;
              padding-inline-end: 40px;
            "
          >
            <div class="steptUpperTitle">{{ tabTitles[currentTabIndex].title }}</div>
            <div class="dashedLineDiv"></div>
          </div>
        </div>
        <div class="stepperWizard">
          <div class="stepsIndicator">
            <div v-for="(tab, index) in tabTitles" :key="index">
              <stepTemplete
                :tab="tab"
                :index="index"
                :currentTabIndex="currentTabIndex"
                :mainColor="2"
                @click="isSubmitted == 'true' ? onChangeCurrentTab(index) : donothing"
                style="cursor: pointer"
              ></stepTemplete>
            </div>
          </div>
          <div class="stepsBody">
            <Basic_Info_static
              v-if="getStepIdFromIndex(currentTabIndex) === 110"
              @nextPage="onChangeCurrentTab($event)"
            ></Basic_Info_static>
            <Basic_Information
              v-if="getStepIdFromIndex(currentTabIndex) === 1"
              @nextPage="onChangeCurrentTab($event)"
            ></Basic_Information>

            <Contact_Information
              v-if="getStepIdFromIndex(currentTabIndex) === 3"
              @nextPage="onChangeCurrentTab($event)"
            ></Contact_Information>

            <Guardian_Information
              v-if="getStepIdFromIndex(currentTabIndex) === 18"
              @nextPage="onChangeCurrentTab($event)"
            ></Guardian_Information>

            <Secondary_School_Qualifications
              @nextPage="onChangeCurrentTab($event)"
              v-if="getStepIdFromIndex(currentTabIndex) === 4"
            ></Secondary_School_Qualifications>

            <Fees_Payment
              v-if="getStepIdFromIndex(currentTabIndex) === 22"
              @nextPage="onChangeCurrentTab($event)"
            ></Fees_Payment>

            <Applicant_Preferences
              v-if="getStepIdFromIndex(currentTabIndex) === 15"
              @nextPage="onChangeCurrentTab($event)"
            ></Applicant_Preferences>

            <Upload_Documents
              v-if="getStepIdFromIndex(currentTabIndex) === 8"
              @nextPage="onChangeCurrentTab($event)"
            ></Upload_Documents>

            <Terms_Conditions
              v-if="getStepIdFromIndex(currentTabIndex) === 16"
              @nextPage="onChangeCurrentTab($event)"
            ></Terms_Conditions>
            <Finished
              v-if="getStepIdFromIndex(currentTabIndex) === 111"
              @nextPage="onChangeCurrentTab($event)"
            ></Finished>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerBar from "../../components/custom-components/applicant_application_master/header_bar.vue";
import stepTemplete from "../../components/custom-components/applicant_application_master/step_templete.vue";

import Basic_Info_static from "./application_pages/Basic_Info_static.vue";
import Basic_Information from "./application_pages/Basic_Information.vue";
import Contact_Information from "./application_pages/Contact_Information.vue";
import Guardian_Information from "./application_pages/Guardian_Information.vue";
import Secondary_School_Qualifications from "./application_pages/Secondary_School_Qualifications.vue";
import Fees_Payment from "./application_pages/Fees_Payment.vue";
import Applicant_Preferences from "./application_pages/Applicant_Preferences.vue";
import Upload_Documents from "./application_pages/Upload_Documents.vue";
import Terms_Conditions from "./application_pages/Terms_Conditions.vue";

import Finished from "./application_pages/Finished.vue";

export default {
  data() {
    return {
      firstView: true,
      currentTabIndex: 0,
      tabTitles: [
        {
          id: 0,
          orderCode: 1,
          title: "",
        },
      ],
    };
  },
  components: {
    headerBar,
    stepTemplete,
    Basic_Info_static,
    Basic_Information,
    Contact_Information,
    Guardian_Information,
    Secondary_School_Qualifications,
    Fees_Payment,
    Applicant_Preferences,
    Upload_Documents,
    Terms_Conditions,
    Finished,
  },
  computed: {
    isSubmitted() {
      return localStorage.getItem("isSubmitted");
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    navigateToX() {
      console.log("in the name of allah");
      // if (this.firstView == true) {
      this.currentTabIndex = 4;
      Number(
        this.tabTitles.filter((o) => o.id == Number(localStorage.getItem("stepId")))[0]
          .orderCode
      ) - 1;
      this.firstView = true;
      // }
    },
    donothing() {},
    getStepIdFromIndex(currentIndex) {
      return this.tabTitles[Number(currentIndex)].id;
    },
    onChangeCurrentTab(index) {
      console.log(index);
      this.firstView = false;
      this.currentTabIndex = index;
      localStorage.setItem("stepId", this.tabTitles[index].id);
      // this.currentTabIndex = index;
    localStorage.setItem("currentTabIndex", index);
    },
    onTabBeforeChange() {},
    wizardCompleted() {
      // console.log("Wizard Completed");
    },
  },
  created() {
    this.$store.commit(
      "Auth_embassy/setViewOnly",
      localStorage.getItem("ViewOnly") === "true"
    );
    this.$store.commit(
      "Auth_embassy/setSelectedAdmApplicantId",
      localStorage.getItem("AdmApplicantId")
    );
    this.$store.commit(
      "Auth_embassy/setSelectedAdmAppRegHistoryId",
      localStorage.getItem("AdmAppRegHistoryId")
    );

    this.$store.dispatch("Auth_embassy/fetchStepsData").then((resp) => {
      // this.$store.dispatch("Auth_embassy/fetchCurrentActiveStep");
      this.tabTitles = [];
      this.tabTitles.push({
        id: 110,
        orderCode: 0,
        title: this.$t("basicInfoStepName"),
      });

      for (var x = resp.length - 1; x >= 0; x--) {
        this.tabTitles.push({
          id: resp[x].admRegStepsId,
          orderCode: resp[x].stepNo,
          title: resp[x].stepTitle,
        });
      }
      this.tabTitles.push({
        id: 111,
        orderCode: Number(resp[0].stepNo) + 1,
        title: this.$t("Finished"),
      });



      const storedTabIndex = localStorage.getItem("currentTabIndex");
    if (storedTabIndex !== null) {
      this.onChangeCurrentTab(parseInt(storedTabIndex));
    } else {
      this.onChangeCurrentTab(
        Number(this.tabTitles.filter((o) => o.id == 1)[0].orderCode) - 2
      );
    }



      //Number(localStorage.getItem("stepId"))
      // this.onChangeCurrentTab(
      //   Number(this.tabTitles.filter((o) => o.id == 1)[0].orderCode) - 2

      //   // Number(this.tabTitles.filter((o) => o.id == Number(1))[0].orderCode) - 2
      // );
    });
  },
};
</script>
<style scoped>
.stepTitleHeader {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.steptUpperTitle {
  font-size: 22px;
  font-weight: bold;
  font-family: "Cairo";
  color: var(--main-color2);
  margin-inline-end: 10px;
}

.dashedLineDiv {
  border-bottom: 3px dotted var(--main-color2);
  height: 20px;
  flex-grow: 1;
}

.stepperWizard {
  display: flex;
  flex-direction: row;
  height: 75vh;
  width: 100%;
  padding-inline: 10px 40px;
}

.stepsIndicator {
  width: 230px;
  margin-inline-end: 10px;
  height: 100%;
}

.stepsBody {
  margin-inline-start: 10px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000012;
  height: 100%;
  border-radius: 10px;
}

.stepsIndicator {
  width: 200px;
}

:deep(.fw-footer) {
  display: none !important;
}

:deep(.p-inputtext-lg) {
  box-shadow: 0px 3px 10px #0000001a !important;
}
:deep(.p-dropdown-label) {
  color: var(--main-color) !important;
  font-weight: bold;
}

:deep(.p-dropdown-trigger-icon) {
  color: var(--main-color2) !important;
  font-weight: bold;
}

:deep(.fw-body-container) {
  height: 100%;
}

:deep(.p-dropdown) {
  border-color: transparent;
  box-shadow: 1px 1px 6px #d5cfcf;
}

:deep(.p-inputtext) {
  color: var(--main-color2) !important;
  font-weight: bold;
}

:deep(.fw-vertical) {
  min-height: 85vh !important;
}

:deep(.fw-body-list) {
  width: 260px !important;
}

.mainDiv {
  background-image: url("../../assets/images/islamicTextureBg.png");
  background-size: cover;
}

.systemName {
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
}

.infoLinkSpan {
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: var(--main-color2);
  text-transform: capitalize;
  margin: 5px 10px;
}

.footer {
  margin-bottom: 20px;
  text-align: center;
}

.tabPageName {
  margin-inline-start: 250px;
  color: var(--main-color2);
}
/*******start of  steps********/

:deep(.fw-body-content) {
  padding: 10px 30px !important;
  background-color: white !important;
  margin-inline: 30px !important;
  box-shadow: 1px 2px 10px #8080806b !important;
  width: auto !important;
  border-radius: 15px;
}
.form-wizard-vue {
  background-color: transparent !important;
}
.fw-card {
  box-shadow: none;
  border: none;
}
/*******end of  steps********/
</style>
