<template>
  <div class="mainForm">
    <div>
      <div class="flex m-3 flex-column">
        <div class="tableContainer">
          <table>
            <tr>
              <th>{{ $t("Fees_Type") }}</th>
              <th>{{ $t("Amount") }}</th>
              <th>{{ $t("Amount_Paid") }}</th>
              <th>{{ $t("Paid") }}</th>
            </tr>
            <tr v-for="(fee, index) in feesData" :key="index">
              <td>
                <div>{{ fee.feeDescr }}</div>
              </td>
              <td>
                <div>{{ fee.feeAmount }} {{ fee.currency }}</div>
              </td>
              <td>
                <div>{{ fee.feeRemainAmount }} {{ fee.currency }}</div>
              </td>
              <td>
                <div>
                  <i v-if="fee.isPaid == '1'" class="pi pi-check paidIcon"></i>
                  <i v-else class="pi pi-times paidIcon" style="color: red"></i>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="flex m-2 justify-content-between">
          <h6 style="color: var(--main-color2); font-family: 'Cairo'; font-weight: bold">
            {{ $t("Pay_Fees_messege") }}
          </h6>
          <div style="width: 150px">
            <Button
              :label="Pay_FeesBtnLabel"
              @click="sendVerification"
              class="btnClass w-full font-bold"
              style="border: none; background-color: var(--main-color2)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #0f5887; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <Button
        :label="nextBtnTxt"
        class="btnClass"
        style="background-color: #d89436; border: none"
        @click="nextPage"
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../store/mixin.js";

export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,
      validationErrors: {},
    };
  },
  computed: {
    feesData() {
      return this.$store.getters["Fees_embassy/getFeesData"];
    },
    Pay_FeesBtnLabel() {
      return this.$t("Pay_Fees");
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    nextPage() {
      this.$emit("nextPage", 8);
      // if (localStorage.getItem("isSubmitted") == "false") {
      //   this.$emit("nextPage", 8);
      // } else {
      //   this.$emit("nextPage", 8);
      // }
    },
    previousPage() {
      this.$emit("nextPage", 6);
    },
  },
  created() {
    this.$store.dispatch("Fees_embassy/fetchFeesData", this.$route.params.id);
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tableContainer {
  border: 2px solid var(--main-color2);
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
}

th {
  background-color: var(--main-color2);
  color: white;
  text-align: center;
  font-family: "Cairo";

  height: 35px;
}

td > div {
  text-align: center;
  padding: 5px;
}

tr:nth-child(odd) > td > div {
  background-color: #f1f1f1;
  color: black;
  margin: 5px;
}

.paidIcon {
  font-size: 20px;
  color: var(--main-color2);
  font-weight: bold;
}
</style>
