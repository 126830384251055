export default {
  "sectionNo_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------global--------------------------"])},
  "systemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة الوافدين"])},
  "Check_This_Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل هذا البيان"])},
  "nextBtnTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ والتالى"])},
  "previousBtnTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
  "nextBtnTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ والتالى"])},
  "selectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختار"])},
  "doneBtnTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنهاء الطلب"])},
  "fillTheMissedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكمل البيانات الناقصة"])},
  "dataSavedSuccessfuly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ البيانات بنجاح"])},
  "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد انك تريد حذف المستند"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
  "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخرى"])},
  "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق"])},
  "admin_officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موظف التسجيل"])},
  "sectionNo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------landingPage--------------------------"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "Privacy_Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "Terms_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
  "Welcome_Dear_Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا عزيزى الطالب"])},
  "Please_Choose_Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك إختار الخدمة"])},
  "Register_New_Application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم طلب جديد"])},
  "Log_In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة الى صفحة الدخول"])},
  "mail_sent_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال البريد الإلكترونى بنجاح"])},
  "successPasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إستعادة كلمة المرور بنجاح"])},
  "passwordLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال كلمة مرور جديدة الى البريد الالكترونى . إفحص بريدك الإلكترونى ثم سجل دخول"])},
  "passwordLinkSentReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال كلمة مرور الى البريد الالكترونى . إفحص بريدك الإلكترونى ثم سجل دخول"])},
  "sectionNo_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------loginPage--------------------------"])},
  "Please_Login_With_Passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك سجل دخول برقم جواز السفر وكلمة المرور"])},
  "Please_Login_With_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك سجل دخول بالبريد الإلكترونى وكلمة المرور"])},
  "Passport_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جواز السفر"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Insert_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أدخل رقم جواز السفر"])},
  "Insert_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة المرور"])},
  "Forgot_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور ؟"])},
  "sectionNo_2.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------reset password (insert email)--------------------------"])},
  "reset_password_with_passport_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم جواز السفر والبريد الإلكترونى لإستعادة كلمه المرور"])},
  "insertEmailGuideSpan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك أدخل البريد الإلكترونى لإستعادة كلمة المرور"])},
  "insertEmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل البريد الإلكترونى"])},
  "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكترونى"])},
  "Admin_officer_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin officer"])},
  "sectionNo_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------regestrationPage--------------------------"])},
  "basicInfoStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الأساسية"])},
  "pageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل طلب إلتحاق"])},
  "Applicant_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الطلب"])},
  "New_Applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقدم جديد"])},
  "Depletion_Failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستنفذ الرسوب"])},
  "Select_Input_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختر نوع الدرجه"])},
  "Select_Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخترالتقدير"])},
  "NBisstoped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمه متوقفه في الوقت الحالي"])},
  "Grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقدير الطالب"])},
  "Degree_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدرجة العلمية"])},
  "degreeTypeDDLPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختار نوع الدرجة العلمية"])},
  "Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة العلمية"])},
  "degreeDDLPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختار الدرجة العلمية"])},
  "Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
  "countryDDLPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختار الجنسية"])},
  "Passport_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ جواز السفر"])},
  "Passport_Exp_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ إنتهاء جواز السفر"])},
  "E-Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكترونى"])},
  "notValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكترونى غير صحيح"])},
  "regestrationGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك أدخل البريد الإلكترونى الشخصى لتتلقى كلمة المرور"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
  "notValidPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جواز السفر غير صحيح"])},
  "Passport_Date_not_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ إصدار حواز السفر غير صحيح"])},
  "sectionNo_3.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------Search For Application--------------------------"])},
  "SearchForApplicationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن متقدم"])},
  "createNewApplicationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل متقدم جديد"])},
  "clearSearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفريغ الخانات"])},
  "SearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "OpenAdvancedSearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث المتقدم"])},
  "CloseAdvancedSearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اغلاق البحث المتقدم"])},
  "AcadmicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العام الاكاديمي"])},
  "Semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفصل الدراسي"])},
  "ApplicantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المتقدم"])},
  "Faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلية"])},
  "Department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
  "Major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
  "SemesterFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الفصل الدراسي"])},
  "SemesterToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الفصل الدراسي"])},
  "ApplicantType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المتقدم"])},
  "SubmitDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من تاريخ"])},
  "SubmitDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى تاريخ"])},
  "ApplicantStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة المتقدم"])},
  "QualificationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المؤهل الدراسي"])},
  "QualificationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المؤهل الدراسي"])},
  "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
  "PassportNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جواز السفر"])},
  "ApplicantCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المتقدم"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "ConfirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد انك تريد حذف هذا المتقدم ؟"])},
  "sectionNo_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page1 > basic info--------------------------"])},
  "Applicant_Basic_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الأساسية للمتقدم"])},
  "fullNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب بالكامل  طبقا لشهادة الثانوية (انجليزية)"])},
  "firstNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأول"])},
  "secondNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثانى"])},
  "thirdNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثالث"])},
  "lastNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخير"])},
  "fullNameLabel_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الطالب بالكامل  طبقا لشهادة الثانوية (عربية)"])},
  "genderNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
  "Birth_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])},
  "Birth_Date_not_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السن يجب ان يكون اكبر من 15 عام"])},
  "Birth_Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محل الميلاد"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة"])},
  "uploadBtntooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الصورة"])},
  "deleteBtntooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الصورة"])},
  "sectionNo_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page2 > Contact Information--------------------------"])},
  "Contact_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الإتصال"])},
  "Mobile_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الموبايل"])},
  "Verification_Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقيق"])},
  "Current_Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان الحالى"])},
  "Egyptian_Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان المصرى"])},
  "Send_Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل كود التحقق"])},
  "Verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
  "Address_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العنوان"])},
  "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصر"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنثى"])},
  "Feeisstoped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء العلم ان خدمة الدفع عن طريق فوري ستكون متاحه غدا."])},
  "sectionNo_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page3 > Guardian Information --------------------------"])},
  "Guardian_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الأبوين"])},
  "Relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلة القرابة"])},
  "fullNameLabel_gardian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم بالكامل (انجليزية)"])},
  "fullNameLabel_ar_gardian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم بالكامل (عربية)"])},
  "sectionNo_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page4 > Secondary School Qualifications --------------------------"])},
  "Secondary_School_Qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مؤهل الدراسة الثانوية"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد الحصول علي المؤهل"])},
  "School_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسم المدرسة"])},
  "Certificate_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الشهادة"])},
  "Certificate_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسم الشهادة"])},
  "Specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخصص"])},
  "Graduation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التخرج"])},
  "Total_Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع الكلى"])},
  "Applicant_Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الطالب"])},
  "Applicant_ScorePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل المجموع"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة"])},
  "Certificate_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواد الشهادة"])},
  "subjectMarkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الطالب"])},
  "subjectMaxMarkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة القصوى"])},
  "subjectPersentagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
  "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرنسية"])},
  "applicantScoreIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع الطالب يجب ان يكون اقل من او يساوى المجموع الكلى"])},
  "graduation_Date_not_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التخرج يجب ان لا يكون قد مضى عليه اكثر من اربع سنوات"])},
  "sectionNo_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page5 > Fees Payment --------------------------"])},
  "Fees_Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع المصروفات"])},
  "Fees_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المصروف"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
  "Amount_Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المتبقي"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الدفع"])},
  "Pay_Fees_messege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عزيزى المتقدم , لن تتمكن من استكمال الطلب الا بعد سداد رسوم التقدم "])},
  "Pay_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سداد الرسوم"])},
  "egyptianPoundSympol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ج.م"])},
  "sectionNo_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page6 > Applicant Preferences --------------------------"])},
  "Applicant_Preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رغبات المتقدم"])},
  "Add_NewBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جديد"])},
  "College": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلية"])},
  "Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب"])},
  "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإعلى"])},
  "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لأسفل"])},
  "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلزامى"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيب"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "optinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إختيارى"])},
  "preferencesMinCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحد الأدنى لعدد الرغبات هو "])},
  "preferencesMaxCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["و الحد الأقصى لعدد الرغبات هو"])},
  "sectionNo_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page7 > Upload The Following Documents --------------------------"])},
  "Upload_Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرفاق المستندات"])},
  "Document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستند"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرفاق"])},
  "pleaseCompeleteData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ارفق كل المستندات الإلزامية"])},
  "documentUploadedSuccessfuly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرفاق المستند بنجاح"])},
  "sectionNo_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page8 > Terms & Conditions --------------------------"])},
  "Terms_N_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
  "Terms_N_Conditions_body1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "readConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرأت كل الشروط والأحكام وموافق عليها"])},
  "sectionNo_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page9 > Finished --------------------------"])},
  "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Application_Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإنتهاء من الطلب"])},
  "Dear_Applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عزيزى المتقدم ,"])},
  "finished_messege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إستلام طلبك بنجاح وسيتم مراجعة الطلب فى اقرب وقت. إيصال الدفع الخاص بالطلب  المقدم لم يتم قبوله ---- .أصول المستندات المقدمه يجب ان تقدم بصفة شخصية فى موعد سوف يتم تحديدة قريبا."])},
  "backToMainBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة للصفحة الرئيسية"])},
  "sectionNo_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------messeges--------------------------"])},
  "MSG_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم تسجيل هذا الرقم لجواز سفر سابق"])},
  "MSG_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تكرار البريد الإلكترونى"])},
  "MSG_03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يكون جواز السفر ساري لمدة 6 شهور علي الاقل"])},
  "MSG_04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحفظ وإرسال رمز التحقق لبريدك الإلكترونى ,برجاء معاودة الدخول برقم جواز السفر كإسم مستخدم ورمز التحقق المرسل ككلمة مرور لإستكمال طلب الإلتحاق"])},
  "MSG_05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد طلب إلتحاق فى الفصل الدراسى الحالى"])},
  "MSG_06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التحقق غير صحيح ولن يتم حفظ البريد الإلكترونى الجديد بدون رمز التحقق الصحيح"])},
  "MSG_07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم السداد بنجاح"])},
  "MSG_08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم رفض السداد"])},
  "MSG_09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ فى السداد"])},
  "MSG_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تكن تكرار الرغبة"])},
  "MSG_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحميل الملفات الضرورية أولا"])},
  "MSG_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسجيل طلبك بنجاح"])},
  "sectionNo_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------back end messeges--------------------------"])},
  "MSG-DEV2-000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك تحقق من تاريخ انتهاء جواز السفر"])},
  "MSG-DEV2-000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فصل تقديم مفتوح"])},
  "MSG-DEV2-000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد تكرار في رقم جواز السفر"])},
  "MSG-DEV2-000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد تكرار في البريد الألكتروني"])},
  "MSG-DEV2-000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فصل تقديم مفتوح"])},
  "MSG-DEV2-000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوجد تكرار في البريد الألكتروني"])},
  "MSG-DEV2-000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحفظ"])},
  "MSG-DEV2-000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فصل تقديم مفتوح"])},
  "MSG-DEV2-000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتقدم غير موجود"])},
  "MSG-DEV2-000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في كلمة السر"])},
  "MSG-DEV2-000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات للمؤهل"])},
  "MSG-DEV2-000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم غير موجود"])},
  "MSG-DEV1-000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد كليات متاحه"])},
  "MSG-DEV1-000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد هذا المتقدم"])},
  "MSG-DEV2-000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر تخصص للكليه "])},
  "MSG-DEV2-000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الباسبور الخاص بك غير موجود من فضلك ادخل رقم الباسبور الصحيح "])},
  "MSG-DEV2-000028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الايميل غير صحيح من فضلك اعد كتابه الايميل الصحيح"])},
  "MSG-DEV2-000029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال رقم سري جديد من فضلك تاكد من بريدك الالكتروني"])},
  "MSG-DEV2-000030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت معالجة الدفعة بنجاح. شكرًا لك على عملية الشراء"])},
  "MSG-DEV2-000031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تتم عمليه الدفع يُرجى المحاولة مرة أخرى في وقت لاحق أو التواصل مع دعم العملاء للمساعده"])},
  "MSG-DEV2-000032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نأسف لإبلاغك أن عملية الدفع الخاصة بك تم إلغاؤها. إذا كنت بحاجة إلى مساعدة إضافية، يرجى التواصل مع فريق الدعم الخاص بنا"])},
  "MSG-DEV2-000033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع الخاص بك معلق"])},
  "MSG-DEV2-000034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم الدفع بعد"])},
  "MSG-DEV2-000035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يجب الا يتعدي تارخ التخرج ٤ سنين من الان ولا يتعدي تاريخ اليوم"])},
  "MSG-DEV2-000036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب عليك اضافه جميع المواد "])},
  "MSG-DEV2-000037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تحتوي  درجه العظمي و درجه الطالب علي قيم "])},
  "MSG-DEV2-000038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب انا تكون درجه الطالب اقل من الدرجه العظمي "])},
  "MSG-DEV2-000039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في التسجيل"])}
}