<template>
  <div class="mainForm">
    <div>
      <div class="grid m-1">
        <div class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round col-2">
          <span>{{ $t("Country") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-4 align-items-start justify-content-start font-bold border-round col-4">
          <Dropdown v-model="selectedCountry" :options="countriesData" @change="ClearSchoolData()" optionLabel="name"
            filter :placeholder="selectPlaceholder" :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCountry && submitted },
            ]" />
        </div>
        <div class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3">
          <span>{{ $t("Graduation_Date") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round col-3">
          <Calendar v-model="Graduation_Date" @hide="checkForGraduationDate" showIcon :class="[
            'w-full m-1',
            { 'p-invalid': validationErrors.Graduation_Date && submitted },
          ]" />
        </div>
      </div>

      <div class="grid m-1">
        <div class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round col-2">
          <span>{{ $t("Certificate_Type") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-4">
          <Dropdown v-model="selectedCertificateType" :options="certificateTypesData" :disabled="!selectedCountry.code"
            optionLabel="name" :loading="certificateTypesData.length == 0 && selectedSchool != {}"
            @change="fetchCertificateNamesForCertificateType" :placeholder="selectPlaceholder" :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCertificateType && submitted },
            ]" />
        </div>
        <div v-if="inputType === 'applicantScore'" class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3">
          <span>{{ $t("Total_Score") }}</span><span style="color: red">*</span>
        </div>
        <div v-if="inputType === 'applicantScore'" class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round col-3">
          <!-- {{ selectedCertificateType.code }} -->
          <InputText placeholder="" v-model="Total_Score" :min="applicantScore" @keypress="onlyNumbersWithDot"
            :disabled="selectedCertificateType.code == 1 ||
              selectedCertificateType.code == 3 ||
              selectedCertificateType.code == 6
              " :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.applicantMaxScore && submitted },
            ]" />
        </div>
      </div>

      <div class="grid m-1">
        <div class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round col-2">
          <span>{{ $t("Certificate_Name") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-4">
          <Dropdown v-model="selectedCertificateName" :options="certificateNamesData" :disabled="!selectedCountry.code"
            optionLabel="name" :loading="certificateNamesData.length == 0 && selectedCertificateType != {}"
            @change="fetchSpecializationsDataForCertificateName" :placeholder="selectPlaceholder" :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCertificateName && submitted },
            ]" />
        </div>

        <div v-if="inputType === 'applicantScore'"
          class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3">
          <span>{{ $t("Applicant_Score") }}</span><span style="color: red">*</span>
        </div>
        <div v-if="inputType === 'applicantScore'"
          class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round col-3">
          <InputText :placeholder="insert_ScorePlaceholder" v-model="applicantScore" :max="Total_Score"
            @change="calcPercentage" @input="calcPercentage" @keypress="onlyNumbersWithDot" :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.applicantScore && submitted },
            ]" />
        </div>

        <!-- <div class="grid m-1"> -->
        <div class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2"
          v-if="inputType === 'grade'">
          <span>{{ $t("Select_Degree") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round col-3"
          v-if="inputType === 'grade'">
          <div class="col-12 md:col-12 align-items-center justify-content-center font-bold border-round col-12">
            <Dropdown @change="SelectPercentange" v-model="selectedGrade" :options="gradesData.grades" optionLabel="gradeName" value="admCdeEXamGrdngId" :placeholder="selectPlaceholder"
              :class="[
                'w-full m-1',
                { 'p-invalid': validationErrors.selectedGrade && submitted },
              ]" >
              <!-- Custom Option Template -->
        <template #option="slotProps">
          <div class="flex flex-column">
            <span class="font-bold">{{ slotProps.option.gradeName }}</span>
            <small >{{ slotProps.option.gradeWeight }}%</small>
          </div>
        </template>
        <!-- Custom Selected Item Template -->
        <template #item="slotProps">
          <div class="flex flex-column">
            <span class="font-bold">{{ slotProps.option.gradeName }}</span>
            <small class="font-bold">{{ slotProps.option.gradeWeight }}%</small>
          </div>
        </template>
            </Dropdown>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- Radio Button Selection -->
<!-- {{ selectedCertificateType }} -->
      <!-- Radio Button Selection -->
      <div class="grid m-1" v-if="selectedCertificateType.code===5">
        <div class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round col-2 m-3">
          <span>{{ $t("Select_Input_Type") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-4 m-3">
          <div class="field-radiobutton p-2">
            <RadioButton v-model="inputType" value="applicantScore" />
            <label class="mx-2">{{ $t("Applicant_Score") }}</label>
          </div>
          <div class="field-radiobutton  p-2">
            <RadioButton v-model="inputType" value="grade" @change="fetchGrades" />
            <label class="mx-2">{{ $t("Grade") }}</label>
          </div>
        </div>
      </div>

      <div class="grid m-1">
        <div class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round col-2">
          <span>{{ $t("School_Name") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-4">
          <Dropdown v-model="selectedSchool" :options="schoolsData" :disabled="!selectedCountry.code" optionLabel="name"
            filter :placeholder="selectPlaceholder" :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedSchool && submitted },
            ]">
            <template #value="slotProps">
              <div v-if="slotProps.value.code == -1">
                {{ $t("others") }}
              </div>
              <div v-else-if="slotProps.value.code != undefined">
                {{ slotProps.value.name }}
              </div>
              <div v-else>
                {{ slotProps.placeholder }}
              </div>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option.code == -1">{{ $t("others") }}</div>
              <div v-else>{{ slotProps.option.name }}</div>
            </template>
          </Dropdown>
        </div>
        <div class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
          v-if="selectedSchool.code == -1">
          <span>{{ $t("School_Name") }}</span><span style="color: red">*</span>
        </div>
        <div class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round col-3"
          v-if="selectedSchool.code == -1">
          <InputText placeholder="" v-model="schoolNameTxt" :class="[
            'w-full p-inputtext-lg',
            { 'p-invalid': validationErrors.schoolNameTxt && submitted },
          ]" />
        </div>
      </div>
      <div class="grid m-1">
        <div
          class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Specialization") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-4"
        >
          <Dropdown
            v-model="selectedSpecialization"
            :options="specializationsData"
           :disabled="!selectedCountry.code"
            optionLabel="name"
            :loading="specializationsData.length == 0 && selectedCertificateName != {}"
            :placeholder="selectPlaceholder"
            @change="fetchSubjectsDataForSpecializmName"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedSpecialization && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
        >
          <span>{{ $t("Percentage") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round col-3"
        >
     
          <InputText
            placeholder=""
            v-model="ShowPercentage"
            disabled
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.Percentage && submitted },
            ]"
          />
        </div>
      </div>


      <div class="grid m-1" style="background: rgba(2, 139, 255, 0.06) no-repeat padding-box">
        <div class="col-12 md:col-2 labelDiv align-items-start justify-content-start font-bold border-round col-2">
          <div v-if="subjectsData.length > 0 && subjectsDataWithMarks.length > 0" style="line-height: 50px">
            <span>{{ $t("Certificate_Subjects") }}</span><span style="color: red"></span>
          </div>
        </div>
        <div class="col-12 md:col-10 align-items-center justify-content-center font-bold border-round col-10">
          <div v-if="subjectsData.length > 0 && subjectsDataWithMarks.length > 0"
            class="grid flex-row w-full justify-content-between">
            <div class="col-12 md:col-6 grid" v-for="(subject, index) in subjectsData" :key="index">
              <div class="col-6 md:col-3 justify-content-center align-items-center"
                style="color: var(--main-color); font-size: 0.9rem">
                <span>{{ subject.name }}</span>
              </div>
              <div class="col-6 md:col-3" style="color: var(--main-color)">
                <InputText :placeholder="subjectMaxMarkPlaceholder" v-model="subjectsDataWithMarks[index].maxMark"
                  :min="subjectsDataWithMarks[index].mark" @keypress="onlyNumbersWithDot" style="width: 90px !important"
                  :class="['w-full p-inputtext-sm']" />
              </div>
              <div class="col-6 md:col-3">
                <InputText :placeholder="subjectMarkPlaceholder" v-model="subjectsDataWithMarks[index].mark"
                  style="width: 90px !important" :max="subjectsDataWithMarks[index].maxMark"
                  @change="calcPercentageForSubject(index)" @input="calcPercentageForSubject(index)"
                  @keypress="onlyNumbersWithDot" :class="[
                    'w-full p-inputtext-sm',
                    { 'p-invalid': validationErrors.englishMark && submitted },
                  ]" />
              </div>
              <div class="col-6 md:col-3">
            
                <InputText :placeholder="subjectPercentagePlaceholder" v-model="subjectsDataWithMarks[index].percentage"
                  disabled style="width: 90px !important" :class="[
                    'w-full p-inputtext-sm',
                    { 'p-invalid': validationErrors.englishMark && submitted },
                  ]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button :label="previousBtnTxt" class="btnClass" style="background-color: #df2b2b; border: none"
        @click="previousPage" icon="pi pi-angle-left" iconPos="left" />
      <div v-tooltip="`${nextBtnTooltip}`">
        <Button :label="nextBtnTxt" class="btnClass" @click="saveSchoolInfoPage" icon="pi pi-angle-right"
          iconPos="right" />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../store/mixin.js";

export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,
      selectedCountry: {},
      selectedSchool: {},
      schoolNameTxt: "",
      selectedCertificateType: {},
      selectedCertificateName: {},
      selectedGrade: {},
      selectedSpecialization: {},
      gradeType: null,
      inputType: 'applicantScore', // Added for input type selection
      subjectsDataWithMarks: [
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
      ],
      Graduation_Date: "",
      Total_Score: 0,
      applicantScore: 0,
      Percentage: "",
      ShowPercentage: "",
      validationErrors: {},
    };
  },
  computed: {
    nextBtnTooltip() {
      return this.$t("nextBtnTooltip");
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    insert_ScorePlaceholder() {
      return this.$t("Applicant_ScorePlaceholder");
    },
    subjectMarkPlaceholder() {
      return this.$t("subjectMarkPlaceholder");
    },
    subjectMaxMarkPlaceholder() {
      return this.$t("subjectMaxMarkPlaceholder");
    },
    subjectPercentagePlaceholder() {
      return this.$t("subjectPersentagePlaceholder");
    },
    countriesData() {
      return this.$store.getters["Auth/getCountries2Data"];
    },
    schoolsData() {
      return this.$store.getters["Schools/getSchoolsData"];
    },
    gradesData() {
      return this.$store.getters["Schools/grades"];
    },
    certificateTypesData() {
      return this.$store.getters["Schools/getCertificateTypesData"];
    },
    certificateNamesData() {
      return this.$store.getters["Schools/getCertificateNamesData"];
    },
    specializationsData() {
      return this.$store.getters["Schools/getSpecializationsData"];
    },
    subjectsData() {
      return this.$store.getters["Schools/getSubjectsData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
    applicationCountry() {
      return this.$store.getters["Fees/getApplicationCountry"];
    },
    isEgypt() {
      return this.applicationCountry === 'EG' ? 1 : 0;
    },
  },
  methods: {
    fetchGrades() {
      this.$store.dispatch(
        "Schools/fetchGradesData",

      )
    },
    checkForGraduationDate() {
      var Graduation_Date_DT = new Date(this.Graduation_Date);

      var month_diff = Graduation_Date_DT - new Date(new Date().getFullYear(), 0, 1);
      if (Math.abs(new Date(month_diff).getUTCFullYear() - 1970) > 4) {
        this.validationErrors["graduation_Date_not_correct"] = true;
        // this.$toast.add({
        //   severity: "error",
        //   detail: this.$t("graduation_Date_not_correct"),
        //   life: 3000,
        // });
        this.$swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("confirmButtonText"),
          text: this.$t("graduation_Date_not_correct"),
        });
      } else delete this.validationErrors["graduation_Date_not_correct"];
    },
    calcPercentage() {
      if (
        Number(this.Total_Score) != 0 &&
        this.applicantScore <= Number(this.Total_Score)
      ) {
        this.Percentage =
          ((Number(this.applicantScore) / Number(this.Total_Score)) * 100)
            .toFixed(1)
            .toString() + "%";
      } else {
        this.applicantScore = this.Total_Score;
        if (Number(this.Total_Score) != 0) this.Percentage = (100).toString() + "%";
      }
      this.ShowPercentage = this.Percentage
    },
    calcPercentageForSubject(index) {
      if (
        Number(this.subjectsDataWithMarks[index].maxMark) != 0 &&
        Number(this.subjectsDataWithMarks[index].mark) <=
        Number(this.subjectsDataWithMarks[index].maxMark)
      ) {
        this.subjectsDataWithMarks[index].percentage =
          (
            (Number(this.subjectsDataWithMarks[index].mark) /
              Number(this.subjectsDataWithMarks[index].maxMark)) *
            100
          )
            .toFixed(1)
            .toString() + "%";
      } else {
        this.subjectsDataWithMarks[index].mark = this.subjectsDataWithMarks[
          index
        ].maxMark;
        if (Number(this.subjectsDataWithMarks[index].maxMark) != 0)
          this.subjectsDataWithMarks[index].percentage = (100).toString() + "%";
      }
    },
    async fetchSchoolsForCountry() {
      await this.$store.dispatch("Schools/fetchSchoolsData", {
        countryId: this.selectedCountry.code,
        certificateName: this.selectedCertificateName.code,
      });
    },
    ClearSchoolData() {
      this.$store.commit("Schools/setSchoolsData", []); // Set schoolsData to null

      this.schoolsData = null;
      this.selectedCertificateName = {};
      this.selectedSpecialization = {};
      this.certificateTypesData = []
      this.selectedSchool = 0
      this.subjectsData = [];
    },
    async fetchCertificateNamesForCertificateType() {

      this.$store.commit("Schools/setSchoolsData", []); // Set schoolsData to null
      this.selectedSchool = 0
      this.selectedCertificateName = {};
      this.selectedSpecialization = {};
      this.subjectsData = [];
      this.subjectsDataWithMarks = [
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
      ];

      // this.Total_Score = 0;
      this.Percentage = 0;
      this.ShowPercentage = this.Percentage
      await this.$store.dispatch(
        "Schools/fetchCertificateNamesData",
        this.selectedCertificateType.code
      );
    },
    async fetchSpecializationsDataForCertificateName() {
      this.subjectsData = [];
      this.schoolsData = null;
      this.subjectsDataWithMarks = [
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
      ];

      if (this.Total_Score > 0) {
        this.Percentage =
          ((Number(this.applicantScore) / Number(this.Total_Score)) * 100)
            .toFixed(1)
            .toString() + "%";
        this.ShowPercentage = this.Percentage
      }

      this.fetchSchoolsForCountry();
      await this.$store.dispatch(
        "Schools/fetchSpecializationsData",
        this.selectedCertificateName.code
      );

    },
    SelectPercentange(){
      this.ShowPercentage= this.selectedGrade.gradeWeight +'%'
      this.Percentage=this.selectedGrade.gradeWeight +'%'
    },
       async fetchSubjectsDataForSpecializmName() {
      debugger;
      if (
        this.selectedCertificateType.code == 1 ||
        this.selectedCertificateType.code == 3 ||
        this.selectedCertificateType.code == 6
      ) {
        debugger
        this.Total_Score = this.selectedSpecialization.totalMark;
      }
      console.log(this.selectedSpecialization);
      debugger
      await this.$store
        .dispatch("Schools/fetchSubjectsData", this.selectedSpecialization.code)
        .then(() => {
          this.subjectsDataWithMarks = this.subjectsData.map((subj) => ({
            subject: subj.code,
            mark: "",
            maxMark: "",
            percentage: "",
          }));
        });
    },
    validateForm() {
      if (this.selectedCountry.code == "")
        this.validationErrors["selectedCountry"] = true;
      else delete this.validationErrors["selectedCountry"];

      if (this.selectedSchool.code == "") this.validationErrors["selectedSchool"] = true;
      else delete this.validationErrors["selectedSchool"];

      if (
        this.selectedSchool.code == -1 &&
        (this.schoolNameTxt == "" || this.schoolNameTxt == undefined)
      )
        this.validationErrors["schoolNameTxt"] = true;
      else delete this.validationErrors["schoolNameTxt"];

      if (this.selectedCertificateType.code == "")
        this.validationErrors["selectedCertificateType"] = true;
      else delete this.validationErrors["selectedCertificateType"];

      if (this.selectedCertificateName.code == "")
        this.validationErrors["selectedCertificateName"] = true;
      else delete this.validationErrors["selectedCertificateName"];

      if (
        this.specializationsData.length > 0 &&
        (this.selectedSpecialization.code == "" ||
          this.selectedSpecialization.code == undefined)
      )
        this.validationErrors["selectedSpecialization"] = true;
      else delete this.validationErrors["selectedSpecialization"];

      if (this.Graduation_Date == "" || this.Graduation_Date == undefined)
        this.validationErrors["Graduation_Date"] = true;
      else delete this.validationErrors["Graduation_Date"];
      if (
        (this.selectedCertificateType.code == 2 ||
          this.selectedCertificateType.code == 4 ||
          this.selectedCertificateType.code !== 5 ||
          this.selectedCertificateType.code == 7) &&
        (this.Total_Score == "" ||
          this.Total_Score == "0" ||
          this.Total_Score == undefined)
      )
        this.validationErrors["applicantMaxScore"] = true;
      else delete this.validationErrors["applicantMaxScore"];
if (this.selectedCertificateType.code == 5) {
  delete this.validationErrors["applicantMaxScore"]
}
      if ((this.applicantScore == "" || this.applicantScore == undefined ) && this.inputType === 'applicantScore')
        this.validationErrors["applicantScore"] = true;
      else delete this.validationErrors["applicantScore"];

      return !Object.keys(this.validationErrors).length;
    },
    saveSchoolInfoPage() {
  if (localStorage.getItem("isSubmitted") == "false") {
    debugger;
    this.submitted = true;
    if (this.validateForm()) {
      if (Number(this.Total_Score) >= Number(this.applicantScore)) {
        this.$store.commit("showLoaderFn", true);
        const data = {
          Graduation_Date: this.Graduation_Date,
          selectedCertificateType: this.selectedCertificateType.code,
          selectedCertificateName: this.selectedCertificateName.code,
          selectedSpecialization: this.selectedSpecialization.code,
          selectedCountry: this.selectedCountry.code,
          selectedSchool: this.selectedSchool.code == -1 ? 0 : this.selectedSchool.code,
          schoolName: this.schoolNameTxt,
          Total_Score: this.Total_Score,
          Percentage: this.Percentage,
          isEgypt: 1,
          // isEgypt: this.isEgypt,
          subjectsDataWithMarks: this.subjectsDataWithMarks.map((subj) => ({
            edCodeQualSubjectId: subj.subject,
            subjectMark: Number(subj.mark),
            MaxMark: Number(subj.maxMark),
          })),
        };

        // Add either applicantScore or admCdeEXamGrdngId based on inputType
        if (this.inputType === "applicantScore") {
          data.applicantScore = this.applicantScore;
          data.admCdeEXamGrdngId = null;

        } else if (this.inputType === "grade") {
          data.applicantScore = 0;
          data.gradeWeight=this.selectedGrade.gradeWeight 
          data.admCdeEXamGrdngId = this.selectedGrade;
          this.ShowPercentage= this.selectedGrade.gradeWeight 
     
        }

        this.$store
          .dispatch("Schools/Add_Update_SchoolInformation", data)
          .then((res) => {
            if (res.status) {
              this.$swal.fire({
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t("dataSavedSuccessfuly"),
              });
              this.$emit("nextPage", 4);
              this.$store.commit("showLoaderFn", false);
            }
          })
          .catch((error) => {
            debugger
            this.$store.commit("showLoaderFn", false);
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(error.response.data.Message),
            });
          });
      } else {
        this.$swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("confirmButtonText"),
          text: this.$t("applicantScoreIncorrect"),
        });
      }
    } else {
      this.$swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.$t("confirmButtonText"),
        text: this.$t("fillTheMissedData"),
      });
    }
  } else {
    this.$emit("nextPage", 4);
  }
},

    previousPage() {
      this.$emit("nextPage", 2);
    },
  },
  created() {
 
    this.$store.commit("Schools/setSubjectsData", []);
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Auth/fetchCountries2Data").then(() => {
      this.$store.dispatch("Schools/fetchCertificateTypesData").then(() => {
        this.$store
          .dispatch("Schools/fetchSchoolsInfoData")
          .then((result) => {
            this.Graduation_Date = result.qualDate;
            this.Total_Score = result.CountryTotalMark;
            this.applicantScore = result.applicantTotalDegree;
            this.Percentage = result.applicantPercent;

            (this.schoolNameTxt = result.schoolName),
              (this.selectedCountry = this.countriesData.filter(
                (c) => c.code == result.gsCountryNodeId
              )[0]);

            this.selectedCertificateType = this.certificateTypesData.filter(
              (c) => c.code == result.edCdeQualCatId
            )[0];
            this.fetchCertificateNamesForCertificateType().then(() => {
              this.selectedCertificateName = this.certificateNamesData.filter(
                (c) => c.code == result.edCodeQualID
              )[0];

              this.fetchSchoolsForCountry().then(() => {
                if (result.gsCdeSchoolId != null) {
                  this.selectedSchool = this.schoolsData.filter(
                    (c) => c.code == result.gsCdeSchoolId
                  )[0];
                } else {
                  this.selectedSchool = this.schoolsData.filter((c) => c.code == -1)[0];
                }
              });

              this.fetchSpecializationsDataForCertificateName().then(() => {
                debugger
                this.selectedSpecialization = this.specializationsData.filter(
                  (c) => c.code == result.edCdeQualBrnId
                )[0];

                this.fetchSubjectsDataForSpecializmName().then(() => {
                  this.subjectsDataWithMarks = result.subjects.map((subj) => ({
                    subject: subj.edCodeQualSubjectId,
                    mark: subj.appSubjectMark,
                    maxMark: subj.appMaxSubjectMark,
                    percentage:
                      (
                        (Number(subj.appSubjectMark) / Number(subj.appMaxSubjectMark)) *
                        100
                      )
                        .toFixed(1)
                        .toString() + "%",
                  }));

                  this.$store.commit("showLoaderFn", false);
                });
              });
            });
            this.ShowPercentage =this.Percentage

            // this.$store.commit("showLoaderFn", false);
          })
          .catch((error) => {
            this.$store.commit("showLoaderFn", false);
          });
      });
    });
    this.fetchGrades()
    this.$store.dispatch("Fees/fetchApplicationCountry");
    this.$store.commit("showLoaderFn", false);
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}

.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
