<template>
  <div class="grid h-screen m-auto mainDiv">
    <div class="col-12 md:col-6 lg:col-6 bg-white containerDiv">
      <div class="flex h-full flex-column justify-content-between">
        <!-- <div style="height: 80px"></div> -->

        <div class="header">
          <languageDDL :mainColor="2"></languageDDL>
          <Button
          v-if="routerName != 'landingpage'"
          :label="backBtnLabel"
          class="btnClass"
          @click="backFn"
        />
        </div>

        <div class="navigatorDiv">
          <router-view></router-view>
        </div>

        <div class="footer">
          <!-- <span class="infoLinkSpan">{{ $t("Privacy_Policy") }}</span> -->
          <span @click="showDialog" class="infoLinkSpan">{{ $t("Terms_Conditions") }}</span>
        </div>
      </div>
    </div>
    <div
      class="col-12 md:col-6 lg:col-6 h-28rem md:h-screen lg:h-screen flex flex-column justify-content-center logoContainer"
    >
      <div class="md:h-screen lg:h-screen" style="text-align: center">
        <div class="pageTitleDivClass">{{ $t("admin_officer") }}</div>
        <img src="../../assets/images/azharLogo_vertical.png" class="md:mt-8 logoImg" />
        <div class="systemName">منصة الأزهر للوافدين</div>
        <div class="systemName">International Students Gate</div>
      </div>
      <div></div>
    </div>
    
  </div>
</template>

<script>
import languageDDL from "../../components/shared-components/langueButton.vue";

export default {
  data() {
    return {
      termsAndConditions: `
      <p>-	اوافق والتزم بجميع شروط واجراءات وقواعد قبول الطلاب الوافدين بجامعة الازهر 
        -	التزم بكافة الشروط وقواعد القبول وقررات المجلس الاعلي للازهر
        -	التزم بجميع الشروط والضوابط المنظمة للالتحاق بالبرامج الدراسية في جميع كليات جامعة الازهر 
        -	اقر بان البيانات المدخلة صحيحة وان بيانات الدفع الالكتروني البنكية صحيحة 
        -	التزم بتحميل صور واضحة من الاوراق الاتية ( صورة شخصية – الشهادة الثانوية – تضاف شهادة القدرات بالنسبة للثانوية السعودية  - جواز سفر ساري العمل به مدة لا تقل عن ستة اشهر من تاريخ التقديم ) 
        علي ان تكون جميع المستندات  بنفس الاسم 
        -	التزم عند التسجيل اذا كانت الام مصرية اقوم بتوضيح ذلك ويجب تحميل ما يثبت ذلك 
        (شهادة ميلاد المتقدم – بطاقة الرقم القومي للام )
        -	اقر بتحمل المسؤلية الكاملة عن اختيار رغباتي 
        -	التزم بسداد المصروفات الدراسية طبقا لما تنص عليه قواعد ولوائح الطلاب الوافدين بجامعة الازهر 
        -	اقر والتزم بان ترشيحي للكلية يعد ترشيحا مبدئيا ولا يعد ترشيح نهائي الا بعد تقديم الاوراق مطابقة لما تم تقديمه علي الموقع الالكتروني معتمدة وموثقة من الجهات المختصة 
        -	اوافق علي الالتزام بموعيد تقديم اصول الاوراق كاملة واقر بصحة البيانات المقدمة وصحة الشهادة و جواز السفر  واتحمل المسؤلية الكاملة في حالة تقديم اوراق مخالفة لما تم تقديمها علي الموقع الالكتروني في موعد اقصاه 21 يوما بعد الترشيح المبدئي والا سيكون ترشيحي لاغيا ولا يحق لي المطالبة بتنفيذه 
        -	اوافق علي الغاء ترشيحي في حالة اعتراض الجهات المختصة علي ترشيحي في اي وقت يصبح ترشيحي لاغيا 
        -	اتعهد بعدم المطالبة باسترداد المقابل النقدي للخدمات المقدمة علي الموقع الالكتروني 
        -	اوافق علي ان يتم الغاء التسجيل اذا تقدمنت اكثر من مرة او تقدمت مستندات غير صحيحة او غير مكتملة 
        -	I agree and commit to all the conditions, procedures, and rules for accepting foreign students at Al-Azhar University.
        -	I commit to all the conditions, admission rules, and decisions of the Supreme Council of Al-Azhar.
        -	I commit to all the regulations and requirements for enrolling in study programs in all faculties of Al-Azhar University.
        -	I acknowledge that the entered data is correct and that the electronic bank payment information is accurate.
        -	I commit to uploading clear photos of the following documents (personal photo, high school certificate, the Qudurat certificate for Saudi high school, a valid passport for a period of not less than six months from the date of application), and all documents should be under the same name.
        -	Upon registration, I commit to clarifying if my mother is Egyptian and must provide proof (applicant's birth certificate, national ID card for the mother).
        -	I acknowledge full responsibility for my choice of preferences.
        -	I commit to paying the tuition fees in accordance with the rules and regulations for foreign students at Al-Azhar University.
        -	I acknowledge and commit that my nomination for the college is preliminary and is not final until the submitted documents are verified on the official website and authenticated by the competent authorities.
        -	I agree to comply with the deadline for submitting all the original documents and acknowledge the accuracy of the information provided, the validity of the certificate, and the passport. I also assume full responsibility in case of submitting documents that contradict what was submitted on the official website within a maximum of 21 days after the preliminary nomination, otherwise, my nomination will be canceled, and I will not be entitled to demand its implementation.
        -	I agree to cancel my nomination if the competent authorities object to my nomination at any time, my nomination will be canceled.
        -	I undertake not to claim a refund for the cash payment for the services provided on the official website.
        -	I agree that registration will be canceled if I apply more than once or if I submit incorrect or incomplete documents.
        -	
        </p>
      `,
    };
  },
  components: { languageDDL },
  mounted() {},
  computed: {
    routerName() {
      return this.$route.name;
    },
    backBtnLabel() {
      return this.$t("back");
    },
  },
  methods: { 
    showDialog() {
      this.$swal.fire({
        title: 'Terms and Conditions',
        html: this.termsAndConditions,
        confirmButtonText: 'Close',
        showCancelButton: false,
        showCloseButton: true,
      });
    },
 
       backFn() {
      this.$router.push("/");
    },},
};
</script>

<style scoped>
.pageTitleDivClass {
  background-color: #d89436;
  padding: 5px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
}
.btnClass {
  margin: 0px 20px;
  font-weight: bold;
  background-color: #d89436
}

:deep(.p-placeholder) {
  text-align: start !important;
}

:deep(.p-dropdown-label) {
  text-align: start !important;
}

:deep(.p-dropdown-label) {
  color: var(--main-color2) !important;
  font-weight: bold;
}

:deep(.p-dropdown-trigger-icon) {
  color: var(--main-color2) !important;
  font-weight: bold;
}

:deep(.p-dropdown) {
  border-color: transparent;
  box-shadow: 1px 1px 6px #d5cfcf;
}

:deep(.p-inputtext) {
  color: var(--main-color2) !important;
  font-weight: bold;
  border-radius: 10px;
}

.mainDiv {
  background-image: url("../../assets/images/islamicTextureBg.png");
  background-size: cover;
}

.containerDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000012;
  border-radius: 0px 20px 20px 0px;
}

.logoImg {
  width: 25vh;
  margin: 10px auto;
}

.systemName {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Cairo";
  text-align: center;
}

.header {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
}

.infoLinkSpan {
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: var(--main-color2);
  text-transform: capitalize;
  margin: 5px 10px;
}

.footer {
  margin-bottom: 20px;
  text-align: center;
}
</style>
