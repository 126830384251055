import api from '../api';

export default {
    namespaced: true,
    state: {
        ifLogin: (localStorage.getItem("JwtToken") != null),
        status: '',
        errorCode: "",
        degreeTypesData: [],
        degreesData: [],
        countriesData: [],
        countries2Data: [],
        AdmApplicantId:0
    },
    getters: {
        IF_LOGIN: state => !!state.ifLogin,
        AUTH_STATUS: state => state.status,
        getDegreeTypesData(state) {
            return state.degreeTypesData;
        },
        getDegreesData(state) {
            return state.degreesData;
        },
        getCountriesData(state) {
            return state.countriesData.sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        getCountries2Data(state) {
            return state.countries2Data.sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        getAdmApplicantId(state){
            return state.AdmApplicantId;
        },
    },
    mutations: {
        AUTH_REQUEST(state) {
            state.status = 'loading'
        },
        AUTH_SUCCESS(state) {
            state.status = 'success'
            state.ifLogin = true
        },
        AUTH_ERROR(state, error) {
            state.status = 'error'
            state.errorCode = "error";

        },
        AUTH_LOGOUT(state) {
          return new Promise((resolve, reject) => {
            // api().get('api/v0/GsCountryNode?nodeLevel=2&fields=gsCountryNodeId,nationDescr&onepage=true')
            api().post('api/v1/UserManagement/Logout/')
                .then(res => {
                  localStorage.removeItem("JwtToken");
            localStorage.removeItem("stepId");
            localStorage.clear()
                  state.ifLogin = '' // clear your user's token from localStorage
                })
                .catch(err => {
                    reject(err)
                })
        })



            state.ifLogin = '' // clear your user's token from localStorage
        },
        setDegreeTypesData(state, data) {
            state.degreeTypesData = data

        },
        setSelectedAdmApplicantId(state, data){
state.AdmApplicantId=data
        },
        setDegreesData(state, data) {
            state.degreesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.degreesData.push({
                    "code": data[x].asCodeDegreeId,
                    "name": data[x].degreeDescr
                });
            }
        },
        setCountriesData(state, data) {
            state.countriesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.countriesData.push({
                    "code": data[x].gsCountryNodeId,
                    "name": data[x].countryName
                });
            }
        },
        setCountries2Data(state, data) {
            state.countries2Data = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.countries2Data.push({
                    "code": data[x].gsCountryNodeId,
                    "name": data[x].nodeDescr
                });
            }
        },
    },
    actions: {
        USER_LOGIN({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/UserManagement/login", {
                    "ApplicantPassportNo": userInfo.Passport_No,
                    "Password": userInfo.password
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            this.commit(
                                "Auth/setSelectedAdmApplicantId",
                                resp.data.admApplicantId
                            );
                            localStorage.setItem("admApplicantId", resp.data.admApplicantId)
                            debugger
                        //    state.AdmApplicantId=resp.data.admApplicantId
                            localStorage.setItem("JwtToken", "Bearer " + resp.data.token.token);
                            localStorage.setItem('isSubmitted', resp.data.isSubmitted)
                            localStorage.setItem("stepId", resp.data.stepId);
                            commit("AUTH_SUCCESS");
                            resolve({ status: true, isSubmitted: resp.data.isSubmitted, stepId: resp.data.stepId })
                        } else {
                            resolve({ status: false, message: resp.data.Message })
                        }
                    })
                    .catch(err => {
                        commit("AUTH_ERROR", err.code)
                        localStorage.removeItem('JwtToken')
                        reject(err)
                    })
            })
        },
        RESET_PASSWORD({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                api().post('api/v1/ForgetPassword', {
                        ApplicantEmail: userInfo.email,
                        PassporNo: userInfo.Passport_No
                    })
                    .then(resp => {
                        if (resp.status == 200) { // Use number 200 instead of string
                            resolve({ status: true, message: resp.data.message })
                        } else {
                            resolve({ status: false, message: resp.data.message })
                        }
                    })
                    .catch(err => {
                        commit("AUTH_ERROR", err.code)
                        localStorage.removeItem('JwtToken')
                        reject(err)
                    })
            })
        },
        
        USER_LOGOUT({ commit }) {
            return new Promise((resolve) => {
                commit("AUTH_LOGOUT")
                resolve()
            })
        },
        ApplicantRegistration({ }, ApplicantRegistrationData) {
            return new Promise((resolve, reject) => {

                api().post("api/v1/ApplicantRegistration/ApplicantRegistration", {
                    "asCodeDegreeClassId": ApplicantRegistrationData.asCodeDegreeClassId,
                    "asCodeDegreeId": ApplicantRegistrationData.asCodeDegreeId,
                    "gsCountryNodeId": ApplicantRegistrationData.gsCountryNodeId,
                    "applicantPassportNo": ApplicantRegistrationData.applicantPassportNo,
                    "passportDate": ApplicantRegistrationData.passportDate,
                    "passportExpireDate": ApplicantRegistrationData.passportExpireDate,
                    "applicantEmail": ApplicantRegistrationData.applicantEmail,
                    "AdmCdeAppTypeId": ApplicantRegistrationData.Applicant_Type,
                    "AsFacultyInfoId": null,
                    "EntMainId": null,
                    // "isEgypt": ApplicantRegistrationData.isEgypt,
                    "isEgypt":1,
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            this.commit(
                                "setSelectedAdmApplicantId",
                                resp.data.admApplicantId
                            );
                            resolve({ status: true, admApplicantId: resp.data.admApplicantId })
                            
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchStepsData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get('api/v1/AdmissionTabs/GetApplicantAdmissionTabs?AsCodeDegreeClassId=1')
                    .then(res => {
                        resolve(res.data.tabs.sort(function (a, b) { return b.stepNo - a.stepNo }))
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchDegreeTypesData({ commit }) {
            return new Promise((resolve, reject) => {
                // api().get('api/v0/AsCodeDegreeClass?fields=asCodeDegreeClassId,classDescr')
                api().get('api/v1/ApplicantRegistration/GetAllDegrees')
                    .then(res => {
                        commit("setDegreeTypesData", res.data.degrees);

                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchDegreesData({ commit }, degreeType) {
            return new Promise((resolve, reject) => {
                api().get(`api/v0/AsCodeDegree?asCodeDegreeClassId=${degreeType.code}&fields=asCodeDegreeId,degreeDescr&orderby=asCodeDegreeId asc`)
                    .then(res => {
                        commit("setDegreesData", res.data.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchCountriesData({ commit }) {
            return new Promise((resolve, reject) => {
                // api().get('api/v0/GsCountryNode?nodeLevel=2&fields=gsCountryNodeId,nationDescr&onepage=true')
                api().get('api/v1/ApplicantRegistration/GetAllNationalities')
                    .then(res => {
                        commit("setCountriesData", res.data.nationalities);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchCountries2Data({ commit }) {
            return new Promise((resolve, reject) => {
                api().get('api/v0/GsCountryNode?GsCodeCNodeNatureId=1&&OnePage=true')
                    .then(res => {
                        commit("setCountries2Data", res.data.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}
