<template>
  <div class="w-full md:w-12 py-5 px-1 md:px-4 bg-white topHeader">
    <div @click="openSearchPage" style="cursor: pointer">
      <img src="../../../assets/images/azharLogo_horizontal.png" class="headerLogoImg" />
    </div>
    <div
      v-if="mainColor == 2"
      style="color: var(--main-color2); font-weight: bold"
      class="sm:hidden"
    >
      {{ $t("admin_officer") }}
    </div>
    <div
      style="color: var(--main-color1); font-weight: bold"
      class="hidden md:inline"
      v-else
    >
      {{ $t("systemName") }}
    </div>
    <div class="flex align-items-center">
      <languageDDL :mainColor="mainColor"></languageDDL>
      <i
        @click="logout"
        :style="{ color: mainColor == 1 ? '#028BFF' : '#D89436' }"
        class="pi pi-sign-in logOutBtn mx-2"
      />
    </div>
  </div>
</template>
<script>
import languageDDL from "../../shared-components/langueButton.vue";
export default {
  components: { languageDDL },
  props: ["mainColor"],
  methods: {
    logout() {
      if (this.mainColor == 1) {
        this.$store.dispatch("Auth/USER_LOGOUT").then(() => {
          localStorage.clear()

          this.$router.push("login");
        });
      } else if (this.mainColor == 2) {
        this.$store.dispatch("Auth_embassy/USER_LOGOUT").then(() => {
          localStorage.clear()

          this.$router.push("/login_embassy");
        });
      }
    },
    openSearchPage() {
      if (this.mainColor == 2) {
        this.$router.push("/officer_search");
      }
      // else {
      //   this.$router.push("/");
      // }
    },
  },
};
</script>
<style scoped>
.topHeader {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000012;
  border-radius: 0px 20px 20px 0px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerLogoImg {
  height: 70px;
  width: 70px;
}

.logOutBtn {
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
}

[dir="rtl"] .logOutBtn {
  transform: rotate(180deg);
}
</style>
