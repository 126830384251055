<template>
  <div class="mainForm">
    <div>
      <div class="flex m-3 flex-column">
        <div class="tableContainer">
          <table class="preferencesTab">
            <tr>
              <th style="width: 40%">{{ $t("College") }}</th>
              <th style="width: 35%">{{ $t("Program") }}</th>
              <th style="width: 15%">{{ $t("order") }}</th>
              <th style="width: 10%">{{ $t("delete") }}</th>
            </tr>
            <tr
              v-for="(preference, index) in orderedItems(preferencesData)"
              :key="preference.order"
            >
              <td>
                <div>
                  <Dropdown
                    v-model="orderedItems(preferencesData)[index].College"
                    :options="collagesData"
                    optionLabel="name"
                    filter
                    :placeholder="selectPlaceholder"
                    :class="[
                      'w-full m-1',
                      { 'p-invalid': validationErrors.selectedCountry && submitted },
                    ]"
                    @change="onCollegeChange(index,$event)"
                  />
                </div>
              </td>
              <td>

                <div>
                  <Dropdown
                  v-model="orderedItems(preferencesData)[index].Program"
                  :options="orderedItems(preferencesData)[index].Programs"
                  optionLabel="majorDescr"
                  filter
                  :placeholder="orderedItems(preferencesData)[index].Program.majorDescr ? orderedItems(preferencesData)[index].Program.majorDescr : selectPlaceholder"
                 
                  :class="[
                    'w-full m-1',
                    { 'p-invalid': validationErrors.selectedCountry && submitted },
                  ]"
                >
                  <template #option="slotProps">
                    <div
                      v-if="
                        this.preferencesData.filter(
                          (pref) => pref.Program.majorEntMainId == slotProps.option.majorEntMainId
                        ).length == 0
                      "
                      class="flex align-items-center"
                    >
                      <div>
                        {{ slotProps.option.majorDescr }}
                      </div>
                    </div>
                    <span v-else class="disabledOption"></span>
                  </template>
                </Dropdown>
                
                </div>
              </td>
              <td>
                <div class="flex justify-content-evenly">
                  <div
                    class="flex flex-column"
                    v-if="preference.order != preferencesData.length"
                    @click="moveDown(index)"
                  >
                    <i
                      class="pi pi-angle-down up_down_btn_icon"
                      style="color: #0057a0"
                    ></i
                    ><span class="up_down_btn_label" style="color: #0057a0">{{
                      $t("down")
                    }}</span>
                  </div>
                  <div
                    class="flex flex-column"
                    v-if="preference.order != 1"
                    @click="moveUp(index)"
                  >
                    <i class="pi pi-angle-up up_down_btn_icon"></i
                    ><span class="up_down_btn_label">{{ $t("up") }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div style="cursor: pointer" @click="removePreference(preference.order)">
                  <i class="pi pi-trash deleteIcon"></i>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="flex m-2 justify-content-end">
          <div style="width: 150px">
            <div @click="addNew" class="addNewPreferenceBtn">
              <i class="pi pi-plus"></i>
              {{ Add_NewBtnLabel }}
            </div>
            <!-- <Button
              :label="Add_NewBtnLabel"
              @click="addNew"
              class="btnClass w-full font-bold"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #0f5887; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <Button
        :label="nextBtnTxt"
        class="btnClass"
        style="background-color: #d89436; border: none"
        @click="nextPage"
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,
      minNumber: 0,
      maxNumber: 0,
      preferencesData: [
        {
          College: { code: 0, name: "", programs: [] },
          Program: { majorEntMainId: 0, majorDescr: "" },
          order: 1,
        },
      ],
      validationErrors: {},
    };
  },
  computed: {
    selectPlaceholder() {
      
      return this.$t("selectPlaceholder");
    },

    Add_NewBtnLabel() {
      return this.$t("Add_NewBtnLabel");
    },
    collagesData() {
      return this.$store.getters["Preferences_embassy/getCollagesData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    onCollegeChange(index,event) {
      this.$store.commit("showLoaderFn", true);
      this.$store
        .dispatch("Preferences_embassy/fetchProgramsData", event.value.code)
        .then((res) => {
          this.$store.commit("showLoaderFn", false);
          // event.value.programs = res;
          // console.log()
          this.preferencesData[index].Programs = res
          // 995888
        })
        .catch((error) => {
          this.$store.commit("showLoaderFn", false);
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(error.response.data.Message),
          });
        });
    },
    hideSelected() {
      var divs = document.querySelectorAll(".disabledOption");
      for (var i = 0; i < divs.length; ++i) {
        divs[i].parentElement.style.padding = "0px";
        divs[i].parentElement.style.height = "0px";
      }
    },
    orderedItems(items) {
      return items.sort((a, b) => Number(a.order) - Number(b.order));
    },
    moveUp(index) {
      this.preferencesData[index - 1].order =
        Number(this.preferencesData[index - 1].order) + 1;
      this.preferencesData[index].order = Number(this.preferencesData[index].order) - 1;
    },
    moveDown(index) {
      this.preferencesData[index + 1].order =
        Number(this.preferencesData[index + 1].order) - 1;
      this.preferencesData[index].order = Number(this.preferencesData[index].order) + 1;
    },
    addNew() {
      this.preferencesData.push({
        College: { code: 0, name: "", Programs: [] },
        Program: { majorEntMainId: 0, majorDescr: "" },
        order: Number(this.preferencesData.length) + 1,
      });
    },
    removePreference(order) {
      this.preferencesData.splice(
        this.preferencesData.findIndex((a) => a.order === order),
        1
      );

      this.preferencesData = this.preferencesData.reduce((accumulator, element) => {
        return [
          ...accumulator,
          element.order > order
            ? {
                College: element.College,
                Program: element.Program,
                order: element.order - 1,
              }
            : element,
        ];
      }, []);
    },
    nextPage() {
      // this.$emit("nextPage", 6);

      // if (localStorage.getItem("isSubmitted") == "false") {
      if (!this.$store.getters["Auth_embassy/getViewOnly"]) {
        if (
          this.preferencesData.length >= Number(this.minNumber) &&
          this.preferencesData.length <= Number(this.maxNumber)
        ) {
          this.$store.commit("showLoaderFn", true);
          this.$store
            .dispatch(
              "Preferences_embassy/SaveApplicantPrefrences",
              this.preferencesData.map((pref) => ({
                asFacultyInfoId: pref.College.code,
                programEntMainId: pref.Program.majorEntMainId,
                prefOrder: pref.order,
              }))
            )
            .then((res) => {
              if (res.status) {
                // this.$toast.add({
                //   severity: "success",
                //   detail: this.$t("dataSavedSuccessfuly"),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t("dataSavedSuccessfuly"),
                });
                this.$emit("nextPage", 6);
                this.$store.commit("showLoaderFn", false);
              }
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          // this.$toast.add({
          //   severity: "error",
          //   detail:
          //     this.$t("preferencesMinCount") +
          //     " " +
          //     this.minNumber +
          //     " " +
          //     this.$t("preferencesMaxCount") +
          //     " " +
          //     this.maxNumber,
          //   life: 3000,
          // });
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text:
              this.$t("preferencesMinCount") +
              " " +
              this.minNumber +
              " " +
              this.$t("preferencesMaxCount") +
              " " +
              this.maxNumber,
          });
        }
      } else {
        this.$emit("nextPage", 6);
      }
      // } else {
      //   this.$emit("nextPage", 6);
      // }
    },
    previousPage() {
      this.$emit("nextPage", 4);
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
   
    this.$store
      .dispatch("Preferences_embassy/fetchCollagesData")
      .then(() => {
        if (
          this.$store.getters["Auth_embassy/getSelectedAdmApplicantId"] != 0 &&
          this.$store.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"] != 0
        ) {
          this.$store
            .dispatch("Preferences_embassy/fetchApplicantPrefrencesData")
            .then((result) => {
              if (result.length > 0) {
                this.preferencesData = result.map((pref) => ({
                  College: this.collagesData.filter(
                    (coll) => coll.code === pref.asFacultyInfoId
                  )[0],
                 
                  Program: { majorEntMainId: pref.entMainId, majorDescr: pref.programDescr },
                  order: pref.prefOrder,
                  
                }
                ));
             
              
              }
              this.$store.commit("showLoaderFn", false);
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          this.$store.commit("showLoaderFn", false);
        }
      })
      .catch((error) => {
        this.$store.commit("showLoaderFn", false);
        // this.$toast.add({
        //   severity: "error",
        //   detail: this.$t(error.response.data.Message),
        //   life: 3000,
        // });
        this.$swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("confirmButtonText"),
          text: this.$t(error.response.data.Message),
        });
      });
    this.$store
      .dispatch("Preferences_embassy/GetMinMaxNumberOfPrefrences")
      .then((result) => {
        this.minNumber = result.minNumberOfPref;
        this.maxNumber = 999999999999999;
      });
  },
};
</script>

<style scoped>
.addNewPreferenceBtn {
  text-decoration: underline;
  color: #0f5887;
  font-family: "cairo";
  font-weight: bold;
  text-align: end;
  cursor: pointer;
}

:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tableContainer {
  border: 2px solid var(--main-color2);
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
}

th {
  background-color: var(--main-color2);
  color: white;
  text-align: center;
  font-family: "Cairo";

  height: 35px;
}

td > div {
  text-align: center;
  padding: 5px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

tr:nth-child(odd) > td > div {
  background-color: #f1f1f1;
  color: black;
  margin: 5px;
}

.up_down_btn_label {
  color: var(--main-color2);
  font-size: 10px;
  margin-top: -7px;
  cursor: pointer;
}

.up_down_btn_icon {
  color: var(--main-color2);
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.deleteIcon {
  font-size: 20px;
  color: red;
  font-weight: bold;
}
.preferencesTab {
  width: 100%;
  margin: 0px;
}
@media screen and (max-width: 600px) {
  .preferencesTab tr {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 3px;
    margin-bottom: 3px;
    margin-inline: 5px;
    border: 1px solid var(--main-color);
  }

  .headerTR {
    border: none !important;
    margin-inline: 0px !important;
  }
}
</style>
