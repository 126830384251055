export default {
    namespaced: true,
    state: {
        selectedLanguage: 'ar'
    },
    getters: {
        getSelectedLang: (state) => {
            return state.selectedLanguage
        }
    },
    mutations: {
        changeLanguage: (state, val) => {
            state.selectedLanguage = val
            localStorage.setItem('language', val)
        }
    },
    actions: {}
}