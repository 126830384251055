import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import master1 from '../views/login_regestration_master.vue'
import landing_page from '../views/landing_page.vue'
import loginPage from '../views/loginPage.vue'
import resetpassword from '../views/reset_password.vue'
import resetpasswordform from '../views/reset_password_form.vue'

import regestration_page from '../views/regestration_page.vue'

import master2 from '../views/applicant_application_master.vue'
import basic_info from '../views/application_pages/Basic_Information.vue'


// embassy
import embassy_officer_master from '../views/embassy_officer/login_regestration_master.vue'
import loginPage_embassy from '../views/embassy_officer/loginPage.vue'
import resetpassword_embassy from '../views/embassy_officer/reset_password.vue'
import reset_password_insert_email from '../views/embassy_officer/reset_password_insert_email.vue'

import officer_search from '../views/embassy_officer/officer_search.vue'
import application_embassy from '../views/embassy_officer/applicant_application_master.vue'

import NotFound from '../views/NotFound.vue' //  404 component

const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem("JwtToken") != null) {
        next()
        return
    }
    next('/')
}

const routes = [
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: '404 - Not Found'
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: "/404",
    },
    {
        path: "/",
        name: "master1",
        component: master1,
        children: [{
            path: '/',
            name: 'landingpage',
            component: landing_page,
            meta: {
                title: "منصة الوافدين"
            }
        }, {
            path: '/login',
            name: 'loginPage',
            component: loginPage,
            meta: {
                title: "تسجيل الدخول"
            }
        },
        {
            path: '/resetpasswordform',
            name: 'resetpasswordform',
            component: resetpasswordform,
            meta: {
                title: "إستعادة كلمة المرور"
            }
        },
        {
            path: '/resetpassword',
            name: 'resetpassword',
            component: resetpassword,
            meta: {
                title: "إستعادة كلمة المرور"
            }
        }, {
            path: '/regestration',
            name: 'regestration',
            component: regestration_page,
            meta: {
                title: "التسجيل"
            }
        },]
    },
    {
        path: "/application",
        name: "master2",
        beforeEnter: ifAuthenticated,
        component: master2,
        children: [{
            path: '/',
            name: 'Basic Info',
            component: basic_info,
            meta: {
                title: "البيانات الأساسية"
            },
        },
        {
            path: '/application/:id',
            name: 'Basic Info',
            component: basic_info,
            meta: {
                title: "البيانات الأساسية"
            },
        },
        ]
    },
    // embassy
    {
        path: "/embassy_officer",
        name: "embassy_officer_master",
        redirect: "/login_embassy",
        component: embassy_officer_master,
        children: [{
            path: '/login_embassy',
            name: 'loginPage_embassy',
            component: loginPage_embassy,
            meta: {
                title: "تسجيل الدخول"
            }
        },
            //  {
            //     path: '/reset_password_insert_email',
            //     name: 'reset_password_insert_email',
            //     component: reset_password_insert_email,
            //     meta: {
            //         title: "تسجيل البريد اللإلكترونى"
            //     }
            // }, {
            //     path: '/resetpassword_embassy',
            //     name: 'resetpassword_embassy',
            //     component: resetpassword_embassy,
            //     meta: {
            //         title: "إستعادة كلمة المرور"
            //     }
            // }
        ]
    },
    {
        path: "/officer_search",
        name: "officer_search",
        beforeEnter: ifAuthenticated,
        component: officer_search,
    },
    {
        path: "/application_embassy",
        name: "application_embassy",
        beforeEnter: ifAuthenticated,
        component: application_embassy,
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : "منصة الوافدين";
    next()
})

export default router