<template>
  <div class="mainForm">
    <div class="p-3">
      <div class="grid m-1">
        <div
          class="col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("Mobile_Number") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-3 flex align-items-center justify-content-center font-bold border-round"
          style="direction: ltr"
        >
          <vue-tel-input
            :value="mobileNumber"
           
            @input="chnageMobileNumber"
            @keypress="chnageMobileNumber"
       
            @open="resetMobileNumber"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.mobileNumber && submitted },
            ]"
            :autoFormat="false"
          ></vue-tel-input>
        </div>
      </div>
      <div class="grid m-1">
        <div
          class="flex col-12 md:col-3 labelDiv align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("E-Mail") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round p-2 col-3"
        >
          <InputText
            placeholder="main@gmail.com"
            v-model="emilField"
            disabled
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.emilField && submitted },
            ]"
          />
        </div>
        <div
          class="flex align-items-center justify-content-center font-bold border-round"
          style="width: 20%; display: none !important"
        >
          <Button
            :label="Send_VerificationLabel"
            @click="sendVerification"
            class="btnClass w-full font-bold"
          />
        </div>
      </div>
      <div class="grid m-1" style="display: none !important">
        <div
          class="col-12 md:col-3 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Verification_Code") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round p-2 col-3"
        >
          <InputText
            placeholder="XXX"
            v-model="Verification_Code"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.Verification_Code && submitted },
            ]"
          />
        </div>
        <div
          class="flex align-items-center justify-content-center font-bold border-round"
          style="width: 20%"
        >
          <Button
            :label="verifyLabel"
            @click="verifyFn"
            class="btnClass w-full font-bold"
          />
        </div>
      </div>
      <div class="grid m-1">
        <div
          class="col-12 md:col-3 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Current_Address") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round p-2 col-3"
        >
          <Dropdown
            v-model="selectedCountry"
            :options="countriesData"
            optionLabel="name"
            filter
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCountry && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-5"
        >
          <InputText
            :placeholder="Address_DetailsPlaceholder"
            v-model="Current_Address"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.Current_Address && submitted },
            ]"
          />
        </div>
      </div>
      <div class="grid m-1">
        <div
          class="labelDiv col-12 md:col-3 flex align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Egyptian_Address") }}</span>
          <!-- <span style="color: red">*</span> -->
        </div>
        <div
          class="col-12 md:col-3 align-items-center justify-content-center font-bold border-round p-2 col-3"
        >
          <InputText
            v-model="egypt"
            disabled
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.emilField && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round col-5"
        >
          <InputText
            :placeholder="Address_DetailsPlaceholder"
            v-model="Egyptian_Address"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.Egyptian_Address && submitted },
            ]"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="flex m-3 justify-content-between">
        <Button
          :label="previousBtnTxt"
          class="btnClass"
          style="background-color: #df2b2b; border: none"
          @click="previousPage"
          icon="pi pi-angle-left"
          iconPos="left"
        />
        <div v-tooltip="`${nextBtnTooltip}`">
          <Button
            :label="nextBtnTxt"
            class="btnClass"
            @click="saveContactsInfoPage"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../store/mixin.js";
export default {
  mixins: [mixin],
  data() {
    return {
      submitted: false,
      selectedPrefix: {},
      mobileNumber: "",
      emilField: "",
      Verification_Code: "",
      selectedCountry: {},
      Current_Address: "",
      Egyptian_Address: "",
      validationErrors: {},
    };
  },
  computed: {
    Send_VerificationLabel() {
      return this.$t("Send_Verification");
    },
    Address_DetailsPlaceholder() {
      return this.$t("Address_Details");
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    verifyLabel() {
      return this.$t("verify");
    },
    egypt() {
      return this.$t("egypt");
    },
    phonePrefixes() {
      return [
        { code: "1", name: "01" },
        { code: "2", name: "02" },
        { code: "2", name: "03" },
        { code: "4", name: "04" },
      ];
      // return this.$store.getters["Auth/getPhonePrefixes"];
    },
    countriesData() {
      return this.$store.getters["Auth/getCountriesData"];
    },
    // contactInfoData() {
    //   return this.$store.getters["Contacts/getContactInfoData"];
    // },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    nextBtnTooltip() {
      return this.$t("nextBtnTooltip");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    resetMobileNumber(){
   this.mobileNumber=""
 
    },
    chnageMobileNumber(phone, phoneObject, input) {
      console.log(phoneObject);
      if (phoneObject?.formatted) {
        this.countryCode = phoneObject.countryCallingCode;
        this.mobileNumber = phoneObject.number;
      console.log(phoneObject);
       
      }
    },
    validateForm() {
      if (
        this.mobileNumber == "" ||
        this.mobileNumber == undefined ||
        !this.validateMobileNumber(this.mobileNumber)
      )
        this.validationErrors["mobileNumber"] = true;
      else delete this.validationErrors["mobileNumber"];

      if (this.emilField == "" || this.emilField == undefined)
        this.validationErrors["emilField"] = true;
      else delete this.validationErrors["emilField"];

      if (!this.selectedCountry.code) this.validationErrors["selectedCountry"] = true;
      else delete this.validationErrors["selectedCountry"];

      if (this.Current_Address == "" || this.Current_Address == undefined)
        this.validationErrors["Current_Address"] = true;
      else delete this.validationErrors["Current_Address"];

      // if (this.Egyptian_Address == "" || this.Egyptian_Address == undefined)
      //   this.validationErrors["Egyptian_Address"] = true;
      // else delete this.validationErrors["Egyptian_Address"];

      return !Object.keys(this.validationErrors).length;
    },
    sendVerification() {},
    verifyFn() {},
    saveContactsInfoPage() {
      if (localStorage.getItem("isSubmitted") == "false") {
        this.submitted = true;
        if (this.validateForm()) {
          debugger
        console.log(this.mobileNumber);
        console.log(this.selectedCountry.code);
          this.$store.commit("showLoaderFn", true);
          this.$store
            .dispatch("Contacts/Add_Update_ApplicantContacts", {
            
              // mobileNum:`${this.countryCode}-${this.mobileNumber}`,
              mobileNum:this.mobileNumber,

              email: this.emilField,
              current_GsCountryNodeId: this.selectedCountry.code,
              currentAddress: this.Current_Address,
              egyptianAddress: this.Egyptian_Address,
            })
            .then((res) => {
              if (res.status) {
                debugger;
                console.log(res)
                // this.$toast.add({
                //   severity: "success",
                //   detail: this.$t("dataSavedSuccessfuly"),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t("dataSavedSuccessfuly"),
                });
                this.$emit("nextPage", 2);
                this.$store.commit("showLoaderFn", false);
              }
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("fillTheMissedData"),
          });
        }
      } else {
        this.$emit("nextPage", 2);
      }
    },
    previousPage() {
      this.$emit("nextPage", 0);
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Auth/fetchCountriesData");
    this.$store.dispatch("Contacts/fetchContactInfoData").then((result) => {
      const [countryCode, phoneNumber] = result.mobileNum.split("-");
      this.countryCode = countryCode;
    
      this.mobileNumber = result.mobileNum;
      console.log(result.mobileNum);
      this.selectedCountry = {
        code: result.current_GsCountryNodeId,
        name: result.current_GsCountryNodeDesc,
      };
      this.emilField = result.email;
      this.Current_Address = result.currentAddress;
      this.Egyptian_Address = result.egyptianAddress;
      this.$store.commit("showLoaderFn", false);
    });
  },

};
</script>

<style scoped>
:deep(.p-inputtext) {
  color: var(--main-color);
}

:deep(.p-button-icon) {
  font-size: 20px;
}

.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
