import api from '../../api_embassy';

export default {
    namespaced: true,
    state: {
        ifLogin: (localStorage.getItem("JwtToken") != null),
        status: '',
        errorCode: "",
        degreeTypesData: [],
        degreesData: [],
        countriesData: [],
        countries2Data: [],
        selectedAdmApplicantId: 0,
        selectedAdmAppRegHistoryId: 0,
        viewOnly: false,
    },
    getters: {
        IF_LOGIN: state => !!state.ifLogin,
        AUTH_STATUS: state => state.status,

        getSelectedAdmApplicantId: state => state.selectedAdmApplicantId,
        getSelectedAdmAppRegHistoryId: state => state.selectedAdmAppRegHistoryId,
        getViewOnly: state => state.viewOnly,

        getDegreeTypesData(state) {
            return state.degreeTypesData;
        },
        getDegreesData(state) {
            return state.degreesData;
        },
        getCountriesData(state) {
            return state.countriesData.sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        getCountries2Data(state) {
            return state.countries2Data.sort((a, b) => (a.name > b.name ? 1 : -1));
        },
    },
    mutations: {
        AUTH_REQUEST(state) {
            state.status = 'loading'
        },
        setSelectedAdmApplicantId(state, data) {
            state.selectedAdmApplicantId = data;
        },
        setSelectedAdmAppRegHistoryId(state, data) {
            state.selectedAdmAppRegHistoryId = data;
        },
        setViewOnly(state, data) {
            state.viewOnly = data;
        },
        AUTH_SUCCESS(state) {
            state.status = 'success'
            state.ifLogin = true
        },
        AUTH_ERROR(state, error) {
            state.status = 'error'
            state.errorCode = "error";

        },
        AUTH_LOGOUT(state) {
          return new Promise((resolve, reject) => {
            // api().get('api/v0/GsCountryNode?nodeLevel=2&fields=gsCountryNodeId,nationDescr&onepage=true')
            api().get('api/v1/UserManagement/Logout/')
                .then(res => {
                  console.log("Loged Out")
                  localStorage.removeItem("JwtToken");
                  localStorage.removeItem("stepId");
                  localStorage.clear()
                  state.ifLogin = '' // clear your user's token from localStorage
                })
                .catch(err => {
                    reject(err)
                })
        })

        },
        setDegreeTypesData(state, data) {
            state.degreeTypesData = [];
            // for (var x = data.length - 1; x >= 0; x--) {
            state.degreeTypesData.push({
                // "code": data[x].asCodeDegreeClassId,
                // "name": data[x].classDescr
                "code": data.asCodeDegreeClassId,
                "name": data.classDescr
            });
            // }
        },
        setDegreesData(state, data) {
            state.degreesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.degreesData.push({
                    "code": data[x].asCodeDegreeId,
                    "name": data[x].degreeDescr
                });
            }
        },
        setCountriesData(state, data) {
            state.countriesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.countriesData.push({
                    "code": data[x].gsCountryNodeId,
                    "name": data[x].countryName
                });
            }
        },
        setCountries2Data(state, data) {
            state.countries2Data = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.countries2Data.push({
                    "code": data[x].gsCountryNodeId,
                    "name": data[x].nodeDescr
                });
            }
        },
    },
    actions: {
        USER_LOGIN({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/DefaultLogin", {
                    "LoginUsername": userInfo,

                })
                    .then(resp => {
                        if (resp.status == "200") {
                            localStorage.setItem("JwtToken", "Bearer " + resp.data.token.token);
                            // localStorage.setItem('isSubmitted', resp.data.isSubmitted)
                            // localStorage.setItem("stepId", resp.data.stepId);
                            commit("AUTH_SUCCESS");
                            resolve({ status: true, isSubmitted: resp.data.isSubmitted, stepId: resp.data.stepId })
                        } else {
                            resolve({ status: false, message: resp.data.Message })
                        }
                    })
                    .catch(err => {
                        commit("AUTH_ERROR", err.code)
                        localStorage.removeItem('JwtToken')
                        reject(err)
                    })
            })
        },
        USER_LOGOUT({ commit }) {
            return new Promise((resolve) => {
                commit("AUTH_LOGOUT")
                resolve()
            })
        },
        ApplicantRegistration({ commit }, ApplicantRegistrationData) {
            return new Promise((resolve, reject) => {

                api().post("api/v1/ApplicantRegistration/Admin_ApplicantRegistration", {
                    "asCodeDegreeClassId": ApplicantRegistrationData.asCodeDegreeClassId,
                    "asCodeDegreeId": ApplicantRegistrationData.asCodeDegreeId,
                    "gsCountryNodeId": ApplicantRegistrationData.gsCountryNodeId,
                    "applicantPassportNo": ApplicantRegistrationData.applicantPassportNo,
                    "passportDate": ApplicantRegistrationData.passportDate,
                    "passportExpireDate": ApplicantRegistrationData.passportExpireDate,
                    "applicantEmail": ApplicantRegistrationData.applicantEmail,
                    "AdmCdeAppTypeId": ApplicantRegistrationData.Applicant_Type,
                    "AsFacultyInfoId": null,
                    "EntMainId": null
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            this.commit(
                                "Auth_embassy/setSelectedAdmApplicantId",
                                resp.data.admApplicantId
                            );
                            this.commit(
                                "Auth_embassy/setSelectedAdmAppRegHistoryId",
                                resp.data.admAppRegHistoryId
                            );
                            resolve({ status: true, admApplicantId: resp.data.admApplicantId, admAppRegHistoryId: resp.data.admAppRegHistoryId })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchStepsData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get('api/v1/AdmissionTabs/GetApplicantAdmissionTabs?AsCodeDegreeClassId=1')
                    .then(res => {
                        resolve(res.data.tabs.map((s) => ({
                            admRegStepsId: s.admRegStepsId,
                            stepTitle: s.stepTitle,
                            stepNo: Number(s.stepNo) + 1,
                        })).sort(function (a, b) { return b.stepNo - a.stepNo }))
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchDegreeTypesData({ commit }) {
            return new Promise((resolve, reject) => {
                // api().get('api/v0/AsCodeDegreeClass?fields=asCodeDegreeClassId,classDescr')
                api().get('api/v0/AsCodeDegreeClass/1?AsCodeDegreeClassId=1')
                    .then(res => {
                        commit("setDegreeTypesData", res.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchDegreesData({ commit }, degreeType) {
            return new Promise((resolve, reject) => {
                api().get(`api/v0/AsCodeDegree?asCodeDegreeClassId=${degreeType.code}&fields=asCodeDegreeId,degreeDescr&orderby=asCodeDegreeId asc`)
                    .then(res => {
                        commit("setDegreesData", res.data.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchCountriesData({ commit }) {
            return new Promise((resolve, reject) => {
                // api().get('api/v0/GsCountryNode?nodeLevel=2&fields=gsCountryNodeId,nationDescr&onepage=true')
                api().get('api/v1/ApplicantRegistration/GetAllNationalities')
                    .then(res => {
                        commit("setCountriesData", res.data.nationalities);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchCountries2Data({ commit }) {
            return new Promise((resolve, reject) => {
                api().get('api/v0/GsCountryNode?GsCodeCNodeNatureId=1&&OnePage=true')
                    .then(res => {
                        commit("setCountries2Data", res.data.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchCurrentActiveStep({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetApplicantStepNo?AdmApplicantId=${this.getters["Auth_embassy/getSelectedAdmApplicantId"]}&AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]}`)
                    .then(res => {
                        if (res.data.stepId != null) {
                            localStorage.getItem("stepId", res.data.stepId);
                        } else {
                            localStorage.getItem("stepId", 0);
                        }
                        localStorage.getItem("isSubmitted", res.data.isSubmitted);
                        resolve()
                    })
                    .catch(err => {
                        localStorage.getItem("stepId", 0);
                        reject(err)
                    })
            })
        },
    }
}
