<template>
  <div class="mainForm">
    <div>
      <div class="grid m-3">
        <div
          class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("fullNameLabel_gardian") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="firstNamePlaceholder"
            v-model="firstName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.firstName && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="secondNamePlaceholder"
            v-model="secondName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.secondName && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="thirdNamePlaceholder"
            v-model="thirdName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.thirdName && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="lastNamePlaceholder"
            v-model="lastName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.lastName && submitted },
            ]"
          />
        </div>
      </div>
      <div class="grid m-3">
        <div
          class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("fullNameLabel_ar_gardian") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="firstNamePlaceholder"
            v-model="firstName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.firstName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="secondNamePlaceholder"
            v-model="secondName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.secondName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="thirdNamePlaceholder"
            v-model="thirdName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.thirdName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="col-12 md:col-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="lastNamePlaceholder"
            v-model="lastName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.lastName_ar && submitted },
            ]"
          />
        </div>
      </div>
      <div class="grid m-3">
        <div
          class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("E-Mail") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-4 align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="insertPassportPlaceholder"
            v-model="emailField"
            @keydown="validateEmail"
            @change="validateEmail"
            @input="validateEmail"
            :class="[
              'w-full p-input-lg',
              {
                'p-invalid':
                  (validationErrors.not_valid_email || validationErrors.emailField) &&
                  submitted,
              },
            ]"
          />
        </div>
        <div
          v-if="validationErrors.not_valid_email"
          class="validationErro w-full"
          style="padding: 0px !important"
        >
          {{ $t("notValidEmail") }}
        </div>
      </div>
      <div class="grid m-3">
        <div
          class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("Relation") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-4 flex align-items-center justify-content-center font-bold border-round"
        >
          <Dropdown
            v-model="selectedRelation"
            :options="relationsData"
            optionLabel="name"
            filter
            :placeholder="selectPlaceholder"
            :class="[
              'w-full',
              { 'p-invalid': validationErrors.selectedRelation && submitted },
            ]"
          />
        </div>
      </div>
      <div class="grid m-3">
        <div
          class="col-12 md:col-3 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("Mobile_Number") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="col-12 md:col-4 flex align-items-center justify-content-center font-bold border-round"
          style="direction: ltr"
        >
          <vue-tel-input
            :value="mobileNumber"
         
            @input="chnageMobileNumber"
            @keypress="onlyNumbersWithPlus"
            @open="resetMobileNumber"
          
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.mobileNumber && submitted },
            ]"
            :autoFormat="false"
          ></vue-tel-input>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #df2b2b; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <div v-tooltip="`${nextBtnTooltip}`">
        <Button
          :label="nextBtnTxt"
          class="btnClass"
          @click="saveGardianInfoPage"
          icon="pi pi-angle-right"
          iconPos="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../store/mixin.js";

export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,

      firstName: "",
      secondName: "",
      thirdName: "",
      lastName: "",

      firstName_ar: "",
      secondName_ar: "",
      thirdName_ar: "",
      lastName_ar: "",

      selectedRelation: {},

      selectedPrefix: {},
      mobileNumber: "",
      emailField: "",

      validationErrors: {},
    };
  },
  computed: {
    nextBtnTooltip() {
      return this.$t("nextBtnTooltip");
    },
    firstNamePlaceholder() {
      return this.$t("firstNamePlaceholder");
    },
    secondNamePlaceholder() {
      return this.$t("secondNamePlaceholder");
    },
    thirdNamePlaceholder() {
      return this.$t("thirdNamePlaceholder");
    },
    lastNamePlaceholder() {
      return this.$t("lastNamePlaceholder");
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    phonePrefixes() {
      return [
        { code: "1", name: "01" },
        { code: "2", name: "02" },
        { code: "2", name: "03" },
        { code: "4", name: "04" },
      ];
      // return this.$store.getters["Auth/getPhonePrefixes"];
    },
    relationsData() {
      return this.$store.getters["Gardian/getRelationsData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    resetMobileNumber(){
   this.mobileNumber=""
 console.log(4848);
    },
    chnageMobileNumber(phone, phoneObject, input) {
     
      console.log(phoneObject);
      if (phoneObject?.formatted) {
        this.countryCode = phoneObject.countryCallingCode;
        this.mobileNumber = phoneObject.number;
      console.log(phoneObject);
       
      }
    },
    validateEmail() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
        this.validationErrors["not_valid_email"] = true;
      } else delete this.validationErrors["not_valid_email"];
    },
    validateForm() {
      if (this.emailField == "" || this.emailField == undefined)
        this.validationErrors["emailField"] = true;
      else delete this.validationErrors["emailField"];

      const namePattern = /^[a-zA-Z\u0600-\u06FF\s]+$/;

if (!namePattern.test(this.firstName)) {
    this.validationErrors["firstName"] = true;
} else {
    delete this.validationErrors["firstName"];
}

if (!namePattern.test(this.secondName)) {
    this.validationErrors["secondName"] = true;
} else {
    delete this.validationErrors["secondName"];
}

if (!namePattern.test(this.thirdName)) {
    this.validationErrors["thirdName"] = true;
} else {
    delete this.validationErrors["thirdName"];
}

if (!namePattern.test(this.lastName)) {
    this.validationErrors["lastName"] = true;
} else {
    delete this.validationErrors["lastName"];
}

if (!namePattern.test(this.firstName_ar)) {
    this.validationErrors["firstName_ar"] = true;
} else {
    delete this.validationErrors["firstName_ar"];
}

if (!namePattern.test(this.secondName_ar)) {
    this.validationErrors["secondName_ar"] = true;
} else {
    delete this.validationErrors["secondName_ar"];
}

if (!namePattern.test(this.thirdName_ar)) {
    this.validationErrors["thirdName_ar"] = true;
} else {
    delete this.validationErrors["thirdName_ar"];
}

if (!namePattern.test(this.lastName_ar)) {
    this.validationErrors["lastName_ar"] = true;
} else {
    delete this.validationErrors["lastName_ar"];
}
    
  
      if (!this.selectedRelation.code) this.validationErrors["selectedRelation"] = true;
      else delete this.validationErrors["selectedRelation"];

      if (
        this.mobileNumber == "" ||
        this.mobileNumber == undefined ||
        !this.validateMobileNumber(this.mobileNumber)
      )
        this.validationErrors["mobileNumber"] = true;
      else delete this.validationErrors["mobileNumber"];

      return !Object.keys(this.validationErrors).length;
    },
    saveGardianInfoPage() {
      if (localStorage.getItem("isSubmitted") == "false") {
        this.submitted = true;
        if (this.validateForm()) {
          this.$store.commit("showLoaderFn", true);
          this.$store
            .dispatch("Gardian/Add_Update_ApplicantGuardian", {
              fnamE_EN: this.firstName,
              mnamE_EN: this.secondName,
              lnamE_EN: this.thirdName,
              familY_EN: this.lastName,
              fnamE_AR: this.firstName_ar,
              mnamE_AR: this.secondName_ar,
              lnamE_AR: this.thirdName_ar,
              familY_AR: this.lastName_ar,
              guardianEmail: this.emailField,
              guardianRelationId: this.selectedRelation.code,
              mobileNumber: this.mobileNumber,
            })
            .then((res) => {
              if (res.status) {
                // this.$toast.add({
                //   severity: "success",
                //   detail: this.$t("dataSavedSuccessfuly"),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t("dataSavedSuccessfuly"),
                });
                this.$emit("nextPage", 3);
                this.$store.commit("showLoaderFn", false);
              }
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("fillTheMissedData"),
          });
        }
      } else {
        this.$emit("nextPage", 3);
      }
    },
    previousPage() {
      this.$emit("nextPage", 1);
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Gardian/fetchRelationsData");
    this.$store.dispatch("Gardian/fetchGardiansInfoData").then((result) => {
      this.firstName = result.fname;
      this.secondName = result.mname;
      this.thirdName = result.lname;
      this.lastName = result.family;
      this.firstName_ar = result.fname_ar;
      this.secondName_ar = result.mname_ar;
      this.thirdName_ar = result.lname_ar;
      this.lastName_ar = result.family_ar;
      this.emailField = result.guardianEmail;
  
   console.log(result.guardianMobile);
      this.mobileNumber = result.guardianMobile;
      this.selectedRelation = {
        code: result.guardianRelationId,
        name: result.relationDescr,
      };
      this.$store.commit("showLoaderFn", false);
    });
    // this.$store.commit("showLoaderFn", false);
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
