<template>
  <div class="navigatorDiv">
    <div class="my-4" style="color: var(--main-color)">
      {{ $t("successPasswordReset") }}
    </div>
    <img
      style="width: 120px; margin: auto"
      src="../assets/images/svgs/completedStep.svg"
    />
    <div class="my-5" style="width: 250px; margin: auto; color: var(--main-color)">
      {{ $t("passwordLinkSent") }}
    </div>
    <router-link to="/login">
      <Button :label="backToLoginBtnLabel" class="btnClass"
    /></router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    backToLoginBtnLabel() {
      return this.$t("backToLogin");
    },
  },
};
</script>

<style scoped>
.navigatorDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.loginGuideSpan {
  inline-size: 330px;
  overflow-wrap: break-word;
  font-size: 1.4vw;
  margin: 10px auto;
}

.btnClass {
  width: 320px;
  margin: 5px auto;
  height: 45px;
  font-weight: bold;
}
.labelDiv {
  margin-inline: 10px;
  width: 120px;
  font-weight: bold;
  text-align: start;
}

.inputGroup {
  margin: 10px auto;
  width: 450px;
}

.inputGroup > input {
  border-radius: 5px;
}

.forgetPasswordDiv {
  text-align: end;
  width: 330px;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #028bff;
  text-transform: capitalize;
}
</style>
