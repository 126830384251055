export default {
    data() {
        return {

        }
    },
    created: function () {
    },
    methods: {
        onlyNumbersWithDot($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        onlyNumbers($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        onlyNumbersWithPlus($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 43) {
                $event.preventDefault();
            }
        },
        validateMobileNumber(mobile_No) {
            // return (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(mobile_No));
            return (/^(\+\d{1,3}[- ]?)?\d{10,15}$/.test(mobile_No.split(" ").join("")));
        },
    },
}