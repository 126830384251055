<template>
  <div class="mainForm">
    <div>
      <div class="flex m-3 flex-column">
        <div class="tableContainer">
          <table class="documentsTab">
            <tr class="headerTR">
              <th>{{ $t("Document") }}</th>
              <th>{{ $t("Type") }}</th>
              <th>{{ $t("Action") }}</th>
            </tr>
            <tr v-for="(document, index) in documentsData" :key="index">
              <td>
                <div style="text-align: left">{{ document.docDescr }}</div>
              </td>
              <td>
                <div
                  :style="{
                    color: document.mandatoryFlg == true ? 'red' : 'black',
                  }"
                >
                  {{ document.mandatoryFlg ? $t("mandatory") : $t("optinal") }}
                </div>
              </td>
              <td>
                <div>
                  <div v-if="document.appDocPath !=='C:/inetpub/wwwroot/ALAzhar_SIS_Production'">
                    <i
                      class="pi pi-check uploadOrDeleteBtn"
                      style="
                        background-color: green;

                        width: fit-content;
                        margin-inline-end: 2px;
                      "
                    ></i>
                  </div>
                  <div @click="uploadFile(document.admAppDocId)">
                    <i
                      class="pi pi-upload uploadOrDeleteBtn"
                      style="cursor: pointer"
                    ></i>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #df2b2b; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <div v-tooltip="`${nextBtnTooltip}`">
        <Button
          :label="nextBtnTxt"
          class="btnClass"
          @click="nextPage"
          icon="pi pi-angle-right"
          iconPos="right"
          :disabled="
            documentsData.filter(
              (o) => o.mandatoryFlg == true && o.appDocPath == ''
            ).length > 0
          "
        />
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="deleteDocumentDialog"
    :style="{ width: '450px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <span v-if="selectedDocument"
        >{{ $t("deleteConfirm") }}<b>{{ selectedDocument.docDescr }}</b
        >'?</span
      >
    </div>
    <template #footer>
      <Button
        :label="noLabel"
        class="p-button"
        @click="deleteDocumentDialog = false"
      ></Button>

      <Button
        :label="yesLabel"
        class="p-button-danger"
        @click="deleteFile"
      ></Button>
    </template>
  </Dialog>
</template>

<script>
import mixin from "../../store/mixin.js";

export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,
      documentsData: [],
      deleteDocumentDialog: false,
      selectedDocument: {},
      mandatoryFiles: [],
      baseUrl: "http://193.227.44.82:9090/",
      validationErrors: {},
    };
  },
  computed: {
    nextBtnTooltip() {
      return this.$t("nextBtnTooltip");
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
    yesLabel() {
      return this.$t("yes");
    },
    noLabel() {
      return this.$t("no");
    },
  },
  methods: {
    confirmDeleteDocument(document) {
      this.selectedDocument = document;
      this.deleteDocumentDialog = true;
    },
    nextPage() {
      if (localStorage.getItem("isSubmitted") == "false") {
        this.mandatoryFiles = this.documentsData.filter((item) => {
          return item.mandatoryFlg == true && !item.appDocPath;
        });
        if (this.mandatoryFiles.length == 0) {
          this.$emit("nextPage", 6);
          this.submitted = true;
        } else {
          // this.$toast.add({
          //   severity: "error",
          //   detail: this.$t("pleaseCompeleteData"),
          //   life: 3000,
          // });
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("pleaseCompeleteData"),
          });
        }
      } else {
        this.$emit("nextPage", 6);
      }
    },
    previousPage() {
      this.$emit("nextPage", 4);
    },
    uploadFile(id) {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "*";
      fileInput.addEventListener("change", (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("AdmAppDocId", id);
        formData.append("file", file);

        this.$store.commit("showLoaderFn", true);
        this.$store
          .dispatch("Documents/UploadDocument", formData)
          .then((res) => {
            if (res.status) {
              this.$swal.fire({
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t("documentUploadedSuccessfuly"),
              });
              this.$store
                .dispatch("Documents/fetchDocumentsData")
                .then((result) => {
                  this.documentsData = result.data.applicantDocuments;
                });

              this.mandatoryFiles = this.documentsData.filter((item) => {
                return item.mandatoryFlg == true && !item.appDocPath;
              });
              this.$store.commit("showLoaderFn", false);
            }
          })
          .catch((error) => {
            this.$store.commit("showLoaderFn", false);
            // this.$toast.add({
            //   severity: "error",
            //   detail: this.$t(error.response.data.Message),
            //   life: 3000,
            // });
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(error.response.data.Message),
            });
          });
      });
      fileInput.click();
    },
    downloadFile(docUrl) {
      const link = document.createElement("a");
      link.href = this.baseUrl + docUrl;
      link.setAttribute("download", true);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    deleteFile() {
      this.$store.commit("showLoaderFn", true);
      this.$store
        .dispatch("Documents/DeleteDocument", this.selectedDocument.admAppDocId)
        .then((res) => {
          if (res.status) {
            // this.$toast.add({
            //   severity: "success",
            //   detail: this.$t("dataDeletedSuccessfuly"),
            //   life: 3000,
            // });
            this.$swal.fire({
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t("dataSavedSuccessfuly"),
            });
            this.$store
              .dispatch("Documents/fetchDocumentsData")
              .then((result) => {
                this.documentsData = result.data.applicantDocuments;
              });
            this.mandatoryFiles = this.documentsData.filter((item) => {
              return item.mandatoryFlg == true && !item.appDocPath;
            });
            this.$store.commit("showLoaderFn", false);
          }
        })
        .catch((error) => {
          this.$store.commit("showLoaderFn", false);
          // this.$toast.add({
          //   severity: "error",
          //   detail: this.$t(error.response.data.Message),
          //   life: 3000,
          // });
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(error.response.data.Message),
          });
        });
      this.deleteDocumentDialog = false;
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store
      .dispatch("Documents/fetchDocumentsData")
      .then((result) => {
        this.documentsData = result.data.applicantDocuments;
        this.mandatoryFiles = this.documentsData.filter((item) => {
          return item.mandatoryFlg == true && !item.appDocPath;
        });
        this.$store.commit("showLoaderFn", false);
      })
      .catch((error) => {
        this.$store.commit("showLoaderFn", false);
        // this.$toast.add({
        //   severity: "error",
        //   detail: this.$t(error.response.data.Message),
        //   life: 3000,
        // });
        this.$swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("confirmButtonText"),
          text: this.$t(error.response.data.Message),
        });
      });
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tableContainer {
  border: 2px solid var(--main-color);
  border-radius: 15px;
  width: 100%;
  max-height: 800vh;
  overflow-x: auto;
}

th {
  background-color: var(--main-color);
  color: white;
  text-align: center;
  font-family: "Cairo";

  height: 35px;
}

td > div {
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

tr:nth-child(odd) > td > div {
  background-color: #f1f1f1;
  color: black;
  margin: 5px;
  height: 50px;
}

.uploadOrDeleteBtn {
  display: inline-block;
  background-color: var(--main-color);
  font-weight: bold;
  padding: 10px;
  color: white;
  width: 80px;
  border-radius: 7px;
  font-size: 19px;
  margin: 5px;
}

.documentsTab {
  width: 100%;
  margin: 0px;
}
@media screen and (max-width: 600px) {
  .documentsTab tr {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 3px;
    margin-bottom: 3px;
    margin-inline: 5px;
    border: 1px solid var(--main-color);
  }

  .headerTR {
    border: none !important;
    margin-inline: 0px !important;
  }
}
</style>
