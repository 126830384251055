<template>
  <!-- {{ getShowLoaderstate }} -->
  <router-view />
  <Toast position="top-right" />

  <div v-if="getShowLoaderstate" class="loaderContainer">
    <spinerLoader></spinerLoader>
  </div>
</template>

<style>
[dir="rtl"] .pi-angle-right {
  transform: rotate(180deg) !important;
}

[dir="rtl"] .p-dropdown-filter {
  margin-right: 0px !important;
}

[dir="rtl"] .pi-angle-left {
  transform: rotate(180deg) !important;
}

[dir="rtl"] .p-datepicker-next-icon {
  transform: rotate(180deg) !important;
}

[dir="rtl"] .p-datepicker-prev-icon {
  transform: rotate(180deg) !important;
}

[dir="rtl"] .p-input-icon-right > i:last-of-type {
  right: 92% !important;
}

.loaderContainer {
  top: 0px;
  margin: auto;
  position: fixed;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: #80808057;
  display: flex;
}

.labelDiv {
  padding: 2px !important;
  margin-block: auto !important;
}
.validationErro {
  margin-block: auto;
}

.btnClass {
  border-radius: 7px !important;
}

.labelDiv > span:last-of-type {
  margin-inline-start: 5px !important;
}

span,
input {
  font-family: "Cairo";
}

body {
  background-color: white !important;
  height: 100vh;
}

.sideCahrtLabel {
  font-size: 1vw;
}

.vue3-circular-progressbar {
  width: 6vh !important;
  height: 6vh !important;
}

.toolTipDivClass .p-tooltip-text {
  width: 20vw !important;
  /*color:red !important;*/
}

.cardDiv {
  background-color: var(--cardsBackground) !important;

  border-color: var(--font-color) !important;
  border-width: 1.5px !important;
}
.bacgroundImg {
  z-index: 100 !important;
}
.skipBtnSheperd {
  background-color: #d32f2f !important;
}

.selectedWidget {
  border-radius: 10px;
}

.shepherd-element {
  margin: 0px 1vw !important;
}
.shepherd-button {
  margin: 0px 0.5rem !important;
}
.cardTitle {
  font-size: 1vw !important;
}

.loadingDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  border-radius: 0.5vw;
  background-color: rgba(0, 0, 0, 0.688);
  background-image: url("./assets/loading.svg");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 200 !important;
}

.loadingDiv2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  border-radius: 0.5vw;
  background-color: rgba(0, 0, 0, 0.688);
  background-image: url("./assets/loading2.svg");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 200 !important;
}

.loadingDiv3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  border-radius: 0.5vw;
  background-color: rgba(0, 0, 0, 0.688);
  background-image: url("./assets/loading3.svg");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 200 !important;
}

.loadingDiv0 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  border-radius: 0.5vw;
  background-color: rgba(0, 0, 0, 0.688);
  z-index: 200 !important;
}

@font-face {
  font-family: "The-Sans";
  src: local("The-Sans"),
    url(./assets/fonts/TheSans/The-Sans-Plain.otf) format("truetype");
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url(./assets/fonts/cairo/Cairo-Bold.ttf) format("truetype");
}

.dragArea > div {
  margin-top: 0.5vh;
}

ol,
ul {
  padding: 0px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  margin-block-end: 4.5vh;
}

::-webkit-scrollbar-track {
  background: #3c4043;
}

::-webkit-scrollbar-thumb {
  background: var(--changable-color);
  /* border-radius: 5px;*/
}

:-webkit-scrollbar-thumb:hover {
  background: #3c4043;
}

body {
  font-family: The-Sans !important;
  margin: 0px;
  background-color: var(--backGroundColor) !important;
  color: var(--font-color);
}

button {
  background-color: var(--button-color);
}

.form-field:focus {
  border-color: #f10f26 !important;
}

.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

/********* animated border **********/
.box {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      rgb(254, 254, 254),
      rgb(248, 248, 248) 50%,
      rgb(244, 240, 248)
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 3s linear infinite;
  animation: bg-spin 3s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
.box:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
</style>

<script>
import "./assets/styles/app/app.css";
import "../public/css/dashboard.css";
import "../public/css/dbresponsive.css";
import "../public/css/main.css";
import "../public/css/normalize.css";
import "../public/css/chartist.css";
import "../public/css/flags.css";
import "../public/css/prettyPhoto.css";
import "../public/css/transitions.css";
import "../public/css/color.css";
import "../public/fontawesome-free-5.12.1-web/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../public/fonts/iconic/css/material-design-iconic-font.min.css";

import { mapGetters } from "vuex";
import spinerLoader from "@/components/shared-components/loaders/spinerLoader.vue";

export default {
  components: {
    spinerLoader,
  },
  computed: {
    ...mapGetters(["cookies/getCookiesIsDisabled", "getShowLoaderstate"]),
  },
  methods: {
    currentLangauge() {
      document.html.lang = "";
    },
  },
  mounted() {
    const unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type == "language/changeLanguage") {
        setTimeout(() => {
          document.lang = mutation.payload;
          this.$i18n.locale = mutation.payload;
          document.dir = mutation.payload == "ar" ? "rtl" : "ltr";
        }, 300);
      }
    });
    if (localStorage.getItem("language") != null) {
      document.lang = localStorage.getItem("language");
      this.$i18n.locale = localStorage.getItem("language");
      document.dir = localStorage.getItem("language") == "ar" ? "rtl" : "ltr";
    } else {
      document.lang = "ar";
      this.$i18n.locale = "ar";
      document.dir = "rtl";
    }

    if (localStorage.getItem("theam") == "dark") {
      document.body.classList.add("dark");
      document.getElementById("checkbox").checked = true;
    }
    // else{
    //   //document.body.classList.add(localStorage.getItem("theam"));
    //   document.getElementById(localStorage.getItem("theam")).checked = true;
    // }
  },
  beforeUnmount() {
    this.$store.unsubscribe();
  },
};
</script>
