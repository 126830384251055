<template>
  <div class="navigatorDiv w-full md:w-9 m-auto">
    <div class="p-inputgroup flex-1 inputGroup">
      <span class="loginGuideSpan">{{ $t("reset_password_with_passport_email") }}</span>
    </div>

    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Passport_No") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <InputText
          v-model="resetPasswordForm.Passport_No"
          :placeholder="insertPassportPlaceholder"
          class="p-inputtext-lg m-1"
        />
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("emailLabel") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <InputText
          :placeholder="insertEmailPlaceholder"
          v-model="resetPasswordForm.email"
          @keydown="validateEmail"
          @change="validateEmail"
          @input="validateEmail"
          :class="[
            'p-inputtext-lg m-1',
            {
              'p-invalid': validationErrors.emailField,
            },
          ]"
        />
      </div>
    </div>
    <div
      v-if="validationErrors.not_valid_email"
      class="p-inputgroup flex-1 inputGroup validationErro"
    >
      <div class="labelDiv"></div>
      <div>
        {{ $t("notValidEmail") }}
      </div>
    </div>
    <div class="p-inputgroup flex-1 inputGroup">
      <div class="labelDiv"></div>
      <Button
        :label="submitLabel"
        class="btnClass"
        :disabled="
          resetPasswordForm.Passport_No == '' ||
          resetPasswordForm.email == '' ||
          validationErrors.not_valid_email
        "
        @click="resetPasswordFn"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      resetPasswordForm: {
        Passport_No: "",
        email: "",
      },
      validationErrors: {},
    };
  },
  components: {},
  computed: {
    insertPassportPlaceholder() {
      return this.$t("Insert_Number");
    },
    insertEmailPlaceholder() {
      return this.$t("insertEmailPlaceholder");
    },
    submitLabel() {
      return this.$t("Submit");
    },
  },
  methods: {
    validateEmail() {
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.resetPasswordForm.email
        )
      ) {
        this.validationErrors["not_valid_email"] = true;
      } else delete this.validationErrors["not_valid_email"];
    },
    async resetPasswordFn() {
      this.$router.push("/resetpassword");
      this.$store.commit("showLoaderFn", true);
      this.$store
        .dispatch("Auth/RESET_PASSWORD", this.resetPasswordForm)
        .then((res) => {
          if (res.status) {
            this.$router.push("/resetpassword");
            this.$store.commit("showLoaderFn", false);
          }
        })
        .catch((error) => {
          this.$store.commit("showLoaderFn", false);
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(error.response.data.Message),
          });
        });
    },
  },
};
</script>

<style scoped>
.validationErro {
  color: red;
  font-size: 0.8vw;
  line-height: 2;
}
.navigatorDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.loginGuideSpan {
  inline-size: 330px;
  overflow-wrap: break-word;
  font-size: 1.5rem;
  margin: 10px auto;
}

.btnClass {
  width: 320px;
  margin: 5px auto;
  height: 45px;
  font-weight: bold;
}
.labelDiv {
  margin-inline: 10px;
  font-weight: bold;
  text-align: start;
}

.inputGroup {
  margin: 10px auto;
  width: 70%;
}

.inputGroup > input {
  border-radius: 5px;
}

.forgetPasswordDiv {
  text-align: end;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #028bff;
  text-transform: capitalize;
}
</style>
