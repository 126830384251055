<template>
  <div class="mainForm">
    <div class="p-3">
      <div class="flex m-1">
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Country") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-start justify-content-start font-bold border-round col-4"
        >
          <!-- @change="fetchSchoolsForCountry" -->
          <Dropdown
            v-model="selectedCountry"
            :options="countriesData"
            optionLabel="name"
            filter
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCountry && submitted },
            ]"
          />
        </div>
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
        >
          <span>{{ $t("Graduation_Date") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-3"
        >
          <Calendar
            v-model="Graduation_Date"
            @hide="checkForGraduationDate"
            showIcon
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.Graduation_Date && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-1">
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Certificate_Type") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-4"
        >
          <Dropdown
            v-model="selectedCertificateType"
            :options="certificateTypesData"
         
            optionLabel="name"
            :loading="certificateTypesData.length == 0 && selectedSchool != {}"
            @change="fetchCertificateNamesForCertificateType"
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCertificateType && submitted },
            ]"
          />
        </div>
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
        >
          <span>{{ $t("Total_Score") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-3"
        >
          <!-- {{ selectedCertificateType.code }} -->
          <InputText
            placeholder=""
            v-model="Total_Score"
            :min="applicantScore"
            @keypress="onlyNumbersWithDot"
            :disabled="
              selectedCertificateType.code == 1 ||
              selectedCertificateType.code == 3 ||
              selectedCertificateType.code == 6
            "
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.applicantMaxScore && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-1">
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Certificate_Name") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-4"
        >
          <Dropdown
            v-model="selectedCertificateName"
            :options="certificateNamesData"
            optionLabel="name"
            :loading="certificateNamesData.length == 0 && selectedCertificateType != {}"
            @change="fetchSpecializationsDataForCertificateName"
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedCertificateName && submitted },
            ]"
          />
        </div>
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
        >
          <span>{{ $t("Applicant_Score") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-3"
        >
          <InputText
            :placeholder="insert_ScorePlaceholder"
            v-model="applicantScore"
            :max="Total_Score"
            @change="calcPercentage"
            @input="calcPercentage"
            @keypress="onlyNumbersWithDot"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.applicantScore && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-1">
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("School_Name") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-4"
        >
          <Dropdown
            v-model="selectedSchool"
            :options="schoolsData"
            optionLabel="name"
            filter
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedSchool && submitted },
            ]"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value.code == -1">
                {{ $t("others") }}
              </div>
              <div v-else-if="slotProps.value.code != undefined">
                {{ slotProps.value.name }}
              </div>
              <div v-else>
                {{ slotProps.placeholder }}
              </div>
            </template>
            <template #option="slotProps">
              <div v-if="slotProps.option.code == -1">{{ $t("others") }}</div>
              <div v-else>{{ slotProps.option.name }}</div>
            </template>
          </Dropdown>
        </div>
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
          v-if="selectedSchool.code == -1"
        >
          <span>{{ $t("School_Name") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-3"
          v-if="selectedSchool.code == -1"
        >
          <InputText
            placeholder=""
            v-model="schoolNameTxt"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.schoolNameTxt && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-1">
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <span>{{ $t("Specialization") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-4"
        >
          <Dropdown
            v-model="selectedSpecialization"
            :options="specializationsData"
            optionLabel="name"
            :loading="specializationsData.length == 0 && selectedCertificateName != {}"
            :placeholder="selectPlaceholder"
            @change="fetchSubjectsDataForSpecializmName"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedSpecialization && submitted },
            ]"
          />
        </div>
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2 px-3"
        >
          <span>{{ $t("Percentage") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-3"
        >
          <InputText
            placeholder=""
            v-model="Percentage"
            disabled
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.Percentage && submitted },
            ]"
          />
        </div>
      </div>
      <div
        class="flex m-1"
        style="background: rgba(2, 139, 255, 0.06) no-repeat padding-box"
      >
        <div
          class="m-1 flex labelDiv align-items-start justify-content-start font-bold border-round col-2"
        >
          <div
            v-if="subjectsData.length > 0 && subjectsDataWithMarks.length > 0"
            style="line-height: 50px"
          >
            <span>{{ $t("Certificate_Subjects") }}</span
            ><span style="color: red"></span>
          </div>
        </div>
        <div
          class="mx-2 flex align-items-center justify-content-center font-bold border-round col-10"
          style="padding-inline-end: 50px"
        >
          <div
            v-if="subjectsData.length > 0 && subjectsDataWithMarks.length > 0"
            class="flex flex-row w-full justify-content-between"
          >
            <div class="flex mt-1" v-for="(subject, index) in subjectsData" :key="index">
              <div
                class="flex justify-content-center align-items-center"
                style="color: var(--main-color2); font-size: 0.9vw"
              >
                <span style="color: var(--main-color2)">{{ subject.name }}</span>
              </div>
              <div class="flex-1 m-1">
                <InputText
                  :placeholder="subjectMarkPlaceholder"
                  v-model="subjectsDataWithMarks[index].mark"
                  style="width: 90px !important"
                  :max="subjectsDataWithMarks[index].maxMark"
                  @change="calcPercentageForSubject(index)"
                  @input="calcPercentageForSubject(index)"
                  @keypress="onlyNumbersWithDot"
                  :class="[
                    'w-full p-inputtext-sm',
                    { 'p-invalid': validationErrors.englishMark && submitted },
                  ]"
                />
              </div>
              <div class="flex-1 m-1" style="color: var(--main-color2)">
                <InputText
                  :placeholder="subjectMaxMarkPlaceholder"
                  v-model="subjectsDataWithMarks[index].maxMark"
                  :min="subjectsDataWithMarks[index].mark"
                  @keypress="onlyNumbersWithDot"
                  style="width: 90px !important"
                  :class="['w-full p-inputtext-sm']"
                />
              </div>
              <div class="flex-1 m-1">
                <InputText
                  :placeholder="subjectPercentagePlaceholder"
                  v-model="subjectsDataWithMarks[index].percentage"
                  disabled
                  style="width: 90px !important"
                  :class="[
                    'w-full p-inputtext-sm',
                    { 'p-invalid': validationErrors.englishMark && submitted },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #0f5887; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <Button
        :label="nextBtnTxt"
        class="btnClass"
        style="background-color: #d89436; border: none"
        @click="saveSchoolInfoPage"
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../store/mixin.js";

export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,
      selectedCountry: {},
      selectedSchool: {},
      schoolNameTxt: "",
      selectedCertificateType: {},
      selectedCertificateName: {},
      selectedSpecialization: {},
      subjectsDataWithMarks: [
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
      ],
      Graduation_Date: "",
      Total_Score: 0,
      applicantScore: 0,
      Percentage: "",
      validationErrors: {},
    };
  },
  computed: {
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    insert_ScorePlaceholder() {
      return this.$t("Applicant_ScorePlaceholder");
    },
    subjectMarkPlaceholder() {
      return this.$t("subjectMarkPlaceholder");
    },
    subjectMaxMarkPlaceholder() {
      return this.$t("subjectMaxMarkPlaceholder");
    },
    subjectPercentagePlaceholder() {
      return this.$t("subjectPersentagePlaceholder");
    },
    countriesData() {
      return this.$store.getters["Auth/getCountries2Data"];
    },
    schoolsData() {
      return this.$store.getters["Schools_embassy/getSchoolsData"];
    },
    certificateTypesData() {
      return this.$store.getters["Schools_embassy/getCertificateTypesData"];
    },
    certificateNamesData() {
      return this.$store.getters["Schools_embassy/getCertificateNamesData"];
    },
    specializationsData() {
      return this.$store.getters["Schools_embassy/getSpecializationsData"];
    },
    subjectsData() {
      return this.$store.getters["Schools_embassy/getSubjectsData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    checkForGraduationDate() {
      var Graduation_Date_DT = new Date(this.Graduation_Date);

      var month_diff = Graduation_Date_DT - new Date(new Date().getFullYear(), 0, 1);
      if (Math.abs(new Date(month_diff).getUTCFullYear() - 1970) > 4) {
        this.validationErrors["graduation_Date_not_correct"] = true;
        // this.$toast.add({
        //   severity: "error",
        //   detail: this.$t("graduation_Date_not_correct"),
        //   life: 3000,
        // });
        this.$swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("confirmButtonText"),
          text: this.$t("graduation_Date_not_correct"),
        });
      } else delete this.validationErrors["graduation_Date_not_correct"];
    },
    calcPercentage() {
      if (
        Number(this.Total_Score) != 0 &&
        this.applicantScore <= Number(this.Total_Score)
      ) {
        this.Percentage =
          ((Number(this.applicantScore) / Number(this.Total_Score)) * 100)
            .toFixed(1)
            .toString() + "%";
      } else {
        this.applicantScore = this.Total_Score;
        if (Number(this.Total_Score) != 0) this.Percentage = (100).toString() + "%";
      }
    },
    calcPercentageForSubject(index) {
      if (
        Number(this.subjectsDataWithMarks[index].maxMark) != 0 &&
        Number(this.subjectsDataWithMarks[index].mark) <=
          Number(this.subjectsDataWithMarks[index].maxMark)
      ) {
        this.subjectsDataWithMarks[index].percentage =
          (
            (Number(this.subjectsDataWithMarks[index].mark) /
              Number(this.subjectsDataWithMarks[index].maxMark)) *
            100
          )
            .toFixed(1)
            .toString() + "%";
      } else {
        this.subjectsDataWithMarks[index].mark = this.subjectsDataWithMarks[
          index
        ].maxMark;
        if (Number(this.subjectsDataWithMarks[index].maxMark) != 0)
          this.subjectsDataWithMarks[index].percentage = (100).toString() + "%";
      }
    },
    async fetchSchoolsForCountry() {
      await this.$store.dispatch("Schools_embassy/fetchSchoolsData", {
        countryId: this.selectedCountry.code,
        certificateName: this.selectedCertificateName.code,
      });
    },
    async fetchCertificateNamesForCertificateType() {
       console.log(this.schoolsData);
       console.log(this.schoolsData);
       console.log(this.schoolsData);
      this.schoolsData=[]
      this.selectedCertificateName = {};
      this.selectedSpecialization = {};
      this.subjectsData = [];
      this.subjectsDataWithMarks = [
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
      ];
      // this.Total_Score = 0;
      this.Percentage = 0;
      this.fetchSchoolsForCountry();
      await this.$store.dispatch(
        "Schools_embassy/fetchCertificateNamesData",
        this.selectedCertificateType.code
      );
    },
    async fetchSpecializationsDataForCertificateName() {
      this.subjectsData = [];
      this.subjectsDataWithMarks = [
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
        { subject: 0, mark: "", maxMark: "", percentage: "" },
      ];

      if (this.Total_Score > 0) {
        this.Percentage =
          ((Number(this.applicantScore) / Number(this.Total_Score)) * 100)
            .toFixed(1)
            .toString() + "%";
      }
      this.fetchSchoolsForCountry();

      await this.$store.dispatch(
        "Schools_embassy/fetchSpecializationsData",
        this.selectedCertificateName.code
      );
    },
    async fetchSubjectsDataForSpecializmName() {
      if (
        this.selectedCertificateType.code == 1 ||
        this.selectedCertificateType.code == 3 ||
        this.selectedCertificateType.code == 6
      ) {
        this.Total_Score = this.selectedSpecialization.totalMark;
      }

      await this.$store
        .dispatch("Schools_embassy/fetchSubjectsData", this.selectedSpecialization.code)
        .then(() => {
          this.subjectsDataWithMarks = this.subjectsData.map((subj) => ({
            subject: subj.code,
            mark: "",
            maxMark: "",
            percentage: "",
          }));
        });
    },
    validateForm() {
      if (this.selectedCountry.code == "")
        this.validationErrors["selectedCountry"] = true;
      else delete this.validationErrors["selectedCountry"];

      if (this.selectedSchool.code == "") this.validationErrors["selectedSchool"] = true;
      else delete this.validationErrors["selectedSchool"];

      if (
        this.selectedSchool.code == -1 &&
        (this.schoolNameTxt == "" || this.schoolNameTxt == undefined)
      )
        this.validationErrors["schoolNameTxt"] = true;
      else delete this.validationErrors["schoolNameTxt"];

      if (this.selectedCertificateType.code == "")
        this.validationErrors["selectedCertificateType"] = true;
      else delete this.validationErrors["selectedCertificateType"];

      if (this.selectedCertificateName.code == "")
        this.validationErrors["selectedCertificateName"] = true;
      else delete this.validationErrors["selectedCertificateName"];

      if (this.selectedSpecialization.code == "")
        this.validationErrors["selectedSpecialization"] = true;
      else delete this.validationErrors["selectedSpecialization"];

      if (
        this.specializationsData.length > 0 &&
        (this.selectedSpecialization.code == "" ||
          this.selectedSpecialization.code == undefined)
      )
        this.validationErrors["selectedSpecialization"] = true;
      else delete this.validationErrors["selectedSpecialization"];

      if (this.Graduation_Date == "" || this.Graduation_Date == undefined)
        this.validationErrors["Graduation_Date"] = true;
      else delete this.validationErrors["Graduation_Date"];
      if (
        (this.selectedCertificateType.code == 2 ||
          this.selectedCertificateType.code == 4 ||
          this.selectedCertificateType.code == 5 ||
          this.selectedCertificateType.code == 7) &&
        (this.Total_Score == "" ||
          this.Total_Score == "0" ||
          this.Total_Score == undefined)
      )
        this.validationErrors["applicantMaxScore"] = true;
      else delete this.validationErrors["applicantMaxScore"];

      if (this.applicantScore == "" || this.applicantScore == undefined)
        this.validationErrors["applicantScore"] = true;
      else delete this.validationErrors["applicantScore"];

      return !Object.keys(this.validationErrors).length;
    },
    saveSchoolInfoPage() {
      // this.$emit("nextPage", 5);
      // if (localStorage.getItem("isSubmitted") == "false") {
      if (!this.$store.getters["Auth_embassy/getViewOnly"]) {
        this.submitted = true;
        if (this.validateForm()) {
          if (Number(this.Total_Score) >= Number(this.applicantScore)) {
            this.$store.commit("showLoaderFn", true);
            this.$store
              .dispatch("Schools_embassy/Add_Update_SchoolInformation", {
                Graduation_Date: this.Graduation_Date,
                selectedCertificateType: this.selectedCertificateType.code,
                selectedCertificateName: this.selectedCertificateName.code,
                selectedSpecialization: this.selectedSpecialization.code,
                selectedCountry: this.selectedCountry.code,
                selectedSchool:
                  this.selectedSchool.code == -1 ? null : this.selectedSchool.code,
                schoolName: this.schoolNameTxt,
                Total_Score: this.Total_Score,
                applicantScore: this.applicantScore,
                Percentage: this.Percentage,
                subjectsDataWithMarks: this.subjectsDataWithMarks.map((subj) => ({
                  edCodeQualSubjectId: subj.subject,
                  subjectMark: Number(subj.mark),
                  MaxMark: Number(subj.maxMark),
                })),
              })
              .then((res) => {
                if (res.status) {
                  // this.$toast.add({
                  //   severity: "success",
                  //   detail: this.$t("dataSavedSuccessfuly"),
                  //   life: 3000,
                  // });
                  this.$swal.fire({
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: this.$t("confirmButtonText"),
                    text: this.$t("dataSavedSuccessfuly"),
                  });
                  this.$emit("nextPage", 5);
                  this.$store.commit("showLoaderFn", false);
                }
              })
              .catch((error) => {
                this.$store.commit("showLoaderFn", false);
                // this.$toast.add({
                //   severity: "error",
                //   detail: this.$t(error.response.data.Message),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "error",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t(error.response.data.Message),
                });
              });
          } else {
            // this.$toast.add({
            //   severity: "error",
            //   detail: this.$t("applicantScoreIncorrect"),
            //   life: 4000,
            // });
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t("applicantScoreIncorrect"),
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("fillTheMissedData"),
          });
        }
      } else {
        this.$emit("nextPage", 5);
      }
    },
    previousPage() {
      this.$emit("nextPage", 3);
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Auth/fetchCountries2Data").then(() => {
      this.$store.dispatch("Schools_embassy/fetchCertificateTypesData").then(() => {
        if (
          this.$store.getters["Auth_embassy/getSelectedAdmApplicantId"] != 0 &&
          this.$store.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"] != 0
        ) {
          this.$store
            .dispatch("Schools_embassy/fetchSchoolsInfoData")
            .then((result) => {
              this.Graduation_Date = result.qualDate;
              this.Total_Score = result.CountryTotalMark;
              this.applicantScore = result.applicantTotalDegree;
              this.Percentage = result.applicantPercent;
              (this.schoolNameTxt = result.schoolName),
                (this.selectedCountry = this.countriesData.filter(
                  (c) => c.code == result.gsCountryNodeId
                )[0]);
              this.selectedCertificateType = this.certificateTypesData.filter(
                (c) => c.code == result.edCdeQualCatId
              )[0];
              this.fetchCertificateNamesForCertificateType().then(() => {
                this.selectedCertificateName = this.certificateNamesData.filter(
                  (c) => c.code == result.edCodeQualID
                )[0];
                this.fetchSpecializationsDataForCertificateName().then(() => {
                  this.selectedSpecialization = this.specializationsData.filter(
                    (c) => c.code == result.edCdeQualBrnId
                  )[0];

                  this.fetchSchoolsForCountry().then(() => {
                    if (result.gsCdeSchoolId != null) {
                      this.selectedSchool = this.schoolsData.filter(
                        (c) => c.code == result.gsCdeSchoolId
                      )[0];
                    } else {
                      this.selectedSchool = this.schoolsData.filter(
                        (c) => c.code == -1
                      )[0];
                    }
                  });

                  this.fetchSubjectsDataForSpecializmName().then(() => {
                    this.subjectsDataWithMarks = result.subjects.map((subj) => ({
                      subject: subj.edCodeQualSubjectId,
                      mark: subj.appSubjectMark,
                      maxMark: subj.appMaxSubjectMark,
                      percentage:
                        (Number(subj.appMaxSubjectMark) > 0
                          ? (Number(subj.appSubjectMark) /
                              Number(subj.appMaxSubjectMark)) *
                            100
                          : 0
                        )
                          .toFixed(1)
                          .toString() + "%",
                    }));
                    this.$store.commit("showLoaderFn", false);
                  });
                });
              });
              // this.$store.commit("showLoaderFn", false);
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
            });
        } else {
          this.$store.commit("showLoaderFn", false);
        }
      });
    });
  },
};
</script>

<style scoped>
>>> .p-calendar-w-btn .p-datepicker-trigger {
  background-color: #d89436 !important;
  border: none;
}

:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
