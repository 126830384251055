<template>
  <div class="mainForm">
    <div>
      <div class="flex m-3 flex-column" >
        <div class="tableContainer">
          <table class="feesTab">
            <tr class="headerTR">
              <th>{{ $t("Fees_Type") }}</th>
              <th>{{ $t("Amount") }}</th>
              <th>{{ $t("Amount_Paid") }}</th>
              <th>{{ $t("Paid") }}</th>
            </tr>
            <tr v-for="(fee, index) in feesData" :key="index">
              <td>
                <div>{{ fee.feeDescr }}</div>
              </td>
              <td>
                <div>{{ fee.feeAmount }} {{ fee.currency }}</div>
              </td>
              <td>
                <div>{{ fee.feeRemainAmount }} {{ fee.currency }}</div>
              </td>
              <td>
                <div>
                  <i v-if="fee.isPaid == '1'" class="pi pi-check paidIcon"></i>
                  <i v-else class="pi pi-times paidIcon" style="color: red"></i>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="grid m-1 justify-content-between">
          <div
            v-if="feesData.filter((f) => f.isPaid == '0').length > 0"
            class="col-12 md:col-7"
          >
            <h6 style="color: var(--main-color); font-family: 'Cairo'; font-weight: bold">
              {{ $t("Pay_Fees_messege") }}
            </h6>
          </div>
      
          <div
            class="col-12 md:col-5 grid"
            style="justify-content: end; justify-content: end"
            v-if="payEnabled && feesData.filter((f) => f.isPaid == '0').length > 0"
          >
          
            <div
          
            class="payBtn col-5 ml-1"
            v-if="1 && feesData[0].currency=='EGP' ||feesData[0].currency=='جنيه مصرى' "
            @click="getUrl('F')"
          >
            <img :src="fawryIMG()" />
          </div >
            <div v-else class="payBtn col-5 ml-1" @click="getUrl('o')" >
              <img src="../../assets/images/NBE.png" />

            </div>
            <!-- <p class="text-danger"> {{ $t("Feeisstoped") }}</p> -->

          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #df2b2b; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <div v-tooltip="`${nextBtnTooltip}`">
        <Button
          :label="nextBtnTxt"
          class="btnClass"
          @click="nextPage"
          icon="pi pi-angle-right"
          iconPos="right"
        />
        <!-- <Button
        :label="nextBtnTxt"
        class="btnClass"
        @click="nextPage"
        :disabled="feesData.filter((f) => f.isPaid != '1').length > 0"
        icon="pi pi-angle-right"
        iconPos="right"
      /> -->
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../store/mixin.js";
import axios from 'axios';
export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,
      validationErrors: {},
      Loading:false,
      payEnabled: true,
      ip: null,
      isEgypt:1,
    };
  },
  computed: {
    feesData() {
      return this.$store.getters["Fees/getFeesData"];
    },
    applicationCountry() {
      return this.$store.getters["Fees/getApplicationCountry"];
    },
    Pay_FeesBtnLabel() {
      return this.$t("Pay_Fees");
    },
    nextBtnTooltip() {
      return this.$t("nextBtnTooltip");
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
 

    // Add this computed property to determine if the country is Egypt or not
    isEgypt() {
      return this.applicationCountry === 'EG' ? 1 : 0;
    },
  },
  methods: {
    fawryIMG() {
      return localStorage.getItem("language") == "ar"
        ? require("../../assets/images/fawry_ar.png")
        : require("../../assets/images/fawry_en.png");
    },
    nextPage() {

      if (localStorage.getItem("isSubmitted") == "false") {
    if (this.feesData[0]?.isPaid) {
      this.$emit("nextPage", 7);

    }
      }
    },
    previousPage() {
      this.$emit("nextPage", 5);
    },
    getUrl(paymentMethod) {
          // this.$swal.fire({
          //   icon: "error",
          //   confirmButtonColor: "#3085d6",
          //   confirmButtonText: this.$t("confirmButtonText"),
          //   text: this.$t("Feeisstoped"),
          // });
      if (paymentMethod == "F") {
        this.$store
          .dispatch("Fees/fetchFawryPaymentURL")
          .then((res) => {
            window.location.href = res.redirectURL;
          })
          .catch((error) => {});
      } else {
        this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("NBisstoped"),
          });
        // this.$store
        //   .dispatch("Fees/fetchPaymentURL")
        //   .then((res) => {
        //     window.location.href = res.redirectURL;
        //   })
        //   .catch((error) => {});
      }
    },
  },
  created() {
   setTimeout(() => {
   this. Loading=true
   }, 1100);
   this.$store.dispatch("Fees/fetchApplicationCountry");

    this.$store.dispatch("Fees/checkForFawryPayment").then((res) => {
        if (res.data.message == "MSG-DEV2-000034") {
      console.log();
        } 
      });
    debugger;
    if (this.$route.params.id == "2") {
      this.$store.dispatch("Fees/checkForFawryPayment").then((res) => {
        if (res.data.message == "MSG-DEV2-000030") {
          this.$swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(res.data.message),
          });
    this.$store.dispatch("Fees/fetchFeesData");

        } else if (res.data.message == "MSG-DEV2-000033") {
          this.$swal.fire({
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(res.data.message),
          });
    this.$store.dispatch("Fees/fetchFeesData");

        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(res.data.message),
          });
    this.$store.dispatch("Fees/fetchFeesData");

        }
      });
      this.payEnabled = false;
    } else if (this.$route.params.id == "1") {
      this.$store
        .dispatch("Fees/checkForPayment", 1)
        .then((res) => {
          if (res.data.message == "MSG-DEV2-000030") {
            this.$swal.fire({
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(res.data.message),
            });
          } else {
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(res.data.message),
            });
          }
        })
        .catch((error) => {});
      this.payEnabled = false;
    } else if (this.$route.params.id == "0") {
      this.$store.dispatch("Fees/checkForPayment", 0)
      .then((res) => {
      
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(res.data.message),
            });
          
        })
        .catch((error) => {});
     
      
      this.payEnabled = true;
    }

    this.$router.push("/application");
    this.$store.dispatch("Fees/fetchFeesData");
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}

.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tableContainer {
  border: 2px solid var(--main-color);
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
}

th {
  background-color: var(--main-color);
  color: white;
  text-align: center;
  font-family: "Cairo";

  height: 35px;
}

td > div {
  text-align: center;
  padding: 5px;
}

tr:nth-child(odd) > td > div {
  background-color: #f1f1f1;
  color: black;
  margin: 5px;
}

.paidIcon {
  font-size: 20px;
  color: var(--main-color);
  font-weight: bold;
}

.payBtn {
  border: 2px solid var(--main-color);
  border-radius: 7px;
  padding: 5px;
  max-width: 150px;
  cursor: pointer;
}

.feesTab {
  width: 100%;
  margin: 0px;
}
@media screen and (max-width: 600px) {
  .feesTab tr {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 3px;
    margin-bottom: 3px;
    margin-inline: 5px;
    border: 1px solid var(--main-color);
  }

  .headerTR {
    border: none !important;
    margin-inline: 0px !important;
  }
}
</style>
