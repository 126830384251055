import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";

import VuePdfEmbed from 'vue-pdf-embed'

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.scss";
import "./assets/flags.css";

import tooltip from "./directives/tooltip.js";
import "./assets/tooltip.css";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueCookies from "vue-cookies";
import Checkbox from 'primevue/checkbox';

import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";
import Carousel from "primevue/carousel";
import ProgressBar from "primevue/progressbar";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import useToast from 'primevue/usetoast';
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Rating from 'primevue/rating';
import FileUpload from 'primevue/fileupload';
import Toolbar from 'primevue/toolbar';
import Tooltip from 'primevue/tooltip';
import InlineMessage from 'primevue/inlinemessage';
import Password from 'primevue/password';
import Calendar from 'primevue/calendar';
import steps from 'primevue/steps';
import InputMask from 'primevue/inputmask';

import DataView from 'primevue/dataview';
import Card from 'primevue/card';
import chart from 'primevue/chart';
import DialogService from 'primevue/dialogservice';
import VueNumber from 'vue-number-animation'

import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import i18n from "./i18n";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "../src/assets/css/colorSetting.css";
import { VueDraggableNext } from 'vue-draggable-next'
import "vue3-circle-progress/dist/circle-progress.css";
import 'form-wizard-vue3/dist/form-wizard-vue3.css'

import CircleProgress from "vue3-circle-progress";
import VueCountdown from '@chenfengyuan/vue-countdown';
import Wizard from 'form-wizard-vue3'


import VueShepherdPlugin from 'vue-shepherd';
import '../node_modules/shepherd.js/dist/css/shepherd.css';
import { Fireworks } from '@fireworks-js/vue'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
var app = createApp(App).use(i18n).use(store).use(router);

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

const VueTelInputOptions = {

    onlyCountries: [
        'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT',
        'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BV',
        'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN',
        'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CY', 'CZ', 'DK', 'DJ',
        'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF',
        'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG',
        'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE',
        'IM', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA',
        'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML',
        'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'MS', 'MA', 'MZ', 'MM',
        'NA', 'NR', 'NP', 'NL', 'AN', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM',
        'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO',
        'RU', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'CS', 'SC', 'SL',
        'SG', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH',
        'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV',
        'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH',
        'YE', 'ZM', 'ZW'
      ]
      ,

       defaultCountry: '',
      preferredCountries: [],
      disabledFetchingCountry: true,

    dropdownOptions: {
        showFlags: true,
        showSearchBox: "true",
    },
    inputOptions: {
        placeholder: "",
    }
}

app.use(VueTelInput, VueTelInputOptions); // Define default global options here (optional)

app.use(VueSweetalert2);
// app.use(VueReCaptcha, { siteKey: '6LeIigQoAAAAAH5djoLV_DikW_Vi7VGD_M2H1tho' })

app.use(VueShepherdPlugin);
app.use(PrimeVue);
app.use(email);
app.use(required);
app.use(useVuelidate);
app.use(ToastService);
app.use(VueCookies);
app.use(DialogService);
app.use(VueNumber);
app.directive("Tooltip", Tooltip);

app.component(Fireworks);
app.component(VueCountdown.name, VueCountdown);

app.component("CircleProgress", CircleProgress);
app.component("draggable", VueDraggableNext);
app.component("Chart", chart);
app.component("useToast", useToast);
app.component("DataView", DataView);
app.component("Toolbar", Toolbar);
app.component("Rating", Rating);
app.component("FileUpload", FileUpload);
app.component("Row", Row);
app.component("Checkbox", Checkbox);
app.component("ColumnGroup", ColumnGroup);
app.component("Column", Column);
app.component("Calendar", Calendar);
app.component("DataTable", DataTable);
app.component("Textarea", Textarea);
app.component("RadioButton", RadioButton);
app.component("InputNumber", InputNumber);
app.component("Toast", Toast);
app.component("Button", Button);
app.component("Dropdown", Dropdown);
app.component("InputText", InputText);
app.component("Skeleton", Skeleton);
app.component("Dialog", Dialog);
app.component("Password", Password);
app.component("Steps", steps);
app.component("Wizard", Wizard);
app.component("InputMask", InputMask);



app.component("InlineMessage", InlineMessage);

app.component("SelectButton", SelectButton);
app.component("Carousel", Carousel);
app.component("ProgressBar", ProgressBar);
app.component("Card", Card);
app.component("VuePdfEmbed", VuePdfEmbed);


app.mount("#app");
