import api from '../api';

export default {
    namespaced: true,
    state: {
        relationsData: [],
        gardianInfo: {
            "fname": "",
            "mname": "",
            "lname": "",
            "family": "",
            "fname_ar": "",
            "mname_ar": "",
            "lname_ar": "",
            "family_ar": "",
            "fullNameEn": "",
            "fullNameAr": "",
            "guardianRelationId": "",
            "relationDescr": "",
            "guardianMobile": "",
            "guardianEmail": ""
        }
    },
    getters: {
        getRelationsData(state) {
            return state.relationsData;
        },
        getGardianInfoData(state) {
            return state.gardianInfo;
        },
    },
    mutations: {
        setRelationsData(state, data) {
            state.relationsData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.relationsData.push({
                    "code": data[x].gS_CODE_KINSHIP_Id,
                    "name": data[x].kinshiP_Descr
                });
            }
        },
        setGardianInfoData(state, data) {
            state.gardianInfo = {};
            if (data != undefined) {
                var fullName_EN_Arr = (data.fullNameEn.length > 0 ? data.fullNameEn.split(' ') : ["", "", "", ""]);
                var fullName_Ar_Arr = (data.fullNameAr.length > 0 ? data.fullNameAr.split(' ') : ["", "", "", ""]);
                state.gardianInfo = {
                    "fname": fullName_EN_Arr[0],
                    "mname": fullName_EN_Arr[1],
                    "lname": fullName_EN_Arr[2],
                    "family": fullName_EN_Arr[fullName_EN_Arr.length - 1],

                    "fname_ar": fullName_Ar_Arr[0],
                    "mname_ar": fullName_Ar_Arr[1],
                    "lname_ar": fullName_Ar_Arr[2],
                    "family_ar": fullName_Ar_Arr[fullName_Ar_Arr.length - 1],

                    "fullNameEn": data.fullNameEn,
                    "fullNameAr": data.fullNameAr,
                    "guardianEmail": data.guardianEmail,

                    "guardianRelationId": data.guardianRelationId,
                    "relationDescr": data.relationDescr,
                    "guardianMobile": data.guardianMobile,
                };
            }
        },
    },
    actions: {
        fetchRelationsData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/Guardian/GetAllRealtion`)
                    .then(res => {
                        commit("setRelationsData", res.data.all_Relations);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchGardiansInfoData({ commit, state }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/Guardian/GetApplicantGuardian`)
                    .then(res => {
                        commit("setGardianInfoData", res.data.applicantGuardian[0]);
                        resolve(state.gardianInfo)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        Add_Update_ApplicantGuardian({ }, gardianInfo) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/Guardian/Add_Update_ApplicantGuardian", {
                    "fullNameEn": gardianInfo.fnamE_EN + " " + gardianInfo.mnamE_EN + " " + gardianInfo.lnamE_EN + " " + gardianInfo.familY_EN,
                    "fullNameAr": gardianInfo.fnamE_AR + " " + gardianInfo.mnamE_AR + " " + gardianInfo.lnamE_AR + " " + gardianInfo.familY_AR,
                    "guardianRelationId": gardianInfo.guardianRelationId,
                    "guardianMobile": gardianInfo.mobileNumber,
                    "guardianEmail": gardianInfo.guardianEmail
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}