import api from '../../api_embassy';
export default {
    namespaced: true,
    state: {
        collagesData: [],
        applicantPrefrences: []
    },
    getters: {
        getCollagesData(state) {
            return state.collagesData;
        },
        applicantPrefrencesData(state) {
            return state.applicantPrefrences;
        },
    },
    mutations: {
        setCollagesData(state, data) {
            state.collagesData = [];
            for (var x = 0; x < data.length; x++) {
                state.collagesData.push({
                    "code": data[x].facultieID,
                    "name": data[x].facultieDescr,
                    "programs": data[x].facultyPrograms.map((prog) => ({
                        "code": prog.majorEntMainId,
                        "name": prog.majorDescr,
                    }))
                });
            }
        },
        setApplicantPrefrencesData(state, data) {
            state.applicantPrefrences = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.applicantPrefrences.push({
                    "asFacultyInfoId": data[x].asFacultyInfoId,
                    "entMainId": data[x].entMainId,
                    "prefOrder": data[x].prefOrder,
                    "facDescr": data[x].facDescr,
                    "programDescr": data[x].programDescr,
                });
            }
        },
    },
    actions: {
        fetchCollagesData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetApplicantPref_Faculties?AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]}`)
                    .then(res => {
                        commit("setCollagesData", res.data.faculties);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchProgramsData({ commit },facultyID) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetApplicantPref_Programs?AsFacultyInfoId=${facultyID}&AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]}&AdmApplicantId=${this.getters["Auth_embassy/getSelectedAdmApplicantId"]
            }`)
                    .then(res => {
                        resolve(res.data.programs)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        GetMinMaxNumberOfPrefrences({ _ }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetMinMaxNumberOfPrefrences?AdmApplicantId=${this.getters["Auth_embassy/getSelectedAdmApplicantId"]
                    }&AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]
                    }`)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchApplicantPrefrencesData({ commit, state }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantPref/GetAllApplicantPrefrences?AdmApplicantId=${this.getters["Auth_embassy/getSelectedAdmApplicantId"]
                    }&AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]
                    }`)
                    .then(res => {
                        commit("setApplicantPrefrencesData", res.data.applicantPrefrences);
                        resolve(state.applicantPrefrences)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        SaveApplicantPrefrences({ }, preferencesDataList) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/ApplicantPref/SaveApplicantPrefrences", {
                    "admApplicantId": this.getters["Auth_embassy/getSelectedAdmApplicantId"],
                    "admAppRegHistoryId": this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"],
                    "modifiedApplicantPrefrences": preferencesDataList
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}