<template>
  <div class="navigatorDiv w-full md:w-9 m-auto">
    <div class="p-inputgroup flex-1 inputGroup">
      <span class="loginGuideSpan">{{ $t("Please_Login_With_Passport") }}</span>
    </div>

    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Passport_No") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <InputText
          v-model="loginForm.Passport_No"
          :placeholder="insertPassportPlaceholder"
          class="p-inputtext-lg"
        />
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Password") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Password
          v-model="loginForm.password"
          :placeholder="insertPasswordPlaceholder"
          class="p-inputtext-lg"
          toggleMask
        />
      </div>
    </div>
    <!-- <VueReCaptcha
    ref="recaptcha"
    sitekey="6LeIigQoAAAAAH5djoLV_DikW_Vi7VGD_M2H1tho"
    @verify="onRecaptchaVerified"
  ></VueReCaptcha> -->
    <div class="p-inputgroup flex-1 inputGroup">
      <router-link to="/resetpasswordform" class="w-full">
        <div class="forgetPasswordDiv">{{ $t("Forgot_Password") }}</div>
      </router-link>
    </div>
    <div class="p-inputgroup flex-1 inputGroup">
      <div class="labelDiv"></div>
      <Button
        :label="loginBtnLabel"
        class="btnClass"
        :disabled="loginForm.Passport_No == '' || loginForm.password == ''"
        @click="loginFn"
      />
    </div>
  </div>
</template>
<script>
// import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
  data() {
    return {
      loginForm: {
        // Passport_No: "k984982345",
        // password: "P@ssw0rd",

        Passport_No: "",
        password: "",
      },
    };
  },
  components: {
    // VueReCaptcha,
  },
  computed: {
    insertPassportPlaceholder() {
      return this.$t("Insert_Number");
    },
    insertPasswordPlaceholder() {
      return this.$t("Insert_Password");
    },
    loginBtnLabel() {
      return this.$t("Log_In");
    },
  },
  methods: {
    async loginFn() {
      // if (
      //   RegExp("^[\u0600-\u06FFa-zA-Z0-9_@ ]{5,200}$").test(this.loginForm.password) &&
      //   RegExp("^[\u0600-\u06FFa-zA-Z0-9_@ ]{8,12}$").test(this.loginForm.Passport_No)
      // ) {

      this.$store.commit("showLoaderFn", true);

      this.$store
        .dispatch("Auth/USER_LOGIN", this.loginForm)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch("Auth_embassy/fetchStepsData");
            this.$router.push("/application");
            this.$store.commit("showLoaderFn", false);
          }
        })
        .catch((error) => {
          this.$store.commit("showLoaderFn", false);
          // this.$toast.add({
          //   severity: "error",
          //   detail: this.$t(error.response.data.Message),
          //   life: 3000,
          // });

          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(error.response.data.Message),
          });
        });
      // } else {
      //   this.$toast.add({
      //     severity: "error",
      //     // summary: "تسجيل الدخول",
      //     detail: this.$t("fillTheMissedData"),
      //     life: 3000,
      //   });
      // }
    },

    async recaptcha() {
      // await this.$recaptchaLoaded();

      this.$recaptcha("login")
        .then((token) => {
          console.log(token);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.navigatorDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.loginGuideSpan {
  inline-size: 330px;
  overflow-wrap: break-word;
  font-size: 1.5rem;
  margin: 10px auto;
}

.btnClass {
  width: 320px;
  margin: 5px auto;
  height: 45px;
  font-weight: bold;
}
.labelDiv {
  margin-inline: 10px;
  font-weight: bold;
  text-align: start;
}

.inputGroup {
  margin: 10px auto;
  width: 70%;
}

.inputGroup > input {
  border-radius: 5px;
}

.forgetPasswordDiv {
  text-align: end;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #028bff;
  text-transform: capitalize;
}
</style>
