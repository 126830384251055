<template>
  <div class="mainForm">
    <div>
      <div class="flex m-3">
        <div
          class="flex-1 m-1 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("fullNameLabel") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="firstNamePlaceholder"
            v-model="firstName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.firstName && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="secondNamePlaceholder"
            v-model="secondName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.secondName && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="thirdNamePlaceholder"
            v-model="thirdName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.thirdName && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="lastNamePlaceholder"
            v-model="lastName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.lastName && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-3">
        <div
          class="flex-1 labelDiv m-1 flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("fullNameLabel_ar") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="firstNamePlaceholder"
            v-model="firstName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.firstName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="secondNamePlaceholder"
            v-model="secondName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.secondName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="thirdNamePlaceholder"
            v-model="thirdName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.thirdName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="lastNamePlaceholder"
            v-model="lastName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.lastName_ar && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-3">
        <div
          class="flex-1 labelDiv m-1 flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("genderNameLabel") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <Dropdown
            v-model="selectedgender"
            :options="gendersData"
            optionLabel="name"
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedgender && submitted },
            ]"
          />
        </div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
      </div>
      <div class="flex m-3">
        <div class="flex-1 mx-2 flex flex-column">
          <div
            class="mb-3 align-items-start justify-content-start font-bold border-round p-1"
          >
            <span>{{ $t("Birth_Date") }}</span
            ><span style="color: red">*</span>
          </div>
          <div
            class="mt-3 align-items-start justify-content-start font-bold border-round p-1"
          >
            <span>{{ $t("Birth_Place") }}</span
            ><span style="color: red">*</span>
          </div>
        </div>
        <div class="flex-1 mx-2 flex flex-column">
          <Calendar
            v-model="Birth_Date"
            @hide="checkForBirthDate"
            dateFormat="yy-mm-dd"
            showIcon
            :class="[
              'w-full mb-2',
              {
                'p-invalid':
                  (validationErrors.Birth_Date && submitted) ||
                  validationErrors.Birth_Date_not_correct,
              },
            ]"
          />
          <Dropdown
            v-model="Birth_Place"
            :options="countriesData"
            optionLabel="name"
            filter
            :placeholder="selectPlaceholder"
            :class="[
              'w-full mt-3',
              { 'p-invalid': validationErrors.Birth_Place && submitted },
            ]"
            class="w-full m-1"
          />
        </div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex">
          <div
            class="surface-section"
            style="text-align: center; margin-top: -40px"
            :class="[{ emptyIMgError: validationErrors.imgPath && submitted }]"
          >
            <div
              class="flex labelDiv align-items-center justify-content-center font-bold border-round"
            >
              <span>{{ $t("photo") }}</span
              ><span style="color: red">*</span>
            </div>
            <img
              v-if="imgPath == null || imgPath == ''"
              style="width: 180px"
              src="../../../assets/images/avatar.png"
            />
            <img
              v-else
              style="width: 180px"
              :src="'http://193.227.44.82:9090/' + imgPath"
            />

            <div class="flex justify-content-center mt-2">
              <div v-tooltip="`${deleteBtntooltip}`">
                <Button
                  icon="pi pi-trash"
                  v-if="isSubmitted == 'false'"
                  @click="removeImage"
                  style="
                    background-color: #df2b2b;
                    width: 60px;
                    margin-inline: 5px;
                    border: none;
                  "
                />
                <input
                  type="file"
                  v-if="isSubmitted == 'false'"
                  style="display: none !important"
                  id="image_setting"
                  @change="onFileChange"
                />
              </div>
              <div v-tooltip="`${uploadBtntooltip}`">
                <Button
                  v-if="isSubmitted == 'false'"
                  icon="pi pi-upload"
                  @click="uploadImage"
                  style="
                    background-color: #d89436;
                    width: 60px;
                    margin-inline: 5px;
                    border: none;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #0f5887; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />

      <Button
        :label="nextBtnTxt"
        class="btnClass"
        style="background-color: #d89436; border: none"
        @click="saveBasicInfoPage"
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../store/mixin.js";

export default {
  mixins: [mixin],
  data() {
    return {
      submitted: false,
      firstName: "",
      secondName: "",
      thirdName: "",
      lastName: "",
      firstName_ar: "",
      secondName_ar: "",
      thirdName_ar: "",
      lastName_ar: "",
      Birth_Date: "",
      imgPath: "",
      image: "",
      Birth_Place: {},
      selectedgender: {},
      validationErrors: {},
    };
  },
  computed: {
    isSubmitted() {
      return localStorage.getItem("isSubmitted");
    },
    firstNamePlaceholder() {
      return this.$t("firstNamePlaceholder");
    },
    deleteBtntooltip() {
      return this.$t("deleteBtntooltip");
    },
    uploadBtntooltip() {
      return this.$t("uploadBtntooltip");
    },
    secondNamePlaceholder() {
      return this.$t("secondNamePlaceholder");
    },
    thirdNamePlaceholder() {
      return this.$t("thirdNamePlaceholder");
    },
    lastNamePlaceholder() {
      return this.$t("lastNamePlaceholder");
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    countriesData() {
      return this.$store.getters["Auth/getCountriesData"];
    },
    gendersData() {
      return this.$store.getters["BasicInfo_embassy/getGendersData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    checkForBirthDate() {
      var Birth_Date_DT = new Date(this.Birth_Date);

      if (Birth_Date_DT < Date.now()) {
        var month_diff = Date.now() - Birth_Date_DT.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.abs(year - 1970);

        if (age < 15) {
          this.validationErrors["Birth_Date_not_correct"] = true;
          // this.$toast.add({
          //   severity: "error",
          //   detail: this.$t("Birth_Date_not_correct"),
          //   life: 3000,
          // });
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("Birth_Date_not_correct"),
          });
        } else delete this.validationErrors["Birth_Date_not_correct"];
      } else {
        this.validationErrors["Birth_Date_not_correct"] = true;
        // this.$toast.add({
        //   severity: "error",
        //   detail: this.$t("Birth_Date_not_correct"),
        //   life: 3000,
        // });
        this.$swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("confirmButtonText"),
          text: this.$t("Birth_Date_not_correct"),
        });
      }
    },
    uploadImage() {
      document.getElementById("image_setting").click();
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;
      var re = /(\.jpg|\.jpeg|\.bmp|\.doc|.docx|.pdf|\.png)$/i;
      if (!re.exec(files[0].name) || files[0].length > 1024 * 1024 * 5) {
        return;
      }

      this.createImage(files[0]);
      this.image = files[0];
      this.uploadImg();
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imgPath = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.imgPath = "";
    },
    validateForm() {
      if (this.imgPath == "" || this.imgPath == undefined)
        this.validationErrors["imgPath"] = true;
      else delete this.validationErrors["imgPath"];

      if (this.firstName == "" || this.firstName == undefined)
        this.validationErrors["firstName"] = true;
      else delete this.validationErrors["firstName"];

      if (this.secondName == "" || this.secondName == undefined)
        this.validationErrors["secondName"] = true;
      else delete this.validationErrors["secondName"];

      if (this.thirdName == "" || this.thirdName == undefined)
        this.validationErrors["thirdName"] = true;
      else delete this.validationErrors["thirdName"];

      if (this.lastName == "" || this.lastName == undefined)
        this.validationErrors["lastName"] = true;
      else delete this.validationErrors["lastName"];

      if (this.firstName_ar == "" || this.firstName_ar == undefined)
        this.validationErrors["firstName_ar"] = true;
      else delete this.validationErrors["firstName_ar"];

      if (this.secondName_ar == "" || this.secondName_ar == undefined)
        this.validationErrors["secondName_ar"] = true;
      else delete this.validationErrors["secondName_ar"];

      if (this.thirdName_ar == "" || this.thirdName_ar == undefined)
        this.validationErrors["thirdName_ar"] = true;
      else delete this.validationErrors["thirdName_ar"];

      if (this.lastName_ar == "" || this.lastName_ar == undefined)
        this.validationErrors["lastName_ar"] = true;
      else delete this.validationErrors["lastName_ar"];

      if (!this.selectedgender.code) this.validationErrors["selectedgender"] = true;
      else delete this.validationErrors["selectedgender"];

      if (this.Birth_Date == "" || this.Birth_Date == undefined)
        this.validationErrors["Birth_Date"] = true;
      else delete this.validationErrors["Birth_Date"];

      if (!this.Birth_Place.code) this.validationErrors["Birth_Place"] = true;
      else delete this.validationErrors["Birth_Place"];

      return !Object.keys(this.validationErrors).length;
    },
    uploadImg() {
      if (this.isSubmitted == "false") {
        this.$store
          .dispatch("BasicInfo_embassy/ApplicantPhotoUpload", {
            file: this.image,
          })
          .then((res) => {
            this.imgPath = res.data.saveFileLocation;
          })
          .catch((error) => {
            // this.$toast.add({
            //   severity: "error",
            //   detail: this.$t(error.response.data.Message),
            //   life: 3000,
            // });
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t(error.response.data.Message),
            });
          });
      }
    },
    saveBasicInfoPage() {
      if (!this.$store.getters["Auth_embassy/getViewOnly"]) {
        if (this.isSubmitted == "false") {
          this.submitted = true;
          if (this.validateForm()) {
            this.$store.commit("showLoaderFn", true);
            this.$store
              .dispatch("BasicInfo_embassy/Update_ApplicantBasicInformation", {
                fnamE_EN: this.firstName,
                mnamE_EN: this.secondName,
                lnamE_EN: this.thirdName,
                familY_EN: this.lastName,
                fnamE_AR: this.firstName_ar,
                mnamE_AR: this.secondName_ar,
                lnamE_AR: this.thirdName_ar,
                familY_AR: this.lastName_ar,
                gS_CODE_GENDER_ID: this.selectedgender.code,
                gS_BIRTH_COUNTRY_NODE_ID: this.Birth_Place.code,
                birthdate: this.Birth_Date,
                photO_PATH: this.imgPath,
              })
              .then((res) => {
                if (res.status) {
                  // this.$toast.add({
                  //   severity: "success",
                  //   detail: this.$t("dataSavedSuccessfuly"),
                  //   life: 3000,
                  // });
                  this.$swal.fire({
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: this.$t("confirmButtonText"),
                    text: this.$t("dataSavedSuccessfuly"),
                  });
                  this.$emit("nextPage", 2);
                  this.$store.commit("showLoaderFn", false);
                }
              })
              .catch((error) => {
                this.$store.commit("showLoaderFn", false);
                // this.$toast.add({
                //   severity: "error",
                //   detail: this.$t(error.response.data.Message),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "error",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t(error.response.data.Message),
                });
              });
          } else {
            this.$swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: this.$t("confirmButtonText"),
              text: this.$t("fillTheMissedData"),
            });
          }
        } else {
          this.$emit("nextPage", 2);
        }
      } else {
        this.$emit("nextPage", 2);
      }
    },
    previousPage() {
      this.$emit("nextPage", 0);
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Auth_embassy/fetchCountriesData");
    this.$store.dispatch("BasicInfo_embassy/fetchGendersData");
    if (
      this.$store.getters["Auth_embassy/getSelectedAdmApplicantId"] != 0 &&
      this.$store.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"] != 0
    ) {
      this.$store.dispatch("BasicInfo_embassy/fetchBasicInfoData").then((result) => {
        this.firstName = result.fname;
        this.secondName = result.mname;
        this.thirdName = result.lname;
        this.lastName = result.family;
        this.firstName_ar = result.fname_ar;
        this.secondName_ar = result.mname_ar;
        this.thirdName_ar = result.lname_ar;
        this.lastName_ar = result.family_ar;
        this.Birth_Date = result.birthdate != null ? result.birthdate.substr(0, 10) : "";
        this.Birth_Place = {
          code: result.gS_BIRTH_COUNTRY_NODE_ID,
          name: result.gS_BIRTH_COUNTRY_NODE,
        };
        this.selectedgender = { code: result.gS_CODE_GENDER_ID, name: result.gS_GENDER };
        this.imgPath = result.photO_PATH;
        this.$store.commit("showLoaderFn", false);
      });
    } else {
      this.$store.commit("showLoaderFn", false);
    }
  },
};
</script>

<style scoped>
>>> .p-calendar-w-btn .p-datepicker-trigger {
  background-color: #d89436 !important;
  border: none;
}

:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.emptyIMgError {
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
}
</style>
