<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: transparent; display: block"
    :width="divSize"
    :height="divSize"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <path d="M10 50A40 40 0 0 0 90 50A40 42.3 0 0 1 10 50" fill="#009ef7" stroke="none">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 51.15;360 50 51.15"
      ></animateTransform>
    </path>
  </svg>
</template>
<script>
export default {
  props: {
    divSize: {
      required: true,
      default: "100",
    },
  },
};
</script>
