<template>
  <Dropdown
    class="animation a6"
    v-model="selectedLanguage"
    :options="countries"
    optionLabel="name"
    :filterInputProps="{ class: 'dropdownFilter-ar' }"
    :placeholder="$t('Selectlanguage')"
    :showClear="false"
  >
    <template #value="slotProps">
      <div class="menu-item menu-item-value flex" v-if="slotProps.value">
        <div style="margin-inline: 7px">
          <i
            :style="{
              color: mainColor == 1 ? '#028BFF' : '#D89436',
              'font-weight': 'bold',
            }"
            class="pi pi-globe"
          ></i>
        </div>

        <div
          :style="{
            color: mainColor == 1 ? '#028BFF' : '#D89436',
            'font-weight': 'bold',
          }"
        >
          {{ slotProps.value.name }}
        </div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div
        class="menu-item"
        :style="{
          direction: slotProps.option.code.toLowerCase() == 'ar' ? 'rtl' : 'ltr',
        }"
      >
        <div>
          {{ slotProps.option.name }}
        </div>
      </div>
    </template>
  </Dropdown>
</template>
<script>
export default {
  data() {
    return {
      displayPosition: true,

      name: "",
      password: "",
      countries: [
        { name: "Ar", code: "ar" },
        { name: "En", code: "en" },
        // { name: "Français", code: "fr" },
      ],
      loading: false,
      selectedLanguage: { name: "Ar", code: "ar" },
      loadLazyTimeout: null,
    };
  },
  props: ["mainColor"],
  watch: {
    selectedLanguage(val) {
      if (val != null && val.code != localStorage.getItem("language")) {
        this.$store.commit("language/changeLanguage", val.code);
        this.$i18n.locale = val.code;
        localStorage.setItem("languageName", val.name);
        this.$emit("langueChanged");
        document.getAnimations().forEach((move) => {
          move.cancel();
          move.play();
        });
        location.reload();
      }
    },
  },
  mounted() {
    if (localStorage.getItem("language") != null) {
      var lang = localStorage.getItem("languageName");
      var code = localStorage.getItem("language");
      this.selectedLanguage = { name: lang, code: code };
      // this.$watch.selectedLanguage.
    }
  },
  methods: {
    onLazyLoad() {
      this.loading = true;
      if (this.loadLazyTimeout) {
        clearTimeout(this.loadLazyTimeout);
      }
      this.loadLazyTimeout = setTimeout(() => {
        this.loading = false;
      }, 400);
    },
  },
};
</script>
<style lang="scss" scoped>
.langueContainer {
  width: 100%;
  text-align: center;
  margin: auto;
}

.langueContainer div {
  background-color: var(--dropDowen-color);
  color: var(--dropDowen-text-color);
  border-color: var(--labelBorder) !important;
  font-family: "The-Sans" !important;
}
</style>
