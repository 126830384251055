<template>
    <div class="d-flex flex-column flex-center flex-column-fluid p-10">
      <!--begin::Illustration-->
      <img
        src="../assets/images/NotFound.png"
        alt=""
        class="mw-100 mb-10 h-lg-450px"
      />
      <!--end::Illustration-->
  
      <!--begin::Message-->
      <h1 class="fw-bold mb-10 m-auto" style="color: #a3a3c7">
        Seems there is nothing here
      </h1>
      <!--end::Message-->
  
      <!--begin::Link-->
      <router-link to="/" class="btnClass  m-auto btn-primary"> {{ $t("backToMainBtnLabel") }}</router-link>
      <!--end::Link-->
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
  }
  </script>
  
  <style scoped>

  </style>
  