import api from '../api';
import axios from 'axios';

export default {
    namespaced: true,
    state: {
        feesData: [],
        applicationCountry: "",
        ip:null,
        isEgypt:1,
        // paymentURL: "",
    },
    getters: {
        getFeesData(state) {
            return state.feesData;
        },
        getApplicationCountry(state) {
            return state.applicationCountry;
        },
        getIP(state) {
            return state.ip;
        },
    },
    mutations: {
        setFeesData(state, data) {
            state.feesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.feesData.push({
                    "feeDescr": data[x].feeDescr,
                    "feeAmount": data[x].feeAmount,
                    "feeRemainAmount": data[x].feeRemainAmount,
                    "isPaid": data[x].isPaid,
                    "currency": data[x].currencyDescr
                });
            }
        },
        
        setApplicationCountry(state, data) {
            state.applicationCountry = data.country;
            // state.applicationCountry = "Egypt";

        },
        setIP(state, data) {
            state.ip = data;
       

        },
        // setPaymentUrl(state, data) {
        //     state.paymentURL = data.url;
        // },
    },
    actions: {
        fetchFeesData({ commit, state }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantFees/GetAllApplicantFees`, {
                    params: {
                        isEgypt: state.isEgypt // Send isEgypt in the API call
                    }
                })
                .then(res => {
                    commit("setFeesData", res.data.allApplicantFees);
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
            });
        },
      
      
        async fetchApplicationCountry({ commit, dispatch, state}) {
            try {
              const ipadd = await axios.get('https://api.ipify.org?format=json');
              const ip = ipadd.data.ip;
              console.log(ip);
              const response = await api().get(`api/v1/IPLocation?PubblicIp=${ip}`);
              commit("setApplicationCountry", response.data);
            //   state.isEgypt = response.data.country === 'EG' ? 1 : 0; 
              state.isEgypt = 1; 
              debugger // Set isEgypt based on applicationCountry
            } catch (error) {
              console.error('Error fetching application country:', error);
              throw error;
            }
          },

        fetchPaymentURL({ _ }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/NBEPay`)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchFawryPaymentURL({ _ }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/FawryPay`)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        checkForPayment({ _ }, paid) {
      
            return new Promise((resolve, reject) => {
                api().get(`/api/v1/NBEPaymentStatement?IsPaid=${paid}`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        checkForFawryPayment({ _ }) {
            return new Promise((resolve, reject) => {
                api().get(`/api/v1/FawryPayStatment`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}
