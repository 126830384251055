<template>
  <div style="text-align: center">
    <div
      class="my-4"
      style="color: var(--main-color2); font-size: 18px; font-weight: bold"
    >
      {{ $t("Application_Finished") }}
    </div>
    <img
      style="width: 120px; margin: auto"
      src="../../../assets/images/svgs/completedStep_brown.svg"
    />
    <div class="mt-3" style="width: 600px; margin: auto; text-align: start">
      {{ $t("Dear_Applicant") }}
    </div>
    <div class="mt-2 mb-5" style="width: 600px; margin: auto; text-align: start">
      {{ $t("finished_messege") }}
    </div>

    <Button
      :label="backToMainBtnLabel"
      class="btnClass"
      style="background-color: var(--main-color2); border: none"
      @click="backtoMainFn"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,

      validationErrors: {},
    };
  },
  computed: {
    backToMainBtnLabel() {
      return this.$t("backToMainBtnLabel");
    },
  },
  methods: {
    backtoMainFn() {
      this.$emit("nextPage", 0);
    },
  },
  created() {},
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}

.btnClass {
  font-size: 21px;
}
</style>
