import api from '../../api_embassy';
export default {
    namespaced: true,
    state: {
        documentsData: [],
    },
    getters: {
        getDocumentsData(state) {
            return state.documentsData;
        },
    },
    mutations: {
        setDocumentsData(state, data) {
            state.documentsData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.documentsData.push({
                    "docDescr": data[x].docDescr,
                    "gsCdeDocId": data[x].gsCdeDocId,
                    "mandatoryFlg": data[x].mandatoryFlg,
                    "copyDocNo": data[x].copyDocNo
                });
            }
        },
    },
    actions: {
        fetchDocumentsData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/UploadFiles/GetAllApplicantDocuments?AdmApplicantId=${this.getters["Auth_embassy/getSelectedAdmApplicantId"]}&AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]}`)
                    .then(res => {
                        commit("setDocumentsData",
                            res.data.applicantDocuments
                        );
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        UploadDocument({ }, documentData) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/UploadFiles/AdmAppDocFileUpload", documentData)
                    .then(resp => {
                        if (resp.status == "200") {
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        DeleteDocument({ }, docId) {
            return new Promise((resolve, reject) => {
                api().delete("api/v1/UploadFiles/DeleteUploadedFile?AdmAppDocId=" + docId + "&AdmApplicantId=" + this.getters["Auth_embassy/getSelectedAdmApplicantId"]
                )
                    .then(resp => {
                        if (resp.status == "200") {
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}