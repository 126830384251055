<template>
  <div class="mainForm">
    <div>
      <div class="flex m-3">
        <div
          class="flex-1 m-1 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("fullNameLabel_gardian") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="firstNamePlaceholder"
            v-model="firstName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.firstName && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="secondNamePlaceholder"
            v-model="secondName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.secondName && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="thirdNamePlaceholder"
            v-model="thirdName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.thirdName && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="lastNamePlaceholder"
            v-model="lastName"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.lastName && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-3">
        <div
          class="flex-1 m-1 labelDiv flex align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("fullNameLabel_ar_gardian") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="firstNamePlaceholder"
            v-model="firstName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.firstName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="secondNamePlaceholder"
            v-model="secondName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.secondName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="thirdNamePlaceholder"
            v-model="thirdName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.thirdName_ar && submitted },
            ]"
          />
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <InputText
            :placeholder="lastNamePlaceholder"
            v-model="lastName_ar"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.lastName_ar && submitted },
            ]"
          />
        </div>
      </div>
      <div class="flex m-3">
        <div
          class="my-1 flex labelDiv align-items-start justify-content-start font-bold border-round"
          style="width: 20%"
        >
          <span>{{ $t("E-Mail") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 align-items-center justify-content-center font-bold border-round"
          style="width: 30%"
        >
          <InputText
            :placeholder="insertPassportPlaceholder"
            v-model="emailField"
            @keydown="validateEmail"
            @change="validateEmail"
            @input="validateEmail"
            :class="[
              'w-full p-input-lg',
              {
                'p-invalid': validationErrors.emailField && submitted,
              },
            ]"
          />
        </div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
      </div>
      <div class="flex m-3">
        <div
          class="flex-1 m-1 flex labelDiv align-items-start justify-content-start font-bold border-round"
        >
          <span>{{ $t("Relation") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="flex-1 mx-2 flex align-items-center justify-content-center font-bold border-round"
        >
          <Dropdown
            v-model="selectedRelation"
            :options="relationsData"
            optionLabel="name"
            filter
            :placeholder="selectPlaceholder"
            :class="[
              'w-full m-1',
              { 'p-invalid': validationErrors.selectedRelation && submitted },
            ]"
          />
        </div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
      </div>
      <div class="flex m-3">
        <div
          class="my-1 flsx-1 flex labelDiv align-items-start justify-content-start font-bold border-round"
          style="width: 20%"
        >
          <span>{{ $t("Mobile_Number") }}</span
          ><span style="color: red">*</span>
        </div>
        <div
          class="mx-2 flex-1 flex align-items-center justify-content-center font-bold border-round"
          style="width: 20%; direction: ltr"
        >
          <vue-tel-input
            :value="mobileNumber"
         
            @input="chnageMobileNumber"
            @keypress="onlyNumbersWithPlus"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.mobileNumber && submitted },
            ]"
            :autoFormat="false"
          ></vue-tel-input>
          <!-- <InputText
            placeholder="XXXXXXXXXXX"
            @keypress="onlyNumbersWithPlus"
            v-model="mobileNumber"
            :class="[
              'w-full p-inputtext-lg',
              { 'p-invalid': validationErrors.mobileNumber && submitted },
            ]"
          /> -->
        </div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
        <div class="flex-1 mx-2 flex"></div>
      </div>
    </div>
    <div class="flex m-3 justify-content-between">
      <Button
        :label="previousBtnTxt"
        class="btnClass"
        style="background-color: #0f5887; border: none"
        @click="previousPage"
        icon="pi pi-angle-left"
        iconPos="left"
      />
      <Button
        :label="nextBtnTxt"
        class="btnClass"
        style="background-color: #d89436; border: none"
        @click="saveGardianInfoPage"
        icon="pi pi-angle-right"
        iconPos="right"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../store/mixin.js";

export default {
  mixins: [mixin],

  data() {
    return {
      submitted: false,

      firstName: "",
      secondName: "",
      thirdName: "",
      lastName: "",

      firstName_ar: "",
      secondName_ar: "",
      thirdName_ar: "",
      lastName_ar: "",

      selectedRelation: {},

      selectedPrefix: {},
      mobileNumber: "",
      emailField: "",

      validationErrors: {},
    };
  },
  computed: {
    firstNamePlaceholder() {
      return this.$t("firstNamePlaceholder");
    },
    secondNamePlaceholder() {
      return this.$t("secondNamePlaceholder");
    },
    thirdNamePlaceholder() {
      return this.$t("thirdNamePlaceholder");
    },
    lastNamePlaceholder() {
      return this.$t("lastNamePlaceholder");
    },
    selectPlaceholder() {
      return this.$t("selectPlaceholder");
    },
    phonePrefixes() {
      return [
        { code: "1", name: "01" },
        { code: "2", name: "02" },
        { code: "2", name: "03" },
        { code: "4", name: "04" },
      ];
      // return this.$store.getters["Auth/getPhonePrefixes"];
    },
    relationsData() {
      return this.$store.getters["Gardian_embassy/getRelationsData"];
    },
    nextBtnTxt() {
      return this.$t("nextBtnTxt");
    },
    previousBtnTxt() {
      return this.$t("previousBtnTxt");
    },
  },
  methods: {
    chnageMobileNumber(phone, phoneObject, input) {
      if (phoneObject?.formatted) {
        this.mobileNumber = phoneObject.formatted;
      }
    },
    validateEmail() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
        this.validationErrors["not_valid_email"] = true;
      } else delete this.validationErrors["not_valid_email"];
    },
    validateForm() {
      if (this.emailField == "" || this.emailField == undefined)
        this.validationErrors["emailField"] = true;
      else delete this.validationErrors["emailField"];

      if (this.firstName == "" || this.firstName == undefined)
        this.validationErrors["firstName"] = true;
      else delete this.validationErrors["firstName"];

      if (this.secondName == "" || this.secondName == undefined)
        this.validationErrors["secondName"] = true;
      else delete this.validationErrors["secondName"];

      if (this.thirdName == "" || this.thirdName == undefined)
        this.validationErrors["thirdName"] = true;
      else delete this.validationErrors["thirdName"];

      if (this.lastName == "" || this.lastName == undefined)
        this.validationErrors["lastName"] = true;
      else delete this.validationErrors["lastName"];

      if (this.firstName_ar == "" || this.firstName_ar == undefined)
        this.validationErrors["firstName_ar"] = true;
      else delete this.validationErrors["firstName_ar"];

      if (this.secondName_ar == "" || this.secondName_ar == undefined)
        this.validationErrors["secondName_ar"] = true;
      else delete this.validationErrors["secondName_ar"];

      if (this.thirdName_ar == "" || this.thirdName_ar == undefined)
        this.validationErrors["thirdName_ar"] = true;
      else delete this.validationErrors["thirdName_ar"];

      if (this.lastName_ar == "" || this.lastName_ar == undefined)
        this.validationErrors["lastName_ar"] = true;
      else delete this.validationErrors["lastName_ar"];

      if (!this.selectedRelation.code) this.validationErrors["selectedRelation"] = true;
      else delete this.validationErrors["selectedRelation"];

      if (
        this.mobileNumber == "" ||
        this.mobileNumber == undefined ||
        !this.validateMobileNumber(this.mobileNumber)
      )
        this.validationErrors["mobileNumber"] = true;
      else delete this.validationErrors["mobileNumber"];

      return !Object.keys(this.validationErrors).length;
    },
    saveGardianInfoPage() {
      // if (localStorage.getItem("isSubmitted") == "false") {
      if (!this.$store.getters["Auth_embassy/getViewOnly"]) {
        this.submitted = true;
        if (this.validateForm()) {
          this.$store.commit("showLoaderFn", true);
          this.$store
            .dispatch("Gardian_embassy/Add_Update_ApplicantGuardian", {
              fnamE_EN: this.firstName,
              mnamE_EN: this.secondName,
              lnamE_EN: this.thirdName,
              familY_EN: this.lastName,
              fnamE_AR: this.firstName_ar,
              mnamE_AR: this.secondName_ar,
              lnamE_AR: this.thirdName_ar,
              familY_AR: this.lastName_ar,
              guardianEmail: this.emailField,
              guardianRelationId: this.selectedRelation.code,
              mobileNumber: this.mobileNumber,
            })
            .then((res) => {
              if (res.status) {
                // this.$toast.add({
                //   severity: "success",
                //   detail: this.$t("dataSavedSuccessfuly"),
                //   life: 3000,
                // });
                this.$swal.fire({
                  icon: "success",
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: this.$t("confirmButtonText"),
                  text: this.$t("dataSavedSuccessfuly"),
                });
                this.$emit("nextPage", 4);
                this.$store.commit("showLoaderFn", false);
              }
            })
            .catch((error) => {
              this.$store.commit("showLoaderFn", false);
              // this.$toast.add({
              //   severity: "error",
              //   detail: this.$t(error.response.data.Message),
              //   life: 3000,
              // });
              this.$swal.fire({
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: this.$t("confirmButtonText"),
                text: this.$t(error.response.data.Message),
              });
            });
        } else {
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t("fillTheMissedData"),
          });
        }
      } else {
        this.$emit("nextPage", 4);
      }
    },
    previousPage() {
      this.$emit("nextPage", 2);
    },
  },
  created() {
    this.$store.commit("showLoaderFn", true);
    this.$store.dispatch("Gardian_embassy/fetchRelationsData");
    if (
      this.$store.getters["Auth_embassy/getSelectedAdmApplicantId"] != 0 &&
      this.$store.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"] != 0
    ) {
      this.$store.dispatch("Gardian_embassy/fetchGardiansInfoData").then((result) => {
        this.firstName = result.fname;
        this.secondName = result.mname;
        this.thirdName = result.lname;
        this.lastName = result.family;
        this.firstName_ar = result.fname_ar;
        this.secondName_ar = result.mname_ar;
        this.thirdName_ar = result.lname_ar;
        this.lastName_ar = result.family_ar;
        this.emailField = result.guardianEmail;
        this.mobileNumber = result.guardianMobile;
        this.selectedRelation = {
          code: result.guardianRelationId,
          name: result.relationDescr,
        };
        this.$store.commit("showLoaderFn", false);
      });
    } else {
      this.$store.commit("showLoaderFn", false);
    }
  },
};
</script>

<style scoped>
:deep(.p-button-icon) {
  font-size: 20px;
}
.mainForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
