import axios from "axios";
import { serverBasePath_embassy } from "./store/consts"

export default () => {
    return axios.create({
        baseURL: serverBasePath_embassy,
        headers: {
            "Accept-Language": localStorage.getItem('language'),
            "Authorization": localStorage.getItem('JwtToken'),
            //"Authorization": "Bearer eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJBZG1BcHBBcHBsaWNhbnRJZCI6IjAiLCJBZG1BcHBSZWdoaXN0b3J5SWQiOiIwIiwiVXNlcklkIjoiMSIsIlVzZXJUeXBlSWQiOiIzNiIsIm5iZiI6MTY4NzU2MzQyMiwiZXhwIjoxNzA1NTYzNDIyLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0LyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3QvIn0.IcYwtcT3Es95Mim3P4s6AnbJW5zfYnB47Y4D_mYil0QuxHALqDPRBaGjzx5xS3V4",
        }
    })
}