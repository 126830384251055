import { createStore } from 'vuex'
import language from "./language/language.js"

import Auth from './Auth.module'

import BasicInfo from './BasicInfo.module.js'
import Contacts from './Contacts.module.js'
import Gardian from './Gardian.module.js'
import Schools from './Schools.module.js'
import Preferences from './Preferences.module.js'
import Fees from './Fees.module.js'
import Documents from './Documents.module.js'
import Terms_conditions from './Terms_conditions.module.js'

//embassy
import Auth_embassy from './embassy/Auth_embassy.module.js'
import Applicant_search from './embassy/Applicant_search_embassy.module.js'
import BasicInfo_embassy from './embassy/BasicInfo_embassy.module.js'
import Contacts_embassy from './embassy/Contacts_embassy.module.js'
import Gardian_embassy from './embassy/Gardian_embassy.module.js'
import Schools_embassy from './embassy/Schools_embassy.module.js'
import Preferences_embassy from './embassy/Preferences_embassy.module.js'
import Fees_embassy from './embassy/Fees_embassy.module.js'
import Documents_embassy from './embassy/Documents_embassy.module.js'
import Terms_conditions_embassy from './embassy/Terms_conditions_embassy.module.js'






export default createStore({
    state: {
        backgroundColors: [
            "crimson",
            "Blue",
            "Bisque",
            "BlueViolet",
            "Brown",
            "BurlyWood",
            "Chocolate",
            "Coral",
            "CornflowerBlue",
            "DarkCyan",
            "DarkGoldenRod",
            "DarkOliveGreen",
            "DarkMagenta",
            "DarkOrange",
            "DarkSalmon",
            "DarkOrchid",
            "DeepSlateBlue",
            "FireBrick",
            "GoldenRod",
            "LimeGreen",
            "IndianRed",
            "Aqua",
            "Aquamarine",
        ],
        showLoader: false,
    },
    getters: {
        getBackgroundColors: state => {
            return state.backgroundColors;
        },
        getShowLoaderstate: state => {
            return state.showLoader;
        },
    },
    mutations: {
        showLoaderFn(state, showLoader_f) {
            state.showLoader = showLoader_f;
        },
    },
    actions: {
    },
    modules: {
        language,
        Auth,
        BasicInfo,
        Contacts,
        Gardian,
        Schools,
        Preferences,
        Fees,
        Documents,
        Terms_conditions,

        Auth_embassy,
        Applicant_search,
        BasicInfo_embassy,
        Contacts_embassy,
        Gardian_embassy,
        Schools_embassy,
        Preferences_embassy,
        Fees_embassy,
        Documents_embassy,
        Terms_conditions_embassy,
    }
})