import api from '../api';

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {
    },
    actions: {
        Save_TermsAndConditions({ }, termcondFlg) {
            return new Promise((resolve, reject) => {
                api().post("api/v1/TermsAndConditions/Save_TermsAndConditions", {
                    "termcondFlg": termcondFlg
                })
                    .then(resp => {
                        if (resp.status == "200") {
                            localStorage.setItem('isSubmitted', true);
                            resolve({ status: true })
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}
