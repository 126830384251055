export default {
  "sectionNo_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------global--------------------------"])},
  "systemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreigners Gate"])},
  "Check_This_Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check This Field"])},
  "nextBtnTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and next"])},
  "previousBtnTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "nextBtnTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and next"])},
  "selectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
  "doneBtnTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Frequest"])},
  "fillTheMissedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fill The Missed Data"])},
  "dataSavedSuccessfuly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data saved successfully"])},
  "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the document '"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])},
  "admin_officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Officer"])},
  "sectionNo_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------landingPage--------------------------"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Privacy_Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "Terms_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
  "Welcome_Dear_Student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Dear Student"])},
  "Please_Choose_Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Choose Service"])},
  "Register_New_Application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register New Application"])},
  "Log_In": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "sectionNo_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------loginPage--------------------------"])},
  "Please_Login_With_Passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Login With Passport Number And Password"])},
  "Please_Login_With_Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Login With Email And Password"])},
  "Passport_No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport No."])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Insert_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Number"])},
  "Insert_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Password"])},
  "Forgot_Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Login"])},
  "successPasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset Successfully"])},
  "mail_sent_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Sent To Admin"])},
  "Admin_officer_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin officer"])},
  "passwordLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password Sent To Email Please Check Your Email And Login"])},
  "passwordLinkSentReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Sent To Email Please Check Your Email And Login"])},
  "sectionNo_2.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------reset password (insert email)--------------------------"])},
  "insertEmailGuideSpan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Inser Account Email To Reset Password"])},
  "reset_password_with_passport_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Provide Passport Number And Email To Reset Password"])},
  "insertEmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Email"])},
  "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "sectionNo_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------regestrationPage--------------------------"])},
  "basicInfoStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Info"])},
  "pageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Registration"])},
  "Applicant_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Type"])},
  "Select_Input_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Grade Type"])},
  "Grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
  "Select_Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Degree"])},
  "NBisstoped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This function is stoped in this time."])},
  "Feeisstoped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Will be available tomorrow."])},
  "New_Applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Applicant"])},
  "Depletion_Failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depletion Failure"])},
  "Degree_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree Type"])},
  "degreeTypeDDLPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select degree type"])},
  "Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree "])},
  "degreeDDLPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select degree"])},
  "Nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality "])},
  "countryDDLPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select your nationality"])},
  "Passport_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Date "])},
  "Passport_Exp_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Exp. Date"])},
  "E-Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "notValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This E-Mail is not valid"])},
  "regestrationGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Insert Personal Email To Receive Password"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "notValidPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Number is not valid"])},
  "Passport_Date_not_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport issue date is not valid"])},
  "sectionNo_3.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------Search For Application--------------------------"])},
  "SearchForApplicationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search For Application"])},
  "createNewApplicationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new applicant"])},
  "clearSearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
  "SearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "OpenAdvancedSearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Advanced search"])},
  "CloseAdvancedSearchBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Advanced search"])},
  "AcadmicYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acadmic year"])},
  "Semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester"])},
  "ApplicantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant name"])},
  "Faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faculty"])},
  "Department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
  "Major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Major"])},
  "SemesterFromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester from date"])},
  "SemesterToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semester to date"])},
  "ApplicantType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant type"])},
  "SubmitDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit date from"])},
  "SubmitDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit date to"])},
  "ApplicantStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Status"])},
  "QualificationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification Type"])},
  "QualificationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification Name"])},
  "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "PassportNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport number"])},
  "ApplicantCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Code"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "ConfirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete that applicant ?"])},
  "sectionNo_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page1 > basic info--------------------------"])},
  "Applicant_Basic_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Basic Information"])},
  "fullNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student full name according to Secondary Certificate (English)"])},
  "firstNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
  "secondNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
  "thirdNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third"])},
  "lastNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
  "fullNameLabel_ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student full name according to Secondary Certificate (Arabic)"])},
  "genderNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "Birth_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
  "Birth_Date_not_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age must be greater than 15 years"])},
  "Birth_Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Place"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo"])},
  "uploadBtntooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload image"])},
  "deleteBtntooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete image"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "sectionNo_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page2 > Contact Information--------------------------"])},
  "Contact_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
  "Mobile_Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
  "Verification_Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
  "Current_Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Address"])},
  "Egyptian_Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyptian Address"])},
  "Send_Verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Verification"])},
  "Verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "Address_Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Details"])},
  "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
  "sectionNo_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page3 > Guardian Information --------------------------"])},
  "Guardian_Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardian Information"])},
  "Relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relative Relation"])},
  "fullNameLabel_gardian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name (EN)"])},
  "fullNameLabel_ar_gardian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name (ar)"])},
  "sectionNo_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page4 > Secondary School Qualifications --------------------------"])},
  "Secondary_School_Qualifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary School Qualifications"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The country of obtaining the qualification"])},
  "School_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Name"])},
  "Certificate_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Type"])},
  "Certificate_Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Name"])},
  "Specialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
  "Graduation_Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation Date"])},
  "Total_Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Score"])},
  "Applicant_Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant's Score"])},
  "Applicant_ScorePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert Score"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
  "Certificate_Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate Subjects"])},
  "subjectMarkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student mark"])},
  "subjectMaxMarkPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max mark"])},
  "subjectPersentagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])},
  "graduation_Date_not_correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Candidate must have been graduated since only 4 years"])},
  "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["french"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
  "applicantScoreIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant score sould be less than or equal to the total score"])},
  "sectionNo_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page5 > Fees Payment --------------------------"])},
  "Fees_Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees Payment"])},
  "Fees_Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees Type"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "Amount_Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount remaining"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "Pay_Fees_messege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Applicant, You Will Not Be Able To Enter Your References Untill Payment Of The Admission Fees"])},
  "Pay_Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Fees"])},
  "egyptianPoundSympol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGP"])},
  "sectionNo_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page6 > Applicant Preferences --------------------------"])},
  "Applicant_Preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Preferences"])},
  "Add_NewBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
  "College": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College"])},
  "Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "preferencesMinCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The minimum Preferences count is "])},
  "preferencesMaxCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and The maximum Preferences count is "])},
  "sectionNo_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page7 > Upload The Following Documents --------------------------"])},
  "Upload_Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload The Following Documents"])},
  "Document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["up"])},
  "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["down"])},
  "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory"])},
  "optinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optinal"])},
  "pleaseCompeleteData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please upload all the required documents"])},
  "documentUploadedSuccessfuly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document uploaded successfully"])},
  "sectionNo_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page8 > Terms & Conditions --------------------------"])},
  "Terms_N_Conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
  "Terms_N_Conditions_body1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "Terms_N_Conditions_body10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sed Ut Perspiciatis Unde Omnis Iste Natus Error Sit Voluptatem Accusantium Doloremque Laudantium, Totam Rem Aperiam, Eaque Ipsa Quae Ab Illo Inventore Veritatis Et Quasi Architecto Beatae Vitae Dicta Sunt Explicabo. Nemo Enim Ipsam Voluptatem Quia Voluptas Sit Aspernatur Aut Odit Aut Fugit, Sed Quia Consequuntur Magni Dolores Eos Qui Ratione Voluptatem Sequi Nesciunt. "])},
  "readConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Read And Accept All The Terms And Conditions"])},
  "sectionNo_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------application > page9 > Finished --------------------------"])},
  "Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "Application_Finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Finished"])},
  "Dear_Applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Applicant, "])},
  "finished_messege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Application Has Been Successfully Received And Will Be Reviewed Shortly. Receipt Of The Application Is Not Acceptance In The Program. Original Documents Must Be Submitted Personally In A Period To Be Determined Soon."])},
  "backToMainBtnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Main"])},
  "sectionNo_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------messeges--------------------------"])},
  "MSG_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This passport number already exists"])},
  "MSG_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This E-mail already exists"])},
  "MSG_03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Expiration Date must be after 6 months at least"])},
  "MSG_04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved successfully and Verification code sent to your email, Please login with your Passport number as username and the verification code as Password to continue your Admission request"])},
  "MSG_05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have an application request in the current Semester"])},
  "MSG_06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code not correct, and the new entered email will not be saved without entering the correct verification code"])},
  "MSG_07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment done successfully"])},
  "MSG_08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Rejected"])},
  "MSG_09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Payment"])},
  "MSG_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preference cannot be repeated"])},
  "MSG_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to upload the Mandatory files first"])},
  "MSG_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Application Saved Scuccessfully"])},
  "sectionNo_101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["--------------------------back end messeges--------------------------"])},
  "MSG-DEV2-000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your passport expire date"])},
  "MSG-DEV2-000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no admission semester is opend"])},
  "MSG-DEV2-000003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport number is repeated"])},
  "MSG-DEV2-000004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is repeated"])},
  "MSG-DEV2-000005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no admission semester is opend"])},
  "MSG-DEV2-000006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is repeated"])},
  "MSG-DEV2-000007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is saved"])},
  "MSG-DEV2-000008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no admission semester is opend"])},
  "MSG-DEV2-000009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant not exist"])},
  "MSG-DEV2-000010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrong password"])},
  "MSG-DEV2-000011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No qualification found"])},
  "MSG-DEV2-000020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])},
  "MSG-DEV2-000021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])},
  "MSG-DEV2-000026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select Program to Faculty"])},
  "MSG-DEV2-000027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Passport No. is not Exist Please Enter your correct one"])},
  "MSG-DEV2-000028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Email is not exist Please Enter your correct Email"])},
  "MSG-DEV2-000029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password sent please check your Email"])},
  "MSG-DEV2-000022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment cannot be made now - please pay via the previously selected payment method or wait for 24 hours to choose another payment method"])},
  "MSG-DEV1-000001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no faculties available"])},
  "MSG-DEV1-000002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no apllicant available"])},
  "MSG-DEV2-000030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment has been successfully processed. Thank you for your purchase!"])},
  "MSG-DEV2-000031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Uncompleted ,Please try again later or contact our customer support for further assistance"])},
  "MSG-DEV2-000032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry to inform you that your payment has been canceled. If you need further assistance, please contact our support team."])},
  "MSG-DEV2-000033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Payment  is pending "])},
  "MSG-DEV2-000034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment has not been made yet"])},
  "MSG-DEV2-000035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The graduation date should not be more than 4 years from now and not more than today's date"])},
  "MSG-DEV2-000036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must add all  subject "])},
  "MSG-DEV2-000037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SubjectMark or MaxMark must have value"])},
  "MSG-DEV2-000038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SubjectMark must be less than  MaxMark"])},
  "MSG-DEV2-000039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in Registration"])}
}