<template>
  <DataTable
    ref="dt"
    :value="applicantsData"
    v-model:selection="selectedApplicant"
    dataKey="id"
    stripedRows
    :paginator="true"
    :rows="5"
    :paginatorTemplate="paginatorTemplate"
    :currentPageReportTemplate="currentPageReportTemplate"
    responsiveLayout="scroll"
  >
    <Column
      field="index"
      header="#"
      style="min-width: 5rem; text-align: center"
    ></Column>
    <Column
      field="app_CPR_PASSPORT"
      :header="$t('PassportNumber')"
      style="min-width: 8rem; text-align: center"
    ></Column>
    <Column
      field="admAppCode"
      :header="$t('ApplicantCode')"
      style="min-width: 8rem; text-align: center"
    ></Column>
    <Column
      field="applicantName"
      :header="$t('ApplicantName')"
      style="min-width: 8rem; text-align: center"
    ></Column>
    <Column
      field="appGender"
      :header="$t('Gender')"
      style="min-width: 16rem; text-align: center"
    ></Column>
    <Column
      field="appNationaity"
      :header="$t('Nationality')"
      style="min-width: 8rem; text-align: center"
    ></Column>
    <Column
      field="applicantStatus"
      :header="$t('Status')"
      style="min-width: 8rem; text-align: center"
    ></Column>
    <Column :header="$t('Actions')" :exportable="false" style="min-width: 8rem">
      <template #body="slotProps">
        <Button
          icon="pi pi-eye"
          class="mr-2 tableActionBtns"
          v-tooltip.top="$t('View')"
          @click="openViewData(slotProps.data)"
        ></Button>
        <Button
          icon="pi pi-user-edit"
          class="tableActionBtns"
          v-tooltip.top="$t('Edit')"
          @click="openEdit(slotProps.data)"
        ></Button>
        <!-- <Button
          v-if="
            slotProps.data.applicantStatus == 'Incomplete' ||
            slotProps.data.applicantStatus == 'طلب غير مكتمل'
          "
          icon="pi pi-trash"
          class="tableActionBtns"
          v-tooltip.top="$t('Delete')"
          @click="openDelete(slotProps.data)"
        ></Button> -->
      </template>
    </Column>
  </DataTable>
  <Dialog
    v-model:visible="showDeleteApplicant"
    :style="{ width: '450px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <span v-if="selectedApplicantForDelete">{{ $t("ConfirmDelete") }}</span>
    </div>
    <template #footer>
      <Button
        :label="noLabel"
        class="p-button"
        @click="showDeleteApplicant = false"
      ></Button>

      <Button
        :label="yesLabel"
        class="p-button-danger"
        @click="deleteApplicant"
      ></Button>
    </template>
  </Dialog>
</template>

<script>
export default {
  props: ["applicantsData"],
  data() {
    return {
      selectedApplicant: {},
      selectedApplicantForDelete: {},
      showDeleteApplicant: false,
    };
  },
  computed: {
    paginatorTemplate() {
      return localStorage.getItem("language") == "en"
        ? "PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown  "
        : " PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown ";
    },
    currentPageReportTemplate() {
      return localStorage.getItem("language") == "en"
        ? "Show {first} To {last} From {totalRecords}"
        : "عرض {first} الى {last} من {totalRecords} ";
    },
    yesLabel() {
      return this.$t("yes");
    },
    noLabel() {
      return this.$t("no");
    },
  },
  methods: {
    openViewData(applicant) {
      // this.$store.commit("Auth_embassy/setViewOnly", true);
      // this.$store.commit(
      //   "Auth_embassy/setSelectedAdmApplicantId",
      //   applicant.admApplicantId
      // );
      // this.$store.commit(
      //   "Auth_embassy/setSelectedAdmAppRegHistoryId",
      //   applicant.admAppRegHistoryID
      // );

      localStorage.setItem("isSubmitted", "false");
      localStorage.setItem("ViewOnly", true);
      localStorage.setItem("AdmApplicantId", applicant.admApplicantId);
      localStorage.setItem("AdmAppRegHistoryId", applicant.admAppRegHistoryID);

      this.$router.push("/application_embassy");
    },
    openEdit(applicant) {
      // this.$store.commit("Auth_embassy/setViewOnly", false);
      // this.$store.commit(
      //   "Auth_embassy/setSelectedAdmApplicantId",
      //   applicant.admApplicantId
      // );
      // this.$store.commit(
      //   "Auth_embassy/setSelectedAdmAppRegHistoryId",
      //   applicant.admAppRegHistoryID
      // );
      localStorage.setItem("isSubmitted", "false");
      localStorage.setItem("ViewOnly", false);
      localStorage.setItem("AdmApplicantId", applicant.admApplicantId);
      localStorage.setItem("AdmAppRegHistoryId", applicant.admAppRegHistoryID);

      this.$router.push("/application_embassy");
    },
    openDelete(applicant) {
      this.selectedApplicantForDelete = applicant;
      this.showDeleteApplicant = true;
    },
    deleteApplicant() {
      console.log(this.selectedApplicantForDelete);
    },
  },
  created() {},
};
</script>
<style scoped>
:deep(td) {
  padding: 1px !important;
}

:deep(.p-column-title) {
  margin: auto;
}

.tableActionBtns {
  background: transparent;
  border: none;
  color: #0f5887;
  font-weight: bold;
  font-size: 2vh;
}

.tableActionBtns:hover {
  background: transparent !important;
  border: none;
  color: #0f5887 !important;
  font-weight: bold;
  font-size: 2vh;
}

:deep(.p-datatable-table) {
  border: 1px solid var(--main-color2) !important;
  border-radius: 5px;
}
:deep(th) {
  background-color: var(--main-color2) !important;
  padding: 5px !important;
}

:deep(.p-column-title) {
  color: white !important;
}
</style>
