import api from "../api";

export default {
    namespaced: true,
    state: {
        contactInfo: {
            mobileNum: "",
            email: "",
            current_GsCountryNodeId: 0,
            current_GsCountryNodeDesc: "",
            currentAddress: "",
            egyptianAddress: "",
        },
    },
    getters: {
        getContactInfoData(state) {
            return state.contactInfo;
        },
    },
    mutations: {
        setContactsInfoData(state, data) {
            state.contactInfo = {};
            state.contactInfo = {
                mobileNum: (data.applicantContacts.filter((o) => o.gsCodeContactMethodId == 5.0).length > 0 ? (data.applicantContacts.filter((o) => o.gsCodeContactMethodId == 5.0)[0].methodDescr) : ""),
                email: (data.applicantContacts.filter((o) => o.gsCodeContactMethodId == 2.0).length > 0 ? data.applicantContacts.filter((o) => o.gsCodeContactMethodId == 2.0)[0]
                    .methodDescr : ""),
                current_GsCountryNodeId: data.currentAddress?.gsCountryNodeId,
                current_GsCountryNodeDesc: data.currentAddress?.nodeDescr,
                currentAddress: data.currentAddress?.currentAppAddress,
                egyptianAddress: data.egyptianAddress?.egyptianAppAddress,
            };
        },
    },
    actions: {
        fetchContactInfoData({ commit, state }) {
            return new Promise((resolve, reject) => {
                api()
                    .get(`api/v1/ContactInformation/GetApplicantContacts`)
                    .then((res) => {
                        commit("setContactsInfoData", res.data);
                        resolve(state.contactInfo);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        Add_Update_ApplicantContacts({ }, contactInfo) {
            return new Promise((resolve, reject) => {
                api()
                    .post("api/v1/ContactInformation/Add_Update_ApplicantContacts", {
                        mobileNum: contactInfo.mobileNum,
                        email: contactInfo.email,
                        current_GsCountryNodeId: contactInfo.current_GsCountryNodeId,
                        currentAddress: contactInfo.currentAddress,
                        egyptianAddress: (contactInfo.egyptianAddress != undefined ? contactInfo.egyptianAddress : ""),
                    })
                    .then((resp) => {
                        if (resp.status == "200") {
                            resolve({ status: true, admApplicantId: resp.data.admApplicantId });
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
