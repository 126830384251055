import api from '../../api_embassy';
export default {
    namespaced: true,
    state: {
        applicantsData: [],
        acadmicYearsData:{},
        semesterData:{},
        applicantStatusData:[],
        applicantTypeData:[],
        facultyData:[],
        departmentsData:[],
        majorData:[],
        nationalitiesData:[],
        genderData:[],
        qualificationTypeData:[],
        qualificationNameData:[],
        semesterFromToData:{}
    },
    getters: {
        getApplicantsData(state) {
            return state.applicantsData;
        },
        getAcadmicYearsData(state) {
            return state.acadmicYearsData;
        },
        getSemesterData(state) {
            return state.semesterData;
        },
        getApplicantStatusData(state) {
            return state.applicantStatusData;
        },
        getApplicantTypeData(state) {
            return state.applicantTypeData;
        },
        getFacultyData(state) {
            return state.facultyData;
        },
        getDepartmentsData(state) {
            return state.departmentsData;
        },
        getMajorData(state) {
            return state.majorData;
        },
        getNationalitiesData(state) {
            return state.nationalitiesData;
        },
        getGenderData(state) {
            return state.genderData;
        },
        getQualificationTypeData(state) {
            return state.qualificationTypeData
        },
        getQualificationNameData(state) {
            return state.qualificationNameData
        },
        getSemesterFromToData(state) {
            return state.semesterFromToData
        }
    },
    mutations: {
        setApplicantsData(state, data) {
            state.applicantsData = [];
            for (var x = 0; x <data.length ; x++) {
                state.applicantsData.push({
                    "index":x+1,
                    "admApplicantId": data[x].admApplicantId,
                    "admAppRegHistoryID": data[x].admAppRegHistoryID,
                    "admAppCode": data[x].admAppCode,
                    "applicantName": data[x].applicantName,
                    "app_CPR_PASSPORT": data[x].app_CPR_PASSPORT,
                    "appGender": data[x].appGender,
                    "appNationaity": data[x].appNationaity,
                    "isPaid": data[x].isPaid,
                    "applicantStatus": data[x].applicantStatus
                });
            }
        },
        setAcadmicYearData(state,data) {
            state.acadmicYearsData = data;
        },
        setSemesterData(state,data) {
            state.semesterData = data;
        },
        setApplicantStatusData(state,data) {
            state.applicantStatusData = data;
        },
        setApplicantTypeData(state,data) {
            state.applicantTypeData = data;
        },
        setFacultyData(state,data) {
            state.facultyData = data;
        },
        setDepartmentsData(state,data) {
            state.departmentsData = data;
        },
        setNationalitiesData(state,data) {
            state.nationalitiesData = data;
        },
        setGenderData(state,data) {
            state.genderData = data;
        },
        setQualificationTypeData(state,data) {
            state.qualificationTypeData = data;
        },
        setQualificationNameData(state,data) {
            state.qualificationNameData = data;
        },
        setSemesterFromToData(state,data) {
            state.semesterFromToData = data;
        },
    },
    actions: {
        fetchApplicantsData({ commit },searchParams) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/SearchApplicants?EdAcadYearId=${searchParams.edAcadYearId}&EdCodeSemesterId=${searchParams.edCodeSemesterId}&SemDateFrom=${searchParams.semDateFrom}&SemDateTo=${searchParams.semDateTo}&SubmitDateFrom=${searchParams.submitDateFrom}&SubmitDateTo=${searchParams.submitDateTo}&AdmCdeAppTypeID=${searchParams.admCdeAppTypeID}&AdmCdeStatusId=${searchParams.admCdeStatusId}&Fac_EntMainId=${searchParams.fac_EntMainId}&Dept_EntMainId=${searchParams.dept_EntMainId}&Mjr_EntMainID=${searchParams.mjr_EntMainID}&AdmAppCode=${searchParams.admAppCode}&App_CPR_PASSPORT=${searchParams.app_CPR_PASSPORT}&ApplicantName=${searchParams.applicantName}&Nationality_GsCountryNodeId=${searchParams.nationality_GsCountryNodeId}&GsCodeGenderID=${searchParams.gsCodeGenderID}&EdCdeQualCatId=${searchParams.edCdeQualCatId}&EdCodeQualId=${searchParams.edCodeQualId}`)
                    .then(res => {
                        commit("setApplicantsData", res.data.applicants);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchAcadmicYearData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetAcadYearAdmin`)
                    .then(res => {
                        commit("setAcadmicYearData", res.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchSemesterData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetCurrentSemester`)
                    .then(res => {
                        commit("setSemesterData", res.data);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchSemesterFromToData({ commit },data) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetDatesFrom_To/?EdAcadYearID=${data.edAcadYearID}&EdCodeSemesterID=${data.edCodeSemesterID}`)
                    .then(res => {
                        commit("setSemesterFromToData", res.data);
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchApplicantStatusData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetApplicantStatusAdmin`)
                    .then(res => {
                        commit("setApplicantStatusData", res.data.applicantStatuses);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchApplicantTypeData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/ApplicantType`)
                    .then(res => {
                        commit("setApplicantTypeData", res.data.aplicantTypes);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchFacultyData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetFacultyAdmin`)
                    .then(res => {
                        commit("setFacultyData", res.data.facultyTypes);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchDepartmentsData({ commit },facultyId) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetDepartmentAdmin/?FacultyId=${facultyId}`)
                    .then(res => {
                        commit("setDepartmentsData", res.data.departmentTypes);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchMajorData({ commit },params) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetMajorAdmin/?FacultyId=${params.facultyId ? params.facultyId: ''}&ProgrameId=${params.departmentId ? params.departmentId:''}`)
                    .then(res => {
                        commit("setMajorData", res.data.majorTypes);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchNationalitiesData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetNationalitiesAdmin`)
                    .then(res => {
                        commit("setNationalitiesData", res.data.getNationals);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchGenderData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetGenderAdmin`)
                    .then(res => {
                        commit("setGenderData", res.data.genders);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchQualificationTypeData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetQualTypeAdmin`)
                    .then(res => {
                        commit("setQualificationTypeData", res.data.qualificationTypes);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchQualificationNameData({ commit },qualificationTypeId) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/AdminView/GetQualNameAdmin?QualificationTypeId=${qualificationTypeId}`)
                    .then(res => {
                        const qualificationName = [res.data.qualificationName]
                        commit("setQualificationNameData", qualificationName);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}
