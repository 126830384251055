<template>
  <div class="navigatorDiv w-full md:w-9 m-auto">
    <div class="p-inputgroup flex-1 inputGroup">
      <span class="loginGuideSpan">{{ $t("Please_Login_With_Email") }}</span>
    </div>

    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("emailLabel") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <InputText
          v-model="loginForm.email"
          :placeholder="insertEmailPlaceholder"
          class="p-inputtext-lg"
        />
      </div>
    </div>
    <div class="field grid">
      <div class="labelDiv col-12 md:col-4">
        <span>{{ $t("Password") }}</span
        ><span style="color: red">*</span>
      </div>
      <div class="col">
        <Password
          v-model="loginForm.password"
          :placeholder="insertPasswordPlaceholder"
          class="p-inputtext-lg"
          toggleMask
        />
      </div>
    </div>
    <div class="p-inputgroup flex-1 inputGroup" style="margin: 0px auto">
      <div class="labelDiv"></div>
      <!-- <router-link to="/reset_password_insert_email">
        <div class="forgetPasswordDiv">{{ $t("Forgot_Password") }}</div>
      </router-link> -->
    </div>
    <div class="p-inputgroup flex-1 inputGroup">
      <div class="labelDiv"></div>
      <Button
        :label="loginBtnLabel"
        class="btnClass"
        :disabled="loginForm.Passport_No == '' || loginForm.password == ''"
        @click="loginFn"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  components: {},
  computed: {
    insertEmailPlaceholder() {
      return this.$t("insertEmailPlaceholder");
    },
    insertPasswordPlaceholder() {
      return this.$t("Insert_Password");
    },
    loginBtnLabel() {
      return this.$t("Log_In");
    },
  },
  methods: {
    async loginFn() {
      // this.$store.commit("showLoaderFn", true);
      // console.log(this.loginForm.email)

      this.$store
        .dispatch("Auth_embassy/USER_LOGIN", this.loginForm.email)
        .then((res) => {
          if (res.status) {
            // this.$store.dispatch("Auth/fetchStepsData");
            this.$router.push("/officer_search");

            this.$store.commit("showLoaderFn", false);
          }
        })
        .catch((error) => {
          this.$store.commit("showLoaderFn", false);
          // this.$toast.add({
          //   severity: "error",
          //   detail: this.$t(error.response.data.Message),
          //   life: 3000,
          // });
          this.$swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("confirmButtonText"),
            text: this.$t(error.response.data.Message),
          });
        });
    },
  },
};
</script>

<style scoped>
.navigatorDiv {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
}

.loginGuideSpan {
  inline-size: 330px;
  overflow-wrap: break-word;
  font-size: 1.5rem;
  margin: 10px auto;
}

.btnClass {
  width: 320px;
  margin: 5px auto;
  height: 45px;
  font-weight: bold;
  background-color: var(--main-color2);
  border: none;
}

.btnClass:hover {
  background-color: var(--main-color2) !important;
}
.labelDiv {
  margin-inline: 10px;
  font-weight: bold;
  text-align: start;
}

.inputGroup {
  margin: 10px auto;
  width: 70%;
}

.inputGroup > input {
  border-radius: 5px;
}

.forgetPasswordDiv {
  text-align: end;
  width: 330px;
  text-decoration: underline;
  letter-spacing: 0px;
  color: var(--main-color2);
  text-transform: capitalize;
}
</style>
