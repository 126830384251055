import api from '../../api_embassy';
export default {
    namespaced: true,
    state: {
        feesData: [],
    },
    getters: {
        getFeesData(state) {
            return state.feesData;
        },
    },
    mutations: {
        setFeesData(state, data) {
            state.feesData = [];
            for (var x = data.length - 1; x >= 0; x--) {
                state.feesData.push({
                    "feeDescr": data[x].feeDescr,
                    "feeAmount": data[x].feeAmount,
                    "feeRemainAmount": data[x].feeRemainAmount,
                    "isPaid": data[x].isPaid,
                    "currency": data[x].currencyDescr
                });
            }
        },
    },
    actions: {
        fetchFeesData({ commit }) {
            return new Promise((resolve, reject) => {
                api().get(`api/v1/ApplicantFees/GetAllApplicantFees?AdmApplicantId=${this.getters["Auth_embassy/getSelectedAdmApplicantId"]}&AdmAppRegHistoryId=${this.getters["Auth_embassy/getSelectedAdmAppRegHistoryId"]}`)
                    .then(res => {
                        commit("setFeesData", res.data.allApplicantFees);
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}
